import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import CaseResult from '../../models/CaseResult';
import { nextPageAction } from '../../redux/CurrentPage/Actions';
import { validateCaseResultAction } from '../../redux/Case/Actions';

const mapStateToProps = (state: AppState) => {
  return {
    hasResult: state.case.caseResult !== undefined && state.case.caseResult !== CaseResult.Processing
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    validateCaseResult: () => dispatch(validateCaseResultAction()),
    continue: () => dispatch(nextPageAction())
  }
};

interface CaseDecisionDeterminationComponentProps {
  hasResult: boolean;
  validateCaseResult: () => void;
  continue: () => void;
}

export class CaseDecisionDeterminationComponent extends React.Component<CaseDecisionDeterminationComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.validateCaseResult()
  }

  componentDidUpdate() {
    if (this.props.hasResult) {
      this.props.continue();
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseDecisionDeterminationComponent);