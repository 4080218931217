import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Question } from '../../models/Question';
import { submitQuestionAnswerAction } from '../../redux/InterviewQuestions/Actions';


const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    answerQuestion: (id: string, value: string) => dispatch(submitQuestionAnswerAction(id, value))
  };
};

interface BinaryQuestionComponentProps {
  question: Question;
  answerQuestion: (id: string, value: string) => void;
}
class BinaryQuestionComponent extends React.Component<BinaryQuestionComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  private setFirstValue = () => {
    this.props.answerQuestion(this.props.question.id, this.props.question.options[0].value);
  }
  private setSecondValue = () => {
    this.props.answerQuestion(this.props.question.id, this.props.question.options[1].value);
  }
  render() {
    const baseButtonClassName = 'btn btn-outline-primary';
    const firstClassName = baseButtonClassName + (this.props.question.options[0].value === this.props.question.answer ? ' active' : '');
    const secondClassName = baseButtonClassName + (this.props.question.options[1].value === this.props.question.answer ? ' active' : '');
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                <label className={firstClassName}>
                  <input type="radio" name="options" id="option1" autoComplete="off" onChange={this.setFirstValue} checked={this.props.question.options[0].value === this.props.question.answer} /> {this.props.question.options[0].text}
                </label>
                <label className={secondClassName}>
                  <input type="radio" name="options" id="option2" autoComplete="off" onChange={this.setSecondValue} checked={this.props.question.options[1].value === this.props.question.answer} /> {this.props.question.options[1].text}
                </label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(BinaryQuestionComponent);