import React from 'react';
import { connect } from "react-redux";
import { updateHeightFeetAction, updateWeightAction, updateHeightInchesAction } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import Card from '../../components/Card/Card';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';
import IntegerInput from '../../components/IntegerInput';

const mapStateToProps = (state: AppState) => {
    return {
        heightFeet: state.personalInformation.heightFeet,
        heightInches: state.personalInformation.heightInches,
        weight: state.personalInformation.weightPounds
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateHeightFeet: (value?: number) => dispatch(updateHeightFeetAction(value)),
        updateHeightInches: (value?: number) => dispatch(updateHeightInchesAction(value)),
        updateWeight: (value?: number) => dispatch(updateWeightAction(value))
    };
};

interface BodyComponentProps {
    heightFeet?: number;
    heightInches?: number;
    weight?: number;
    updateHeightFeet: (value?: number) => void;
    updateHeightInches: (value?: number) => void;
    updateWeight: (value?: number) => void;
}

export class BodyComponent extends React.Component<BodyComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private updateHeightFeet = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseInt(e.target.value);
        this.props.updateHeightFeet(value);
    }
    private updateHeightInches = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseInt(e.target.value);
        this.props.updateHeightInches(value);
    }
    private updateWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = parseInt(e.target.value);
        this.props.updateWeight(value);
    }
    render() {
        return (
            <>
                <Card title="Tell us about yourself">
                    <form>
                        <div className="mb-2">Height</div>
                        <div className="form-row">
                            <div className="form-inline col-6">
                                <div className="input-group w-100">
                                    <IntegerInput autoComplete="off" max={9} required className="form-control" id="feet" onChange={this.updateHeightFeet} value={this.props.heightFeet !== undefined ? this.props.heightFeet : ""} />
                                    <div className="input-group-append">
                                        <label className="input-group-text" htmlFor="feet">Feet</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-inline col-6">
                                <div className="input-group w-100">
                                    <IntegerInput autoComplete="off" max={11} required className="form-control" id="inches" onChange={this.updateHeightInches} value={this.props.heightInches !== undefined ? this.props.heightInches : ""} />
                                    <div className="input-group-append">
                                        <label className="input-group-text" htmlFor="inches">Inches</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-2">Weight</div>
                        <div className="form-row">
                            <div className="form-inline col-md-6">
                                <div className="input-group w-100">
                                    <IntegerInput autoComplete="off" max={1500} required className="form-control" id="pounds" onChange={this.updateWeight} value={this.props.weight !== undefined ? this.props.weight : ""} />
                                    <div className="input-group-append">
                                        <label className="input-group-text" htmlFor="pounds">Pounds</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(BodyComponent));