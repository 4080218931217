import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import { nextPageAction } from '../../redux/CurrentPage/Actions';
import { submitApplicationAction } from '../../redux/Policy/Actions'
import { validateCaseResultAction } from '../../redux/Case/Actions';

const mapStateToProps = (state: AppState) => {
  return {
    hasResult: state.policy.policyNumber !== undefined && state.case.caseResult !== undefined
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    submitApplication: () => dispatch(submitApplicationAction()).then(()=>{
      dispatch(validateCaseResultAction())
    }),
    continue: () => dispatch(nextPageAction())
  }
};

interface SubmittingApplicationComponentProps {
  hasResult: boolean;
  submitApplication: () => void;
  continue: () => void;
}

export class SubmittingApplicationComponent extends React.Component<SubmittingApplicationComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.submitApplication();
  }

  componentDidUpdate() {
    if (this.props.hasResult) {
      this.props.continue();
    }
  }

  render() {
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmittingApplicationComponent);