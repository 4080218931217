export function getBrandName(): string | undefined {
    var url = new URL(window.location.href);
    let brandName = url.searchParams.get('brandName');
    if (brandName === null) {
        let hostname = url.hostname;
        if (hostname.toLowerCase().endsWith('.prepareinsure.com')) {
            brandName = hostname.replace(/\.prepareinsure\.com$/i, '');
        }
    }
    return brandName ? brandName : undefined;
}

export function getGender(): string | undefined {
    var url = new URL(window.location.href);
    let gender = url.searchParams.get('gender');
    return gender ? gender : undefined;
}

export function getUseNicotineProducts(): string | undefined {
    var url = new URL(window.location.href);
    let nicotine = url.searchParams.get('nicotine');
    return nicotine ? nicotine : undefined;
}

export function getDateOfBirth(): string | undefined {
    var url = new URL(window.location.href);
    let dob = url.searchParams.get('dob');
    return dob ? dob : undefined;
}

export function getState(): string | undefined {
    var url = new URL(window.location.href);
    let state = url.searchParams.get('state');
    return state ? state : undefined;
}

export function getEmail(): string | undefined {
    var url = new URL(window.location.href);
    let email = url.searchParams.get('email');
    return email ? email : undefined;
}

export function getReferrer(): string {
    var url = new URL(window.location.href);
    let referrer = url.searchParams.get('referrer');
    return referrer ? referrer : '';
}

export function getPartnerSubdomain(): string {
    var url = new URL(window.location.href);
    let partnerSubdomain = url.searchParams.get('partner_subdomain');
    return partnerSubdomain ? partnerSubdomain : '';
}