import React, { ChangeEvent } from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { formatNumber } from '../PageUtilities';
import { nextPageAction, detailsPageAction } from '../../redux/CurrentPage/Actions';
import AppState from '../../models/State/AppState';
import BenefitAmount from '../../models/BenefitAmount';
import AvailableBenefitAmount from '../../models/AvailableBenefitAmount';
import { selectBenefitAmountAction, loadAvailableBenefitAmountsAction } from '../../redux/BenefitAmount/Actions';
import Card from '../../components/Card/Card';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';
import Modal from 'react-bootstrap/Modal';
import { fullDetailsPageAction } from '../../redux/CurrentPage/Actions';

const mapStateToProps = (state: AppState) => {
    return {
        selectedBenefitAmount: state.benefitAmount.selectedAmount,
        availableBenefitAmounts: state.benefitAmount.availableAmounts,
        canContinue: state.validation.selectedAmount.fields.isSelectedAmountValid
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateSelectedBenefitAmount: (value: BenefitAmount | undefined) => dispatch(selectBenefitAmountAction(value)),
        loadBenefitAmounts: () => dispatch(loadAvailableBenefitAmountsAction()),
        continue: () => dispatch(nextPageAction()),
        seeDetails: () => dispatch(detailsPageAction()),
        seeFullDetails: () => dispatch(fullDetailsPageAction())
    };
};

interface BenefitSelectionProps {
    selectedBenefitAmount?: BenefitAmount;
    availableBenefitAmounts?: AvailableBenefitAmount[];
    canContinue: boolean;
    updateSelectedBenefitAmount: (benefitAmount: BenefitAmount | undefined) => void;
    loadBenefitAmounts: () => void;
    continue: () => void;
    seeDetails: () => void;
    seeFullDetails: () => void;
}

interface BenefitSelectionComponentState {
    activeTab: string;
    modalDisplayed: boolean;
}

export class BenefitSelectionComponent extends React.Component<BenefitSelectionProps, BenefitSelectionComponentState>{
    constructor(props: BenefitSelectionProps) {
        super(props);

        this.state = {
            activeTab: "Disclosures",
            modalDisplayed: false
        };
    }

    private seeFullDetails = () => {
        this.props.seeFullDetails();
    }

    private toggleModal = () => {
        this.setState({
            modalDisplayed: !this.state.modalDisplayed
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.loadBenefitAmounts();
    }

    private updateSelectedBenefitAmount = (e: ChangeEvent<HTMLSelectElement>): void => {
        if (this.props.availableBenefitAmounts === undefined) {
            return;
        }
        let benefitAmount = this.props.availableBenefitAmounts.find(a => a.benefitAmount === parseInt(e.target.value));
        this.props.updateSelectedBenefitAmount(benefitAmount);
    }

    private continue = () => {
        this.props.continue();
    }

    private seeDetails = () => {
        this.props.seeDetails();
    }

    private benefitAmountClicked = (benefitAmount: BenefitAmount): void => {
        this.props.updateSelectedBenefitAmount(benefitAmount);
    }

    render() {
        if (this.props.availableBenefitAmounts === undefined) {
            return null;
        }
        let recommended = this.props.availableBenefitAmounts.find(a => a.isRecommended);
        let highlighted = this.props.availableBenefitAmounts.filter(a => a.isHighlighted);
        let dropdownIems = this.props.availableBenefitAmounts.filter(a => !a.isHighlighted);
        return (
            <>

                <Card title="Critical Illness Insurance">
                    {recommended &&
                        <div className="row text-center">
                            <p className="col-12"><strong className="text-dark-blue header">We recommend ${formatNumber(recommended.benefitAmount)} in benefit for ${recommended.cost.toFixed(2)} a month!</strong></p>
                            <p className="col-12"><strong className="text-dark">Covered Conditions:</strong></p>
                            <p className="col-12"><strong className="text-dark-blue">100% Benefit</strong><div className="col-12 text-dark italics">Heart Attack, Stroke, Invasive Cancer, Kidney (Renal) Failure, Advanced Alzheimer’s Disease, Paralysis, and Coma</div></p>
                            <p className="col-12"><strong className="text-dark-blue">Partial Benefit</strong><div className="col-12 text-dark italics">Coronary Artery Bypass Surgery, Angioplasty, Non-Invasive Cancer, Major Organ Transplant</div></p>
                            <p className="col-12 text-dark">Get a decision in less than 5 minutes!</p>
                        </div>
                    }
                    {highlighted.length > 0 &&
                        highlighted.map(a => (
                            <div className="row mb-2" key={a.benefitAmount}>
                                <div className="col-12">
                                    <BenefitButton benefitAmount={a} benefitAmountFormatted={formatNumber(a.benefitAmount)} isSelected={this.props.selectedBenefitAmount ? a.benefitAmount === this.props.selectedBenefitAmount.benefitAmount : false} onClick={this.benefitAmountClicked} />
                                </div>
                            </div>
                        ))
                    }
                    {dropdownIems.length > 0 &&
                        <div className="row mb-3">
                            <div className="col-12 text-dark text-center">
                                Or choose a custom benefit:
                                </div>
                            <div className="col-12">
                                <select className="form-control benefit-select" onChange={this.updateSelectedBenefitAmount} value={this.props.selectedBenefitAmount ? this.props.selectedBenefitAmount.benefitAmount : ""}>
                                    <option value="">Choose...</option>
                                    {
                                        dropdownIems.map(a => <option key={a.benefitAmount} value={a.benefitAmount}>${formatNumber(a.benefitAmount)} for ${a.cost.toFixed(2)}/mo</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12 card-body-text">
                            <button className="btn btn-primary btn-block" onClick={this.continue} disabled={!this.props.canContinue}>Apply Today</button>
                        </div>
                    </div>
                    <div className="col-12 text-center top-margin-20">
                        <button className="btn btn-link btn-inline" onClick={this.toggleModal}>Policy Details</button>
                    </div>
                    <Modal show={this.state.modalDisplayed} onHide={this.toggleModal} size="xl" className="modal-full">
                        <Modal.Header closeButton><h2>Critical Illness Insurance</h2></Modal.Header>
                        <Modal.Body>
                            <table className="table table-sm">
                                <tbody>
                                    <tr className="table-details">
                                        <td><strong>Covered Conditions</strong></td>
                                        <td>
                                            <p><strong>100% Benefit</strong> &ndash; Heart Attack, Stroke, Invasive Cancer, Kidney (Renal) Failure, Advanced Alzheimer's Disease, Paralysis, and Coma</p>
                                            <p><strong>Partial Benefit</strong> &ndash; Coronary Artery Bypass Surgery, Angioplasty, Non-Invasive Cancer, Major Organ Transplant</p>
                                        </td>
                                    </tr>
                                    <tr className="table-details">
                                        <td><strong>Diagnosis Benefit</strong></td>
                                        <td>
                                            <p><strong>Pays a lump-sum benefit</strong> if you are diagnosed with an illness or undergo a procedure from a list of covered conditions while this policy is in force.</p>
                                        </td>
                                    </tr>
                                    <tr className="table-details">
                                        <td><strong>Additional Diagnosis Benefit</strong></td>
                                        <td>
                                            <p>Benefits are available for multiple different critical illness diagnoses covered in the policy if the date of diagnosis is separated from the prior diagnosis by at least six consecutive months, and the new critical illness is not caused by or contributed to by a critical illness for which benefits have already been paid.</p>
                                        </td>
                                    </tr>
                                    <tr className="table-details">
                                        <td><strong>Major Benefits</strong></td>
                                        <td>
                                            <p>Simple, streamlined underwriting.</p>
                                            <p><strong>Return of Premium</strong> - If you die from a cause other than a specified critical illness while the policy is inforce, 100 percent of the premiums paid for the policy less any benefits paid under the policy will be returned to the beneficiary or estate.</p>
                                            <p><strong>Policy is guaranteed renewable for life</strong> as long as you pay the premium; benefit amount is reduced by 50 percent on the later of the policy anniversary following your 70th birthday or the third policy anniversary.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary" onClick={this.toggleModal}>Close</button>
                        </Modal.Footer>
                    </Modal>
                </Card>

            </>
        );
    }
}

interface BenefitButtonProps {
    benefitAmount: AvailableBenefitAmount;
    benefitAmountFormatted: string;
    isSelected: boolean;
    onClick: (benefitAmount: BenefitAmount) => void;
}

class BenefitButton extends React.Component<BenefitButtonProps>{
    private onClick = () => {
        this.props.onClick(this.props.benefitAmount)
    }
    render() {
        let className = "btn btn-outline-primary btn-block" + (this.props.isSelected ? " active" : "");
        return <button className={className} onClick={this.onClick}>${this.props.benefitAmountFormatted} for ${this.props.benefitAmount.cost.toFixed(2)}/mo</button>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(BenefitSelectionComponent));