import React from "react";
import Modal from "react-bootstrap/Modal";

interface DisclosureProps {
}
interface DisclosureState {
    modalDisplayed: boolean;
}
export default class Disclosure extends React.Component<DisclosureProps, DisclosureState>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    constructor(props: DisclosureProps) {
        super(props);
        this.state = {
            modalDisplayed: false
        }
    }
    private toggleModal = () => {
        this.setState({
            modalDisplayed: !this.state.modalDisplayed
        });
    }
    render() {
        return <>
            <div className="card mt-5 mb-1">
                <div className="card-body text-muted small">
                    <p className="text-muted">NOT AVAILABLE IN NEW YORK.</p>
                    <p className="text-muted">CRITICAL ILLNESS INSURANCE PROVIDES LIMITED BENEFIT COVERAGE. It is not a comprehensive major medical plan or Medicare supplement policy and does not satisfy the requirement for minimum essential coverage under the Affordable Care Act (ACA). The description of benefits is intended only to highlight the insured's benefits and should not be relied upon to fully determine coverage. If this description conflicts in any way with the terms of the policy, the terms of the policy prevail. This policy may contain reductions of benefits, <button className="btn btn-link btn-inline" onClick={this.toggleModal}>limitations and exclusions</button>. Availability of this product, final underwriting requirements, benefits and cost will depend on your application.</p>
                    <p className="text-muted">Policy Form No. I H1820 underwritten by Assurity Life Insurance Company, Lincoln, NE.</p>
                    <p className="text-muted">Assurity is a marketing name for the mutual holding company Assurity Group, Inc. and its subsidiaries. Those subsidiaries include but are not limited to: Assurity Life Insurance Company and Assurity Life Insurance Company of New York. Insurance products and services are offered by Assurity Life Insurance Company in all states except New York. In New York, insurance products and services are offered by Assurity Life Insurance Company of New York, Albany, NY. Product availability, features and rates may vary by state.</p>
                </div>
            </div>
            <Modal show={this.state.modalDisplayed} onHide={this.toggleModal} size="xl" className="modal-full">
                <Modal.Header closeButton><h2>Conditions, Limitations and Exclusions</h2></Modal.Header>
                <Modal.Body>
                    <p>The following limitations and exclusions apply to the policy as approved in most states. Limitations and exclusions may vary by state. For a list of all limitations and exclusions, refer to the actual policy.</p>
                    <p>Pre-existing Condition &ndash; Assurity will not pay benefits for a critical illness that is caused by a pre-existing condition unless the date of diagnosis is after this policy has been in force for 12 months from the issue date or last reinstatement date.</p>
                    <p>Reduced Benefit After Age 70 &ndash; On the policy anniversary immediately following the later of the insured's 70th birthday, or three years from the policy's issue date, the policy benefit amount will be automatically reduced by 50 percent. This will be the benefit amount available for the remaining years the policy is in force.</p>
                    <p>Exclusions &ndash; Assurity will not pay benefits for conditions that are caused by or are the result of the insured person: being exposed to war or any act of war, declared or undeclared; actively serving in any of the armed forces or units auxiliary thereto, including the National Guard or Army Reserve, except during active duty training of less than 60 days; engaging in hang-gliding, hot air ballooning, bungee jumping, parachuting, scuba diving, sail gliding, motor vehicle racing, parasailing, parakiting, mountain or rock climbing, B.A.S.E. jumping, sky diving or cave diving; being addicted to drugs or suffering from alcoholism; being intoxicated (as determined by the laws governing the operation of motor vehicles in the jurisdiction where loss occurs) or under the influence of an illegal substance or a narcotic (except for narcotics used as prescribed to the insured person by a physician); committing or attempting to commit a felony; being incarcerated in a penal institution or government detention facility; engaging in an illegal occupation; intentionally self-inflicting an injury; or committing or attempting to commit suicide, while sane or insane.</p>
                </Modal.Body>
                <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.toggleModal}>Close</button>
                    </Modal.Footer>
            </Modal>
        </>
    }
}