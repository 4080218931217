import React from 'react';
import { connect } from "react-redux";
import { updateGenderAction } from '../../redux/DemographicInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Gender from '../../models/Gender';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        gender: state.demographicInformation.gender
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateGender: (value: Gender | undefined) => dispatch(updateGenderAction(value))
    };
};

interface GenderComponentProps {
    gender?: Gender;
    updateGender: (gender: Gender) => void;
}

export class GenderComponent extends React.Component<GenderComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    private setGenderFemale = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.props.updateGender(Gender.Female);
        }
    }
    private setGenderMale = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.props.updateGender(Gender.Male);
        }
    }
    render() {
        const baseButtonClassName = 'btn btn-outline-primary';
        const femaleClassName = baseButtonClassName + (this.props.gender === Gender.Female ? ' active' : '');
        const maleClassName = baseButtonClassName + (this.props.gender === Gender.Male ? ' active' : '');
        return (
            <>
                <Card title="Let's get to know you better!">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div>
                                    <label htmlFor="usersFirstName">Gender?</label>
                                </div>
                                <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label className={femaleClassName}>
                                        <input type="radio" name="options" id="option1" autoComplete="off" onChange={this.setGenderFemale} checked={this.props.gender === Gender.Female} /> Female
                                    </label>
                                    <label className={maleClassName}>
                                        <input type="radio" name="options" id="option2" autoComplete="off" onChange={this.setGenderMale} checked={this.props.gender === Gender.Male} /> Male
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(GenderComponent));