import { combineReducers } from 'redux';
import setCurrentPageState from './CurrentPage/Reducer';
import demographicInformationReducer from './DemographicInformation/Reducer';
import benefitAmountReducer from './BenefitAmount/Reducer';
import availableStatesReducer from './AvailableStates/Reducer';
import personalInformationReducer from './PersonalInformation/Reducer';
import birthLocationReducer from './BirthLocation/Reducer';
import paymentReducer from './Payment/Reducer';
import validationReducer from './Validation/Reducer';
import caseReducer from './Case/Reducer';
import brandReducer from './Brand/Reducer';
import interviewQuestionsReducer from './InterviewQuestions/Reducer';
import documentReducer from './Documents/Reducer';
import policyReducer from './Policy/Reducer';
import AppState from '../models/State/AppState';

const combinedReducers = combineReducers({
    currentPage: (state = {}) => state,
    demographicInformation: demographicInformationReducer,
    benefitAmount: benefitAmountReducer,
    availableStates: availableStatesReducer,
    personalInformation: personalInformationReducer,
    birthLocation: birthLocationReducer,
    interviewQuestions: interviewQuestionsReducer,
    payment: paymentReducer,
    case: caseReducer,
    document: documentReducer,
    brand: brandReducer,
    policy: policyReducer,
    validation: (state = {}) => state
})

function crossStateReducer(state: any, action?: any): AppState{
    var validatedState = {...state, validation: validationReducer(state)};
    return {...validatedState, currentPage: setCurrentPageState(validatedState, action)}
}

export default function(state?: AppState, action?: any){
    const intermediateState = combinedReducers(state, action);
    return crossStateReducer(intermediateState, action);
};