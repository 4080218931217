import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import InterviewQuestionsState from "../../models/State/InterviewQuestionsState";
import { Question, FollowUpQuestion, PickListOption, BaseQuestion } from "../../models/Question";
import { areQuestionAnswersSaving, areQuestionsFetchingFollowUps } from "./Utilities";

type Action = ReturnType<typeof Actions.fetchQuestionsAction> |
    ReturnType<typeof Actions.retrievedQuestionsAction> |
    ReturnType<typeof Actions.answerQuestionAction> |
    ReturnType<typeof Actions.updateQuestionOptionsAction> |
    ReturnType<typeof Actions.setFollowUpQuestionAction> |
    ReturnType<typeof Actions.setHasSavedAnswerAction> |
    ReturnType<typeof Actions.storeFollowUpQuestionAction> |
    ReturnType<typeof Actions.setFollowUpQuestionPicklistAction> |
    ReturnType<typeof Actions.answerPickListQuestionAction>;

export function fetchQuestionsReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.FETCH_QUESTIONS) {
        return state;
    }
    return { ...state, isRetrievingQuestions: true };
}

export function retrievedQuestionsReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.RETRIEVED_QUESTIONS) {
        return state;
    }
    return { ...state, isRetrievingQuestions: false, questions: action.payload.questions };
}

export function storeFollowUpQuestionReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.STORE_FOLLOWUPQUESTION) {
        return state;
    }
    let cache = { ...state.followUpQuestionCache };
    cache[action.payload.followUpId] = action.payload.followUpQuestion;

    let flatCache = { ...state.flatFollowUpQuestionCache };
    setFlatFollowUpQuestionCache(flatCache, action.payload.followUpQuestion);

    return { ...state, followUpQuestionCache: cache, flatFollowUpQuestionCache: flatCache }
}

const setFlatFollowUpQuestionCache = (cache: { [questionId: string]: FollowUpQuestion | undefined; }, followUpQuestion: FollowUpQuestion | undefined): void => {
    if (followUpQuestion === undefined) {
        return;
    }
    cache[followUpQuestion.id] = followUpQuestion;
    followUpQuestion.options.forEach(a => setFlatFollowUpQuestionCache(cache, a.followUpQuestion))
}

export function answerQuestionReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.ANSWER_QUESTION || state.questions === undefined) {
        return state;
    }

    let questionSet = updateQuestionSet(action.payload.id, state.questions, { answer: action.payload.answer, followUpQuestionRetrieved: false, followUpQuestion: undefined, pickList: action.payload.pickList });
    questionSet = updateQuestionSet(action.payload.baseQuestionId, questionSet, { hasSavedAnswer: false })
    return {
        ...state,
        isSavingAnswers: true,
        isFetchingFollowUps: true,
        questions: questionSet,
    };
}

export function answerPickListQuestionReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.ANSWER_PICKLISTQUESTION || state.questions === undefined) {
        return state;
    }

    let currQuestion = state.questions.find(a => a.id === action.payload.baseQuestionId);
    if (currQuestion === undefined || currQuestion.pickList === undefined) {
        return state;
    }
    let pickListOptionIndex = currQuestion.pickList.findIndex(a => a.id === action.payload.id);
    if (pickListOptionIndex === -1) {
        return state;
    }

    let clonedPickList = [...currQuestion.pickList];

    clonedPickList[pickListOptionIndex] = { ...clonedPickList[pickListOptionIndex], answer: action.payload.answer, followUpQuestionRetrieved: false };

    let updatedState = {
        ...state,
        questions: updateQuestionSet(
            action.payload.baseQuestionId,
            state.questions,
            {
                pickList: clonedPickList
            }
        )
    };
    return { ...updatedState, isFetchingFollowUps: areQuestionsFetchingFollowUps(updatedState) }
}

export function updateQuestionOptionsReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.UPDATE_QUESTIONOPTIONS || state.questions === undefined) {
        return state;
    }

    return { ...state, questions: updateQuestionSet(action.payload.id, state.questions, { options: action.payload.options }) };
}

export function setFollowUpQuestionReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.SET_FOLLOWUPQUESTION || state.questions === undefined) {
        return state;
    }

    let updatedState = { ...state, questions: updateQuestionSet(action.payload.id, state.questions, { followUpQuestionRetrieved: true, followUpQuestion: action.payload.followUpQuestion }) };
    return { ...updatedState, isFetchingFollowUps: areQuestionsFetchingFollowUps(updatedState) }
}

export function setFollowUpQuestionPickListReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.SET_FOLLOWUPQUESTIONPICKLIST || state.questions === undefined) {
        return state;
    }
    let currQuestion = state.questions.find(a => a.id === action.payload.questionId);
    if (currQuestion === undefined || currQuestion.pickList === undefined) {
        return state;
    }
    let pickListOptionIndex = currQuestion.pickList.findIndex(a => a.id === action.payload.pickListId);
    if (pickListOptionIndex === -1) {
        return state;
    }

    let clonedPickList = [...currQuestion.pickList];

    clonedPickList[pickListOptionIndex] = { ...clonedPickList[pickListOptionIndex], followUpQuestion: action.payload.followUpQuestion, followUpQuestionRetrieved: true };

    let updatedState = {
        ...state,
        questions: updateQuestionSet(
            action.payload.questionId,
            state.questions,
            {
                pickList: clonedPickList
            }
        )
    };
    return { ...updatedState, isFetchingFollowUps: areQuestionsFetchingFollowUps(updatedState) }
}

const updateQuestionSet = (id: string, allQuestions: BaseQuestion[], values: Partial<BaseQuestion>): BaseQuestion[] => {
    return allQuestions.map(a => updateQuestion(id, a, values));
}

const updateQuestion = <T extends Question | BaseQuestion>(id: string, question: T, values: Partial<Question>): T => {
    if (question.id === id) {
        return { ...question, ...values }
    }
    let result = { ...question };
    if (result.followUpQuestion !== undefined) {
        result.followUpQuestion = updateQuestion(id, result.followUpQuestion, values);
    }
    if (result.subQuestions !== undefined) {
        result.subQuestions = result.subQuestions.map(a => updateQuestion(id, a, values));
    }
    if (result.pickList !== undefined) {
        result.pickList = result.pickList.map(a => updatePickListQuestion(id, a, values));
    }
    return result;
}

const updatePickListQuestion = (id: string, pickListOption: PickListOption, values: Partial<Question>): PickListOption => {
    if (pickListOption.followUpQuestion === undefined) {
        return { ...pickListOption };
    }
    return { ...pickListOption, followUpQuestion: updateQuestion(id, pickListOption.followUpQuestion, values) };
}

export function setHasSavedAnswerReducer(state: InterviewQuestionsState, action: Action): InterviewQuestionsState {
    if (action.type !== Actions.SET_HASSAVEDANSWER || state.questions === undefined) {
        return state;
    }

    let updatedState = { ...state, questions: updateQuestionSet(action.payload.id, state.questions, { hasSavedAnswer: true }) };
    return { ...updatedState, isSavingAnswers: areQuestionAnswersSaving(updatedState) };
}

export default createReducer<InterviewQuestionsState, Actions.Types, Action>({ isRetrievingQuestions: false, isSavingAnswers: false, isFetchingFollowUps: false, followUpQuestionCache: {}, flatFollowUpQuestionCache: {} }, {
    [Actions.FETCH_QUESTIONS]: fetchQuestionsReducer,
    [Actions.RETRIEVED_QUESTIONS]: retrievedQuestionsReducer,
    [Actions.ANSWER_QUESTION]: answerQuestionReducer,
    [Actions.UPDATE_QUESTIONOPTIONS]: updateQuestionOptionsReducer,
    [Actions.SET_FOLLOWUPQUESTION]: setFollowUpQuestionReducer,
    [Actions.SET_HASSAVEDANSWER]: setHasSavedAnswerReducer,
    [Actions.STORE_FOLLOWUPQUESTION]: storeFollowUpQuestionReducer,
    [Actions.SET_FOLLOWUPQUESTIONPICKLIST]: setFollowUpQuestionPickListReducer,
    [Actions.ANSWER_PICKLISTQUESTION]: answerPickListQuestionReducer
});