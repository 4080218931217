import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import * as DemographicInformationActions from '../DemographicInformation/Actions';
import BenefitAmountState from "../../models/State/BenefitAmountState";

type Action = ReturnType<typeof DemographicInformationActions.updateDateOfBirthAction> |
              ReturnType<typeof DemographicInformationActions.updateUseNicotineProductsAction> |
              ReturnType<typeof DemographicInformationActions.updateGenderAction> |
              ReturnType<typeof DemographicInformationActions.updateStateAction> |
              ReturnType<typeof Actions.selectBenefitAmountAction> |
              ReturnType<typeof Actions.fetchAvailableBenefitAmountsAction> |
              ReturnType<typeof Actions.retrievedAvailableBenefitAmountsAction>;

type allActions = Actions.Types | 
                  typeof DemographicInformationActions.UPDATE_DATEOFBIRTH |
                  typeof DemographicInformationActions.UPDATE_USENICOTINEPRODUCTS |
                  typeof DemographicInformationActions.UPDATE_GENDER |
                  typeof DemographicInformationActions.UPDATE_STATE;

export function updateKeyDemographicsReducer(state: BenefitAmountState, action: Action): BenefitAmountState {
    if (action.type !== DemographicInformationActions.UPDATE_DATEOFBIRTH &&
        action.type !== DemographicInformationActions.UPDATE_USENICOTINEPRODUCTS  &&
        action.type !== DemographicInformationActions.UPDATE_GENDER &&
        action.type !== DemographicInformationActions.UPDATE_STATE) {
        return state;
    }
    
    return { ...state, availableAmounts: undefined, selectedAmount: undefined };
}

export function selectBenefitAmountReducer(state: BenefitAmountState, action: Action): BenefitAmountState {
    if (action.type !== Actions.SELECT_BENEFITAMOUNT) {
        return state;
    }
    
    return { ...state, selectedAmount: action.payload.benefitAmount };
}

export function fetchAvailableBenefitAmountsReducer(state: BenefitAmountState, action: Action): BenefitAmountState {
    if (action.type !== Actions.FETCH_AVAILABLEBENEFITAMOUNTS) {
        return state;
    }

    return { ...state, isLoadingAvailableAmounts: true  };
}

export function retrievedAvailableBenefitAmountsReducer(state: BenefitAmountState, action: Action): BenefitAmountState {
    if (action.type !== Actions.RETRIEVED_AVAILABLEBENEFITAMOUNTS) {
        return state;
    }

    var currSelectedAmount = state.selectedAmount;
    if(action.payload.availableBenefitAmounts===undefined){
        currSelectedAmount = undefined;
    } else {
        currSelectedAmount = action.payload.availableBenefitAmounts.find(a=>a.isRecommended);
    }
    
    return { ...state, availableAmounts: action.payload.availableBenefitAmounts, isLoadingAvailableAmounts: false, selectedAmount: currSelectedAmount };
}

export default createReducer<BenefitAmountState, allActions, Action>({isLoadingAvailableAmounts: false}, {
    [DemographicInformationActions.UPDATE_DATEOFBIRTH]: updateKeyDemographicsReducer,
    [DemographicInformationActions.UPDATE_USENICOTINEPRODUCTS]: updateKeyDemographicsReducer,
    [DemographicInformationActions.UPDATE_GENDER]: updateKeyDemographicsReducer,
    [DemographicInformationActions.UPDATE_STATE]: updateKeyDemographicsReducer,
    [Actions.SELECT_BENEFITAMOUNT]: selectBenefitAmountReducer,
    [Actions.FETCH_AVAILABLEBENEFITAMOUNTS]: fetchAvailableBenefitAmountsReducer,
    [Actions.RETRIEVED_AVAILABLEBENEFITAMOUNTS]: retrievedAvailableBenefitAmountsReducer,
});