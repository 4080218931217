import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import CaseState from "../../models/State/CaseState";

type Action = ReturnType<typeof Actions.loadingCreateCaseAction> |
    ReturnType<typeof Actions.receivedCreateCaseAction> |
    ReturnType<typeof Actions.setCaseResultAction>;

export function loadingCreateCaseReducer(state: CaseState, action: Action): CaseState {
    if (action.type !== Actions.CREATE_CASE) {
        return state;
    }

    return { ...state, isLoadingCaseResponse: true };
}

export function receivedCreateCaseReducer(state: CaseState, action: Action): CaseState {
    if (action.type !== Actions.RETRIEVED_CASE) {
        return state;
    }

    return { ...state, case: action.payload.case, isLoadingCaseResponse: false };
}

export function updateCaseResultReducer(state: CaseState, action: Action): CaseState {
    if (action.type !== Actions.UPDATE_CASERESULT) {
        return state;
    }
    return { ...state, caseResult: action.payload.result };
}

export default createReducer<CaseState, Actions.Types, Action>({ isLoadingCaseResponse: false }, {
    [Actions.CREATE_CASE]: loadingCreateCaseReducer,
    [Actions.RETRIEVED_CASE]: receivedCreateCaseReducer,
    [Actions.UPDATE_CASERESULT]: updateCaseResultReducer
});