import React, { ChangeEvent } from 'react';
import moment from 'moment';

interface DateSelectorComponentProps {
    label: string;
    defaultValue?: Date;
    dateValidation?: string;
    dateCallback: (date: Date) => void;
}

interface DateSelectorComponentState {
    date?: Date;
    dateFormatted: string;
    dateCursorLocation: number;
    dateHasCompleted: boolean;
}

export class DateSelector extends React.Component<DateSelectorComponentProps, DateSelectorComponentState>{
    private input: React.RefObject<HTMLInputElement>;
    constructor(props: DateSelectorComponentProps) {
        super(props);
        this.input = React.createRef();
        this.state = {
            date: undefined,
            dateFormatted: "",
            dateCursorLocation: 0,
            dateHasCompleted: false
        };
    }
    componentDidMount() {
        if (this.props.defaultValue !== undefined) {
            this.setState({
                dateFormatted: moment(this.props.defaultValue).format('MM[/]DD[/]YYYY').toString()
            });
        }
    }
    componentDidUpdate() {
        if (this.input.current !== null) {
            this.input.current.selectionStart = this.state.dateCursorLocation;
            this.input.current.selectionEnd = this.state.dateCursorLocation;
        }
    }
    private updateDate = (e: ChangeEvent<HTMLInputElement>) => {
        this.formatDate(e);
        
        if (this.state.dateHasCompleted || e.target.value.length >= 10) {
            let date = moment(e.target.value, "MM/DD/YYYY").toDate();
            this.props.dateCallback(date);
            this.setState({
                date: date
            });
        }
    }
    private formatDate = (e: ChangeEvent<HTMLInputElement>) => {
        let cursor = e.target.selectionStart ? e.target.selectionStart++ : 0;
        let date = e.target.value.replace(/[^0-9 ^MDY]/g, "")

        if (date.length === 1 && parseInt(date) > 1) {
            date = "0" + date;
        }
        if (date.length > 1) {
            date = date.substr(0, 2) + "/" + date.substr(2);
        }
        if (date.length > 2 && parseInt(date.substr(0, 2)) > 12) {
            date = "12/" + date.substr(3);
        }
        if (date.length === 4 && parseInt(date.charAt(3)) > 3) {
            date = date.substr(0, 3) + "0" + date.substr(3);
        }
        if (date.length > 4) {
            date = date.substr(0, 5) + "/" + date.substr(5);
        }
        if (date.length > 5 && parseInt(date.substr(3, 5)) > 31) {
            date = date.substr(0, 3) + "31/" + date.substr(6);
        }

        if (e.target.defaultValue.length > e.target.value.length && e.target.defaultValue.charAt(e.target.defaultValue.length - 2) === '/') {
            date = e.target.value.slice(0, -1);
        }
        else if (e.target.defaultValue.length > e.target.value.length) {
            date = e.target.value;
        }

        cursor = date.length + 1;
        if (date.length === 3) {
            cursor = date.length + 1;
        }
        if (date.length === 6) {
            cursor = date.length + 1;
        }

        if (date.length >= 9 && !this.state.dateHasCompleted) {
            this.setState({
                dateFormatted: date,
                dateCursorLocation: cursor,
                dateHasCompleted: true
            });
        } else {
            this.setState({
                dateFormatted: date,
                dateCursorLocation: cursor
            });
        }
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>{this.props.label}</label>
                            <input type="tel" className="form-control" ref={this.input} name="date" id="date" maxLength={10} onChange={this.updateDate.bind(this)} value={this.state.dateFormatted} placeholder="MM/DD/YYYY" min="1900-01-01" max={moment().format('YYYY-MM-DD')} />
                            {(this.props.dateValidation !== undefined && this.state.date !== undefined) &&
                                <div className="validation-text">{this.props.dateValidation}</div>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DateSelector;