import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { getSettings } from './Settings';
import ReactGA from 'react-ga';
import AppState from './models/State/AppState';
import CurrentPage from './models/CurrentPage';
import { getCurrentQuestion } from './redux/InterviewQuestions/Utilities';
import { Store } from 'redux';

export const initializeApplicationInsights = (): ReactPlugin | undefined => {
    const browserHistory = createBrowserHistory({ basename: '' });
    let aiInstrumentationKey = normalizeSetting(getSettings().aiInstrumentationKey);
    if (aiInstrumentationKey === undefined) {
        return undefined;
    }
    let reactPlugin = new ReactPlugin();
    let appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: aiInstrumentationKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    });
    appInsights.loadAppInsights();
    return reactPlugin;
}

let currentState: AppState;
export const initializeGoogleAnalytics = (store: Store<AppState, any>): void => {
    let gaKey = normalizeSetting(getSettings().gaKey);
    if (gaKey === undefined) {
        return;
    }
    ReactGA.initialize(gaKey);

    store.subscribe(() => {
        let previousState = currentState;
        currentState = store.getState();
        if (previousState === undefined || currentState.currentPage.currentPage !== previousState.currentPage.currentPage) {
          recordPageView(currentState);
        }
        else if (currentState.currentPage.currentPage === CurrentPage.InterviewQuestions && currentState.currentPage.currentQuestionIndex !== previousState.currentPage.currentQuestionIndex) {
          recordPageView(currentState);
        }
      });
}

const recordPageView = (state: AppState): void => {
    if (state.currentPage.currentPage === undefined) {
        return;
    }
    if (state.currentPage.currentPage === CurrentPage.InterviewQuestions) {
        let currentQuestion = getCurrentQuestion(state);
        if (currentQuestion !== undefined) {
            ReactGA.pageview(`${CurrentPage[state.currentPage.currentPage]}_${currentQuestion.id}`);
            return;
        }
    }
    ReactGA.pageview(CurrentPage[state.currentPage.currentPage]);
}

export const recordEvent = (category: string, action: string, label?: string, value?: number): void => {
    let gaKey = normalizeSetting(getSettings().gaKey);
    if (gaKey === undefined) {
        return;
    }
    ReactGA.event({category: category, action: action, label: label, value: value});
}

const normalizeSetting = (value: string | undefined): string | undefined => {
    if (value === undefined ||
        value === null ||
        value.length === 0 ||
        (value.startsWith('#') && value.endsWith('#'))) {
        return undefined;
    }
    return value;
}


