import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose } from "redux";
import appReducer from "./redux/AppReducer";

declare global {
    interface Window { devToolsExtension?: any; }
}

let win = window as any;
const enhancers = compose(
    applyMiddleware(thunkMiddleware),
    win.devToolsExtension ? win.devToolsExtension() : (f: any) => f
  );
const store = createStore(appReducer, {}, enhancers);
export default store;