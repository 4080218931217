import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { nextPageAction } from '../../redux/CurrentPage/Actions';
import AppState from '../../models/State/AppState';
import Case from '../../models/Case';

import './FinalApproved'
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        emailAddress: state.personalInformation.emailAddress,
        policy: state.case.case,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        continue: () => dispatch(nextPageAction())
    };
};

interface TentativeSentToUnderwritingComponentProps {
    emailAddress?: string;
    policy?: Case;
    continue: () => void;
}

class TentativeSentToUnderwriting extends React.Component<TentativeSentToUnderwritingComponentProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private continue = () => {
        this.props.continue();
    }

    render() {
        return (
            <>            
                <div className="p-3">
                    <div className="row mb-3">
                        <h1 className="status-header-text">Based on a preliminary review, it looks like we need additional information to make a decision on your application.</h1>
                        <p>A final underwriting decision will be provided upon application submission and review of your application.</p>
                    </div>
                    <div className="row">
                        <div className="">
                            <button className="btn btn-primary btn-block" onClick={this.continue}>Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(TentativeSentToUnderwriting));