import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import BenefitAmount from "../../models/BenefitAmount";
import AvailableBenefitAmount from "../../models/AvailableBenefitAmount";
import AppState from "../../models/State/AppState";
import { getAvailableBenefitAmounts } from "../../accessors/QuoteAccessor";

// Action Types
export const SELECT_BENEFITAMOUNT = 'benefit_amount/select_benefitamount';
export const FETCH_AVAILABLEBENEFITAMOUNTS = 'benefit_amount/fetch_available';
export const RETRIEVED_AVAILABLEBENEFITAMOUNTS = 'benefit_amount/retrieved_available';

export type Types = typeof SELECT_BENEFITAMOUNT |
                    typeof FETCH_AVAILABLEBENEFITAMOUNTS |
                    typeof RETRIEVED_AVAILABLEBENEFITAMOUNTS;

export interface SelectBenefitAmountPayload{
    benefitAmount?: BenefitAmount;
}

export interface FetchAvailableBenefitAmountsPayload{
}

export interface RetrievedAvailableBenefitAmountsPayload{
    availableBenefitAmounts?: AvailableBenefitAmount[];
}

type TypePayload<T1 extends Types, T2> = {type: T1, payload: T2};

export function selectBenefitAmountAction(benefitAmount?: BenefitAmount): TypePayload<typeof SELECT_BENEFITAMOUNT, SelectBenefitAmountPayload> {
    return {type: SELECT_BENEFITAMOUNT, payload: {benefitAmount: benefitAmount}};
}

export const loadAvailableBenefitAmountsAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: ()=>AppState): Promise<any> => {
        const currState = getState();
        if(currState.benefitAmount.availableAmounts === undefined && !currState.benefitAmount.isLoadingAvailableAmounts){
            if(currState.demographicInformation.gender === undefined || currState.demographicInformation.dateOfBirth === undefined || currState.demographicInformation.useNicotineProducts === undefined|| currState.demographicInformation.state === undefined){
                throw new Error("not enough data to retrieve available benefit amounts");
            }
            dispatch(fetchAvailableBenefitAmountsAction());

            const availableBenefitAmounts = await getAvailableBenefitAmounts(currState.demographicInformation.gender, currState.demographicInformation.dateOfBirth, currState.demographicInformation.useNicotineProducts, currState.demographicInformation.state, currState.demographicInformation.referrer, currState.demographicInformation.email, currState.demographicInformation.partnerSubdomain);
            return dispatch(retrievedAvailableBenefitAmountsAction(availableBenefitAmounts));
        }
    };
}

export function fetchAvailableBenefitAmountsAction(): TypePayload<typeof FETCH_AVAILABLEBENEFITAMOUNTS, FetchAvailableBenefitAmountsPayload> {
    return {type: FETCH_AVAILABLEBENEFITAMOUNTS, payload: {}};
}

export function retrievedAvailableBenefitAmountsAction(availableBenefitAmounts: AvailableBenefitAmount[]): TypePayload<typeof RETRIEVED_AVAILABLEBENEFITAMOUNTS, RetrievedAvailableBenefitAmountsPayload> {
    return {type: RETRIEVED_AVAILABLEBENEFITAMOUNTS, payload: {availableBenefitAmounts: availableBenefitAmounts}};
}

