import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import AppState from "../../models/State/AppState";
import { getLinks } from "../../accessors/BrandingAccessor";
import { Dictionary } from "../../models/State/BrandState";

export const FETCH_BRANDLINKS = 'brand/fetch_brandlinks';
export const RECEIVED_BRANDLINKS = 'brand/received_brandlinks';

export type Types = typeof FETCH_BRANDLINKS |
    typeof RECEIVED_BRANDLINKS;

export interface FetchBrandLinksPayload {

}

export interface ReceivedBrandLinksPayload {
    links?: Dictionary;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export const loadLinksAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();

        if (currState.brand.links === undefined && !currState.brand.isLoadingLinks) {
            dispatch(fetchBrandLinksAction());

            const links = await getLinks();
            return dispatch(receivedBrandLinksAction(links));
        }
    }
}

export function fetchBrandLinksAction(): TypePayload<typeof FETCH_BRANDLINKS, FetchBrandLinksPayload> {
    return { type: FETCH_BRANDLINKS, payload: {} };
}

export function receivedBrandLinksAction(links?: Dictionary): TypePayload<typeof RECEIVED_BRANDLINKS, ReceivedBrandLinksPayload> {
    return { type: RECEIVED_BRANDLINKS, payload: { links: links } };
}