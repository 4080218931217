import { getSettings } from "../Settings";
import { processRequest } from "./AccessorUtilities";

const submitApplicationUrl: string = "/api/quote/submitapplication.json";

export async function submitApplication(applicationRequest: ApplicationRequest) {

    let settings = getSettings();
    var url = new URL(submitApplicationUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);

    const response = await post(url.toString(), {
        caseId: applicationRequest.caseId,
        envelopeId: applicationRequest.envelopeId,
        paymentId: applicationRequest.paymentId,
        benefitAmount: applicationRequest.benefitAmount,
        referrer: applicationRequest.referrer
    }).catch(error => console.error(error));

    return response as SubmitApplicationResponse;
}

async function post(url = '', data = {}) {
    return await processRequest(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    })
        .then(response => response.json());
}

export interface ApplicationRequest {
    caseId: string;
    envelopeId: string;
    paymentId: string;
    benefitAmount: number;
    referrer: string;
}

export interface SubmitApplicationResponse {
    policyNumber: number;
}