import * as EmailValidator from 'email-validator';

import { Validation, PersonalInformationValidation } from "../../models/State/ValidationState";
import AppState from "../../models/State/AppState";
import moment from "moment";
import NicotineType from '../../models/NicotineType';

const userDidAcceptTerms = (currState: AppState): boolean => {
    if (currState.personalInformation.userDidAcceptTerms === undefined) {
        return false;
    }
    return currState.personalInformation.userDidAcceptTerms;
}

const isUsersFirstNameValid = (currState: AppState): boolean => {
    return !isStringEmpty(currState.personalInformation.usersFirstName);
}

const isUsersLastNameValid = (currState: AppState): boolean => {
    return !isStringEmpty(currState.personalInformation.usersLastName);
}

const isEmailAddressValid = (currState: AppState): boolean => {
    return currState.personalInformation.emailAddress !== undefined && EmailValidator.validate(currState.personalInformation.emailAddress);
}

const isStreetAddressValid = (currState: AppState): boolean => {
    return !isStringEmpty(currState.personalInformation.streetAddress);
}

const isCityValid = (currState: AppState): boolean => {
    return !isStringEmpty(currState.personalInformation.city);
}

const isZipValid = (currState: AppState): boolean => {
    return currState.personalInformation.zip !== undefined && currState.personalInformation.zip.length === 5;
}

const isCitizenshipValid = (currState: AppState): { isCitizenValid: boolean, isPermanentResidentValid: boolean, isGreenCardRequired: boolean, isGreenCardValid: boolean } => {
    if (currState.personalInformation.isCitizen === undefined) {
        return {
            isCitizenValid: false,
            isPermanentResidentValid: false,
            isGreenCardRequired: false,
            isGreenCardValid: false
        };
    }
    if (currState.personalInformation.isCitizen === true) {
        return {
            isCitizenValid: true,
            isPermanentResidentValid: true,
            isGreenCardRequired: false,
            isGreenCardValid: true
        };
    }
    if (currState.personalInformation.isPermanentResident === undefined) {
        return {
            isCitizenValid: true,
            isPermanentResidentValid: false,
            isGreenCardRequired: false,
            isGreenCardValid: false
        };
    }
    if (currState.personalInformation.isPermanentResident === true) {
        return {
            isCitizenValid: true,
            isPermanentResidentValid: true,
            isGreenCardRequired: true,
            isGreenCardValid: !isStringEmpty(currState.personalInformation.greenCard)
        };
    }

    return {
        isCitizenValid: true,
        isPermanentResidentValid: true,
        isGreenCardRequired: false,
        isGreenCardValid: true
    };
}

const isBirthLocationValid = (currState: AppState): boolean => {
    if (currState.birthLocation.availableCountries === undefined) {
        return false;
    }
    if (isStringEmpty(currState.personalInformation.birthCountry)) {
        return false;
    }
    let matchingCountry = currState.birthLocation.availableCountries.find(a => a.value === currState.personalInformation.birthCountry);
    if (matchingCountry === undefined) {
        return false;
    }
    if (matchingCountry.states === undefined || matchingCountry.states.length === 0) {
        return true;
    }
    let matchingState = matchingCountry.states.find(a => a.value === currState.personalInformation.birthState);
    return matchingState !== undefined;
}

const isPhoneNumberValid = (currState: AppState): boolean => {
    return !isStringEmpty(currState.personalInformation.phoneNumber);
}

const isSocialSecurityNumberValid = (currState: AppState): boolean => {
    return currState.personalInformation.socialSecurityNumber !== undefined && /^\d{3}-\d{2}-\d{4}$/.test(currState.personalInformation.socialSecurityNumber);
}

const isTobaccoUsesValid = (currState: AppState): boolean => {
    return currState.personalInformation.tobaccoUses !== undefined;
}

const isTobaccoTypeValid = (currState: AppState): boolean => {
    if(currState.personalInformation.tobaccoUses === false){
        return true;
    }

    return currState.personalInformation.tobaccoType !== undefined;
}

const isTobaccoUseCountValid = (currState: AppState): boolean => {
    if(currState.personalInformation.tobaccoUses === false){
        return true;
    }

    if(currState.personalInformation.tobaccoType === NicotineType.Smokeless ||
       currState.personalInformation.tobaccoType === NicotineType.Nicotine_Patches_Gum_Other){
        return true;
    }

    return (currState.personalInformation.tobaccoDailyUseCount !== undefined &&
            currState.personalInformation.tobaccoDailyUseCount > 0 ) ||
           (currState.personalInformation.tobaccoMonthlyUseCount !== undefined &&
            currState.personalInformation.tobaccoMonthlyUseCount > 0 );
}

function isTobaccoLastUseDateValid(currState: AppState) {
    if(currState.personalInformation.tobaccoUses === false){
        return "";
    }
    
    if(currState.personalInformation.tobaccoLastUseDate !== undefined ){
        if(currState.personalInformation.tobaccoLastUseDate > moment().toDate()){
            return "Please enter a valid date (Cannot be a future date)"
        }
        if(currState.demographicInformation.dateOfBirth !== undefined ){
            if(currState.personalInformation.tobaccoLastUseDate < currState.demographicInformation.dateOfBirth){
                return "Please enter a valid date (Cannot be before date of birth)"
            }
        }
        if(!moment(currState.personalInformation.tobaccoLastUseDate).isValid()){
            return "Please enter a valid date.";
        }
    } else {
        return "Please enter a valid date (Date cannot be undefined)";
    }

    return "";
}

const isHeightFeetValid = (currState: AppState): boolean => {
    return currState.personalInformation.heightFeet !== undefined && currState.personalInformation.heightFeet > 0 && currState.personalInformation.heightFeet <= 9;
}

const isHeightInchesValid = (currState: AppState): boolean => {
    return currState.personalInformation.heightInches !== undefined && currState.personalInformation.heightInches >= 0 && currState.personalInformation.heightInches < 12;
}

const isWeightValid = (currState: AppState): boolean => {
    return currState.personalInformation.weightPounds !== undefined && currState.personalInformation.weightPounds > 0 && currState.personalInformation.weightPounds <= 1000;
}

const isHasExistingCoverageValid = (currState: AppState): boolean => {
    return currState.personalInformation.hasExistingCoverage !== undefined;
}

const isHasComprehensiveHealthcareValid = (currState: AppState): boolean => {
    return currState.personalInformation.hasComprehensiveHealthcare !== undefined;
}

function isStringEmpty(value?: string): boolean {
    return value === undefined || value === null || value.trim().length === 0;
}

export default function personalInformationReducer(currState: AppState): Validation<PersonalInformationValidation> {
    let fields: PersonalInformationValidation = Object.assign(
        {},
        {
            userDidAcceptTerms: userDidAcceptTerms(currState),
            isUsersFirstNameValid: isUsersFirstNameValid(currState),
            isUsersLastNameValid: isUsersLastNameValid(currState),
            isEmailAddressValid: isEmailAddressValid(currState),
            isStreetAddressValid: isStreetAddressValid(currState),
            isCityValid: isCityValid(currState),
            isZipValid: isZipValid(currState),
            isBirthLocationValid: isBirthLocationValid(currState),
            isPhoneNumberValid: isPhoneNumberValid(currState),
            isSocialSecurityNumberValid: isSocialSecurityNumberValid(currState),
            isTobaccoUsesValid: isTobaccoUsesValid(currState),
            isTobaccoTypeValid: isTobaccoTypeValid(currState),
            isTobaccoUseCountValid: isTobaccoUseCountValid(currState),
            tobaccoLastUseDateValidation: isTobaccoLastUseDateValid(currState),
            isHeightFeetValid: isHeightFeetValid(currState),
            isHeightInchesValid: isHeightInchesValid(currState),
            isWeightValid: isWeightValid(currState),
            isHasExistingCoverageValid: isHasExistingCoverageValid(currState),
            isHasComprehensiveHealthcareValid: isHasComprehensiveHealthcareValid(currState)           
        },
        isCitizenshipValid(currState)
    );
    return {
        overall: fields.userDidAcceptTerms &&
            fields.isUsersFirstNameValid &&
            fields.isUsersLastNameValid &&
            fields.isEmailAddressValid &&
            fields.isStreetAddressValid &&
            fields.isCityValid &&
            fields.isZipValid &&
            fields.isCitizenValid &&
            fields.isHasExistingCoverageValid &&
            fields.isHasComprehensiveHealthcareValid &&
            fields.isPermanentResidentValid &&
            fields.isGreenCardValid &&
            fields.isBirthLocationValid &&
            fields.isPhoneNumberValid &&
            fields.isSocialSecurityNumberValid &&
            fields.isTobaccoUsesValid &&
            fields.isTobaccoTypeValid &&
            fields.isTobaccoUseCountValid &&
            !fields.tobaccoLastUseDateValidation,
        fields: fields
    }
}