export enum QuestionType {
    BinaryChoice = 0,
    Search = 1,
    Text = 2,
    Options = 3,
    PrimaryQuestion = 4,
    SubQuestion = 5,
    Numeric = 6
}

export type Question = {
    id: string;
    type: QuestionType;
    text: string;
    subHeadingHtml?: string;
    options: QuestionOption[];
    answer?: string;
    searchCategoryId?: string;
    followUpQuestion?: Question;
    followUpQuestionRetrieved: boolean;
    pickList?: PickListOption[];
    subQuestions?: Question[];
}

export type BaseQuestion = Question & {
    hasSavedAnswer: boolean;
}

export type QuestionOption = {
    value: string;
    text: string;
    followUpId?: string;
    pickList?: PickListOption[];
}

export interface PickListOption {
    id: string;
    text: string;
    answer?: string;
    followUpQuestion?: Question;
    followUpQuestionRetrieved: boolean;
}

export type FollowUpQuestion = {
    id: string;
    type: QuestionType;
    text: string;
    baseQuestionId: string;
    followUpId: string;
    answer?: string;
    options: FollowUpQuestionOption[];
}

export type FollowUpQuestionOption = {
    value: string;
    text: string;
    followUpQuestion?: FollowUpQuestion;
}