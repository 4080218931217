import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import PersonalInformationState from "../../models/State/PersonalInformationState";

type Action = ReturnType<typeof Actions.updateUsersDidAcceptTermsAction> |
    ReturnType<typeof Actions.updateUsersFirstNameAction> |
    ReturnType<typeof Actions.updateUsersLastNameAction> |
    ReturnType<typeof Actions.updateEmailAddressAction> |
    ReturnType<typeof Actions.updateStreetAddressAction> |
    ReturnType<typeof Actions.updateStreetAddress2Action> |
    ReturnType<typeof Actions.updateCityAction> |
    ReturnType<typeof Actions.updateZipAction> |
    ReturnType<typeof Actions.updateIsCitizenAction> |
    ReturnType<typeof Actions.updateHasExistingCoverageAction> |
    ReturnType<typeof Actions.updateHasComprehensiveHealthcareAction> |
    ReturnType<typeof Actions.updateIsPermanentResidentAction> |
    ReturnType<typeof Actions.updateGreenCardAction> |
    ReturnType<typeof Actions.updateBirthCountryAction> |
    ReturnType<typeof Actions.updateBirthStateAction> |
    ReturnType<typeof Actions.updatePhoneNumberAction> |
    ReturnType<typeof Actions.updateSocialSecurityNumberAction> |
    ReturnType<typeof Actions.updateTobaccoUsesAction> |
    ReturnType<typeof Actions.updateTobaccoTypeAction> |
    ReturnType<typeof Actions.updateTobaccoDailyUseCountAction> |
    ReturnType<typeof Actions.updateTobaccoMonthlyUseCountAction> |
    ReturnType<typeof Actions.updateTobaccoLastUseDateAction> |
    ReturnType<typeof Actions.updateHeightFeetAction> |
    ReturnType<typeof Actions.updateHeightInchesAction> |
    ReturnType<typeof Actions.updateWeightAction>;

export function updateUsersDidAcceptTermsReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_USERDIDACCEPTTERMS) {
        return state;
    }
    return { ...state, userDidAcceptTerms: action.payload.userDidAcceptTerms };
}

export function updateUsersFirstNameReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_USERSFIRSTNAME) {
        return state;
    }
    return { ...state, usersFirstName: action.payload.usersFirstName };
}

export function updateUsersLastNameReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_USERSLASTNAME) {
        return state;
    }
    return { ...state, usersLastName: action.payload.usersLastName };
}

export function updateEmailAddressReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_EMAILADDRESS) {
        return state;
    }
    return { ...state, emailAddress: action.payload.emailAddress };
}

export function updateStreetAddressReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_STREETADDRESS) {
        return state;
    }
    return { ...state, streetAddress: action.payload.streetAddress };
}

export function updateStreetAddress2Reducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_STREETADDRESS2) {
        return state;
    }
    return { ...state, streetAddress2: action.payload.streetAddress2 };
}

export function updateCityReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_CITY) {
        return state;
    }
    let value = action.payload.city ? action.payload.city.replace(/^\s*/g, "") : action.payload.city;;
    if(value !== undefined){
        value = value.replace(/[^a-zA-Z\s]/g, "");
    }
    return { ...state, city: value };
}

export function updateZipReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_ZIP) {
        return state;
    }
    let value = action.payload.zip ? action.payload.zip.trim() : action.payload.zip;
    return { ...state, zip: value };
}

export function updateIsCitizenReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_ISCITIZEN) {
        return state;
    }
    return updateCitizenshipData(state, action.payload.isCitizen, state.isPermanentResident, state.greenCard);
}

export function updateHasExistingCoverageReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_HASEXISTINGCOVERAGE) {
        return state;
    }
    return { ...state, hasExistingCoverage: action.payload.hasExistingCoverage };
}

export function updateHasComprehensiveHealthcareReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_HASCOMPREHENSIVEHEALTHCARE) {
        return state;
    }
    return { ...state, hasComprehensiveHealthcare: action.payload.hasComprehensiveHealthcare };
}

export function updateIsPermanentResidentReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_ISPERMANENTRESIDENT) {
        return state;
    }
    return updateCitizenshipData(state, state.isCitizen, action.payload.isPermanentResident, state.greenCard);
}

export function updateGreenCardReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_GREENCARD) {
        return state;
    }
    return updateCitizenshipData(state, state.isCitizen, state.isPermanentResident, action.payload.greenCard);
}

export function updateBirthCountryReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_BIRTHCOUNTRY) {
        return state;
    }
    return { ...state, birthCountry: action.payload.birthCountry };
}

export function updateBirthStateReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_BIRTHSTATE) {
        return state;
    }
    return { ...state, birthState: action.payload.birthState };
}

export function updatePhoneNumberReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_PHONENUMBER) {
        return state;
    }
    var value = action.payload.phoneNumber;
    if(value !== undefined){
        value = value.replace(/[^0-9]/g, "");
        if(value.length > 3)
        {
            value = "(" + value.substr(0, 3) + ") " + value.substr(3);
        }
        if(value.length > 9){
            value = value.substr(0, 9) + "-" + value.substr(9);
        }
    }
    return { ...state, phoneNumber: value };
}

export function updateSocialSecurityNumberReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_SOCIALSECURITYNUMBER) {
        return state;
    }
    var value = action.payload.socialSecurityNumber;
    if(value !== undefined){
        value = value.replace(/[-]|[^0-9]/g, "");
        if(value.length > 3)
        {
            value = value.substr(0, 3) + "-" + value.substr(3);
        }
        if(value.length > 6){
            value = value.substr(0, 6) + "-" + value.substr(6);
        }
    }
    return { ...state, socialSecurityNumber: value };
}

export function updateTobaccoUsesReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_TOBACCOUSES) {
        return state;
    }
    return { ...state, tobaccoUses: action.payload.tobaccoUses };
}

export function updateTobaccoTypeReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_TOBACCOTYPE) {
        return state;
    }
    return { ...state, tobaccoType: action.payload.tobaccoType };
}

export function updateTobaccoDailyUseCountReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_TOBACCODAILYUSECOUNT) {
        return state;
    }
    return { ...state, tobaccoDailyUseCount: action.payload.tobaccoDailyUseCount };
}

export function updateTobaccoMonthlyUseCountReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_TOBACCOMONTHLYUSECOUNT) {
        return state;
    }
    return { ...state, tobaccoMonthlyUseCount: action.payload.tobaccoMonthlyUseCount };
}

export function updateTobaccoLastUseDateReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_TOBACCOLASTUSEDATE) {
        return state;
    }
    return { ...state, tobaccoLastUseDate: action.payload.tobaccoLastUseDate };
}

export function updateHeightFeetReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_HEIGHTFEET) {
        return state;
    }

    if(action.payload.feet !== undefined && action.payload.feet > 9){
        action.payload.feet = 9;
    }

    return { ...state, heightFeet: action.payload.feet };
}

export function updateHeightInchesReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_HEIGHTINCHES) {
        return state;
    }

    if(action.payload.inches !== undefined && action.payload.inches > 11){
        action.payload.inches = 11
    }

    return { ...state, heightInches: action.payload.inches };
}

export function updateWeightReducer(state: PersonalInformationState, action: Action): PersonalInformationState {
    if (action.type !== Actions.UPDATE_WEIGHT) {
        return state;
    }

    if(action.payload.pounds !== undefined && action.payload.pounds > 1000){
        action.payload.pounds = 1000
    }

    return { ...state, weightPounds: action.payload.pounds };
}

const updateCitizenshipData = (state: PersonalInformationState, isCitizen?: boolean, isPermanentResident?: boolean, greenCard?: string): PersonalInformationState => {
    if (isCitizen === undefined) {
        return {
            ...state,
            isCitizen: undefined,
            isPermanentResident: undefined,
            greenCard: undefined,
        };
    }
    if (isCitizen === true) {
        return {
            ...state,
            isCitizen: true,
            isPermanentResident: undefined,
            greenCard: undefined,
        };
    }
    if (isPermanentResident === undefined) {
        return {
            ...state,
            isCitizen: false,
            isPermanentResident: undefined,
            greenCard: undefined,
        };
    }
    if (isPermanentResident === true) {
        return {
            ...state,
            isCitizen: false,
            isPermanentResident: true,
            greenCard: greenCard,
        };
    }

    return {
        ...state,
        isCitizen: false,
        isPermanentResident: false,
        greenCard: undefined,
    };
}

export default createReducer<PersonalInformationState, Actions.Types, Action>({birthCountry: "USA"}, {
    [Actions.UPDATE_USERDIDACCEPTTERMS]: updateUsersDidAcceptTermsReducer,
    [Actions.UPDATE_USERSFIRSTNAME]: updateUsersFirstNameReducer,
    [Actions.UPDATE_USERSLASTNAME]: updateUsersLastNameReducer,
    [Actions.UPDATE_EMAILADDRESS]: updateEmailAddressReducer,
    [Actions.UPDATE_STREETADDRESS]: updateStreetAddressReducer,
    [Actions.UPDATE_STREETADDRESS2]: updateStreetAddress2Reducer,
    [Actions.UPDATE_CITY]: updateCityReducer,
    [Actions.UPDATE_ZIP]: updateZipReducer,
    [Actions.UPDATE_ISCITIZEN]: updateIsCitizenReducer,
    [Actions.UPDATE_HASEXISTINGCOVERAGE]: updateHasExistingCoverageReducer,
    [Actions.UPDATE_HASCOMPREHENSIVEHEALTHCARE]: updateHasComprehensiveHealthcareReducer,
    [Actions.UPDATE_ISPERMANENTRESIDENT]: updateIsPermanentResidentReducer,
    [Actions.UPDATE_GREENCARD]: updateGreenCardReducer,
    [Actions.UPDATE_BIRTHCOUNTRY]: updateBirthCountryReducer,
    [Actions.UPDATE_BIRTHSTATE]: updateBirthStateReducer,
    [Actions.UPDATE_PHONENUMBER]: updatePhoneNumberReducer,
    [Actions.UPDATE_SOCIALSECURITYNUMBER]: updateSocialSecurityNumberReducer,
    [Actions.UPDATE_TOBACCOUSES]: updateTobaccoUsesReducer,
    [Actions.UPDATE_TOBACCOTYPE]: updateTobaccoTypeReducer,
    [Actions.UPDATE_TOBACCODAILYUSECOUNT]: updateTobaccoDailyUseCountReducer,
    [Actions.UPDATE_TOBACCOMONTHLYUSECOUNT]: updateTobaccoMonthlyUseCountReducer,
    [Actions.UPDATE_TOBACCOLASTUSEDATE]: updateTobaccoLastUseDateReducer,
    [Actions.UPDATE_HEIGHTFEET]: updateHeightFeetReducer,
    [Actions.UPDATE_HEIGHTINCHES]: updateHeightInchesReducer,
    [Actions.UPDATE_WEIGHT]: updateWeightReducer
});