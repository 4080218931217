import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Question } from '../../models/Question';
import { submitQuestionAnswersAction } from '../../redux/InterviewQuestions/Actions';


const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    answerQuestions: (answers: { id: string, answer?: string }[]) => dispatch(submitQuestionAnswersAction(answers))
  }
};

interface PrimaryQuestionComponentProps {
  question: Question;
  answerQuestions: (answers: { id: string, answer?: string }[]) => void;
}
class PrimaryQuestionComponent extends React.Component<PrimaryQuestionComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  private answerAllNo = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (this.props.question.subQuestions !== undefined) {
      let answer = e.target.checked ? 'false' : undefined;
      let answers = this.props.question.subQuestions.map(a => {
        return {
          id: a.id,
          answer: answer
        };
      });
      this.props.answerQuestions(answers);
    }
  }
  private answerSubQuestion = (id: string, answer: string): void => {
    if (this.props.question.subQuestions !== undefined) {
      let answers = this.props.question.subQuestions.map(a => {
        let questionAnswer: string | undefined;
        if (a.id === id) {
          questionAnswer = answer;
        } else if (a.answer === undefined) {
          questionAnswer = 'false';
        } else {
          questionAnswer = a.answer;
        }
        return {
          id: a.id,
          answer: questionAnswer
        };
      });
      this.props.answerQuestions(answers);
    }
  }
  render() {
    if (this.props.question.subQuestions === undefined) {
      return undefined;
    }
    return (
      <>
        {
          this.props.question.subQuestions.map(a => <SubQuestion key={a.id} question={a} answerQuestion={this.answerSubQuestion} />)
        }
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <div className="form-check">
                <input type="checkbox" className="form-check-input" id="NoToAll" onChange={this.answerAllNo} checked={this.props.question.subQuestions.every(a => a.answer === "false")} />
                <label className="form-check-label" htmlFor="NoToAll">None of the above</label>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

interface SubQuestionComponentProps {
  question: Question;
  answerQuestion: (id: string, value: string) => void;
}

class SubQuestion extends React.Component<SubQuestionComponentProps>{
  private answer = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.checked ? "true" : "false";
    this.props.answerQuestion(this.props.question.id, value);
  }
  render() {
    const checkboxId = `subQuestion_${this.props.question.id}`;
    return (
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <div className="form-check">
              <input type="checkbox" className="form-check-input" id={checkboxId} onChange={this.answer} checked={this.props.question.answer === "true"} />
              <label className="form-check-label" htmlFor={checkboxId}>{this.props.question.text}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(PrimaryQuestionComponent);