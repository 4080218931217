import React from 'react';
import { connect } from "react-redux";
import { updateSocialSecurityNumberAction } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        socialSecurityNumber: state.personalInformation.socialSecurityNumber
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateSocialSecurityNumber: (value: string | undefined) => dispatch(updateSocialSecurityNumberAction(value))
    };
};

interface SocialSecurityNumberComponentProps {
    socialSecurityNumber?: string;
    updateSocialSecurityNumber: (value: string) => void;
}

export class SocialSecurityNumberComponent extends React.Component<SocialSecurityNumberComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private updateSocialSecurityNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateSocialSecurityNumber(e.target.value);
    }
    render() {
        return (
            <>
                <Card title="What is your social security number?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="ssn">Social Security Number</label>
                                <input type="tel" autoComplete="off" placeholder="###-##-####" maxLength={11} required className="form-control" id="ssn" onChange={this.updateSocialSecurityNumber} value={this.props.socialSecurityNumber ? this.props.socialSecurityNumber : ""} />
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(SocialSecurityNumberComponent));