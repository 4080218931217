import ValidationState from "../../models/State/ValidationState";
import AppState from "../../models/State/AppState";
import selectedAmount from "./SelectedAmountReducer";
import demographicInformationReducer from "./DemographicInformationReducer";
import personalInformationReducer from "./PersonalInformationReducer";
import paymentReducer from "./PaymentReducer";

export default function (currState: AppState): ValidationState {
    return {
        selectedAmount: selectedAmount(currState),
        demographicInformation: demographicInformationReducer(currState),
        personalInformation: personalInformationReducer(currState),
        payment: paymentReducer(currState)
    }
}