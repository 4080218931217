import React, { Component } from 'react';

class CardBody extends Component {
    render() {
        const {
            props: {
                children,
            },
        } = this;

        return (
            <div className="card-body">
                {children}
            </div>
        );
    }
}

export default CardBody;