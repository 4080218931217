import React, { ChangeEvent } from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as EmailValidator from 'email-validator';

import { updateStateAction, sendUnavailableStateNotificationAction } from '../../redux/DemographicInformation/Actions';
import AppState from '../../models/State/AppState';
import { loadAvailableStates } from '../../redux/AvailableStates/Actions';
import AvailableState from '../../models/AvailableState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import Modal from 'react-bootstrap/Modal';
import { hideStateUnavailableModalAction } from '../../redux/CurrentPage/Actions';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        state: state.demographicInformation.state,
        availableStates: state.availableStates.availableStates,
        displayModal: state.currentPage.displayStateIsUnavailable === undefined ? false : state.currentPage.displayStateIsUnavailable
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateState: (value: string | undefined) => dispatch(updateStateAction(value)),
        loadAvailableStates: () => dispatch(loadAvailableStates()),
        hideUnavailableModal: () => dispatch(hideStateUnavailableModalAction()),
        sendUnavailableNotification: (firstName: string, lastName: string, email: string, state?: string) => dispatch(sendUnavailableStateNotificationAction(firstName, lastName, email, state)),
    };
};

interface StateComponentProps {
    state?: string;
    availableStates?: AvailableState[];
    displayModal: boolean;
    updateState: (state: string | undefined) => void;
    loadAvailableStates: () => void;
    hideUnavailableModal: () => void;
    sendUnavailableNotification: (firstName: string, lastName: string, email: string, state?: string) => void;
}

interface StateComponentState {
    modalFirstName: string;
    modalLastName: string;
    modalEmail: string;
}

export class StateComponent extends React.Component<StateComponentProps, StateComponentState>{
    constructor(props: StateComponentProps) {
        super(props);

        this.state = {
            modalFirstName: "",
            modalLastName: "",
            modalEmail: ""
        }
    }
    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.props.loadAvailableStates();
    }
    private updateState = (e: ChangeEvent<HTMLSelectElement>) => {
        var selectedOption = e.target.selectedOptions && e.target.selectedOptions.length > 0 && e.target.selectedOptions[0].value.length > 0 ? e.target.selectedOptions[0].value : undefined;
        this.props.updateState(selectedOption);
    }
    private hideUnavailableModal = () => {
        this.props.hideUnavailableModal();
    }
    private sendUnavailableNotification = () => {
        this.props.sendUnavailableNotification(this.state.modalFirstName, this.state.modalLastName, this.state.modalEmail, this.props.state);
    }
    private setModalFirstName = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            modalFirstName: e.target.value
        });
    }
    private setModalLastName = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            modalLastName: e.target.value
        });
    }
    private setModalEmail = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            modalEmail: e.target.value
        });
    }
    render() {
        let modalCanContinue: boolean = this.state.modalFirstName.length > 0 && this.state.modalLastName.length > 0 && EmailValidator.validate(this.state.modalEmail);

        return (
            <>
                <Card title="Let's get to know you better!">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="usersFirstName">Where do you live?</label>
                                <select className="form-control" onChange={this.updateState} disabled={this.props.displayModal} autoComplete="address-level1" value={this.props.state}>
                                    <option value="">Choose...</option>
                                    {
                                        this.props.availableStates && this.props.availableStates.map(a => <option value={a.value} key={a.value}>{a.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
                <Modal show={this.props.displayModal} onHide={this.hideUnavailableModal}>
                    <Modal.Header closeButton><h2>We appreciate your time, but we are unable to offer you Critical Illness Insurance coverage at the moment.</h2></Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-12">
                                Please give us your contact information and we will let you know when it is available. If you would like more information, please contact us at <a href="mailto:hello@prepareinsure.com">hello@prepareinsure.com</a>.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="usersFirstName">First Name</label>
                                    <input type="text" autoComplete="given-name" required className="form-control" id="usersFirstName" onChange={this.setModalFirstName} value={this.state.modalFirstName} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="usersLastName">Last Name</label>
                                    <input type="text" autoComplete="family-name" required className="form-control" id="usersLastName" onChange={this.setModalLastName} value={this.state.modalLastName} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="email" autoComplete="email" required className="form-control" id="email" onChange={this.setModalEmail} value={this.state.modalEmail} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={this.sendUnavailableNotification} disabled={!modalCanContinue}>Continue</button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(StateComponent));