import Gender from "../../models/Gender";
import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import AppState from "../../models/State/AppState";
import { sendNotAvailableInStateEmail } from "../../accessors/NotificationAccessor";

// Action Types
export const UPDATE_DATEOFBIRTH = 'demographic_information/update_dateofbirth';
export const UPDATE_GENDER = 'demographic_information/update_gender';
export const UPDATE_USENICOTINEPRODUCTS = 'demographic_information/update_usenicotineproducts';
export const UPDATE_STATE = 'demographic_information/update_state';
export const UPDATE_REFERRER = 'demographic_information/update_referrer';
export const UPDATE_PARTNERSUBDOMAIN = 'demographic_information/update_partnerSubdomain';
export const UPDATE_EMAIL = 'demographic_information/update_email';

export type Types = typeof UPDATE_DATEOFBIRTH |
    typeof UPDATE_GENDER |
    typeof UPDATE_USENICOTINEPRODUCTS |
    typeof UPDATE_STATE |
    typeof UPDATE_REFERRER | 
    typeof UPDATE_EMAIL | 
    typeof UPDATE_PARTNERSUBDOMAIN;

export interface UpdateDateOfBirthPayload {
    dateOfBirth?: Date;
}

export interface UpdateGenderPayload {
    gender?: Gender;
}

export interface UpdateUseNicotineProductsPayload {
    useNicotineProducts?: boolean;
}

export interface UpdateStatePayload {
    state?: string;
}

export interface UpdateEmailPayload {
    email?: string;
}

export interface UpdatePartnerSubdomainPayload {
    partnerSubdomain?: string;
}

export interface UpdateReferrerPayload {
    referrer: string;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export function updateDateOfBirthAction(dateOfBirth?: Date): TypePayload<typeof UPDATE_DATEOFBIRTH, UpdateDateOfBirthPayload> {
    return { type: UPDATE_DATEOFBIRTH, payload: { dateOfBirth: dateOfBirth } };
}

export function updateGenderAction(gender?: Gender): TypePayload<typeof UPDATE_GENDER, UpdateGenderPayload> {
    return { type: UPDATE_GENDER, payload: { gender: gender } };
}

export function updateUseNicotineProductsAction(useNicotineProducts?: boolean): TypePayload<typeof UPDATE_USENICOTINEPRODUCTS, UpdateUseNicotineProductsPayload> {
    return { type: UPDATE_USENICOTINEPRODUCTS, payload: { useNicotineProducts: useNicotineProducts } };
}

export function updateStateAction(state?: string): TypePayload<typeof UPDATE_STATE, UpdateStatePayload> {
    return { type: UPDATE_STATE, payload: { state: state } };
}

export function updateReferrerAction(referrer: string): TypePayload<typeof UPDATE_REFERRER, UpdateReferrerPayload> {
    return { type: UPDATE_REFERRER, payload: { referrer: referrer } };
}

export function updatePartnerSubdomainAction(partnerSubdomain: string): TypePayload<typeof UPDATE_PARTNERSUBDOMAIN, UpdatePartnerSubdomainPayload> {
    return { type: UPDATE_PARTNERSUBDOMAIN, payload: { partnerSubdomain: partnerSubdomain } };
}

export function updateEmailAction(email: string | undefined): TypePayload<typeof UPDATE_EMAIL, UpdateEmailPayload> {
    return { type: UPDATE_EMAIL, payload: { email: email } };
}

export const sendUnavailableStateNotificationAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (firstName: string, lastName: string, email: string, state?: string) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {

        await sendNotAvailableInStateEmail(firstName, lastName, email, state);

        var currState = getState();
        if (currState.brand.links !== undefined) {
            window.location.replace(currState.brand.links["homepage"]);
        } else {
            window.location.replace("/");
        }
    };
}