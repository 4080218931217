import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { nextPageAction, previousPageAction } from '../../redux/CurrentPage/Actions';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';

import bars from '../Loading/bars.svg';

const mapStateToProps = (state: AppState) => {
    return {
        canGoBack: state.currentPage.canGoBack,
        canContinue: state.currentPage.canContinue,
        showProcessing: state.interviewQuestions.isFetchingFollowUps
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        goBack: () => dispatch(previousPageAction()),
        continue: () => dispatch(nextPageAction())
    };
};

interface NavigationButtonsComponentProps {
    canGoBack: boolean;
    canContinue: boolean;
    showProcessing: boolean;
    continue: () => void;
    goBack: () => void;
}

export class NavigationButtonsComponent extends React.Component<NavigationButtonsComponentProps>{
    private continue = () => {
        this.props.continue();
    }

    private goBack = () => {
        this.props.goBack();
    }
    render() {
        return (
            
                <div className="row">
                    <div className="col-12">
                        <div className="app-button-nav flex-row-reverse">
                            <button className="btn btn-primary" onClick={this.continue} disabled={!this.props.canContinue}>
                                {this.props.showProcessing && <img alt="Processing" className="mr-2" src={bars} />}
                                Continue
                                {this.props.showProcessing && <div className="ml-2 processing-spacer-div" />}
                            </button>
                            <button className="btn btn-primary mr-1" onClick={this.goBack} disabled={!this.props.canGoBack}>&lt;</button>
                        </div>
                    </div>
                    <div className="col-9">
                        
                    </div>
                </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationButtonsComponent);