import AppState from "../../models/State/AppState";
import { Question, QuestionType, BaseQuestion } from "../../models/Question";
import InterviewQuestionsState from "../../models/State/InterviewQuestionsState";

type switchQuestionResult = {
    hasQuestionsLoaded: false
} | {
    hasQuestionsLoaded: true,
    movedPast: true
} | {
    hasQuestionsLoaded: true,
    movedPast: false,
    question: Question,
    questionIndex: number
}
export const getNextQuestion = (state: AppState): switchQuestionResult => {
    if (state.interviewQuestions.questions === undefined) {
        return { hasQuestionsLoaded: false };
    }
    let currentQuestionIndex = state.currentPage.currentQuestionIndex;
    if (currentQuestionIndex === undefined) {
        currentQuestionIndex = 0;
    } else {
        currentQuestionIndex++;
    }
    if (state.interviewQuestions.questions.length <= currentQuestionIndex) {
        return { hasQuestionsLoaded: true, movedPast: true };
    }
    return { hasQuestionsLoaded: true, movedPast: false, question: state.interviewQuestions.questions[currentQuestionIndex], questionIndex: currentQuestionIndex };
}

export const getPreviousQuestion = (state: AppState): switchQuestionResult => {
    if (state.interviewQuestions.questions === undefined) {
        return { hasQuestionsLoaded: false };
    }
    let currentQuestionIndex = state.currentPage.currentQuestionIndex;
    if (currentQuestionIndex === undefined) {
        currentQuestionIndex = state.interviewQuestions.questions.length - 1;
    } else {
        currentQuestionIndex--;
    }
    if (currentQuestionIndex < 0) {
        return { hasQuestionsLoaded: true, movedPast: true };
    }
    return { hasQuestionsLoaded: true, movedPast: false, question: state.interviewQuestions.questions[currentQuestionIndex], questionIndex: currentQuestionIndex };
}

export const getCurrentQuestion = (state: AppState): BaseQuestion | undefined => {
    if (state.interviewQuestions.questions === undefined || state.currentPage.currentQuestionIndex === undefined || state.interviewQuestions.questions.length <= state.currentPage.currentQuestionIndex || state.currentPage.currentQuestionIndex < 0) {
        return undefined;
    }
    return state.interviewQuestions.questions[state.currentPage.currentQuestionIndex];
}

export const areQuestionAnswersSaving = (state: InterviewQuestionsState): boolean => {
    if (state.questions === undefined) {
        return false;
    }
    return state.questions.some(isQuestionAnswerSaving);
}

export const areQuestionsFetchingFollowUps = (state: InterviewQuestionsState): boolean => {
    if (state.questions === undefined) {
        return false;
    }
    return state.questions.some(isFetchingFollowUpQuestions);
}

export const isQuestionAnswerSaving = (question: BaseQuestion): boolean => {
    if (question.hasSavedAnswer !== undefined && !question.hasSavedAnswer) {
        return true;
    }

    return false;
}

export const isCurrentQuestionComplete = (state: AppState): boolean | undefined => {
    if (state.interviewQuestions.questions === undefined || state.currentPage.currentQuestionIndex === undefined || state.currentPage.currentQuestionIndex < 0 || state.currentPage.currentQuestionIndex >= state.interviewQuestions.questions.length) {
        return undefined;
    }
    let currQuestion = state.interviewQuestions.questions[state.currentPage.currentQuestionIndex];
    return isQuestionComplete(currQuestion);
}

export const isFetchingFollowUpQuestions = (question: Question): boolean => {
    if (question.answer !== undefined && !question.followUpQuestionRetrieved) {
        return true;
    }

    if (question.subQuestions !== undefined && question.subQuestions.some(a => isFetchingFollowUpQuestions(a))) {
        return true;
    }

    if (question.followUpQuestion !== undefined && isFetchingFollowUpQuestions(question.followUpQuestion)) {
        return true;
    }

    if (question.pickList !== undefined && question.pickList.length > 0) {
        if(question.pickList.some(a => a.followUpQuestion !== undefined && isFetchingFollowUpQuestions(a.followUpQuestion))){
            return true;
        }
        if(question.pickList.some(a => a.answer === "true" && !a.followUpQuestionRetrieved)){
            return true;
        }
    }

    return false;
}

export const isQuestionComplete = (question: Question): boolean => {
    if(isFetchingFollowUpQuestions(question)){
        return false;
    }
    if (question.type !== QuestionType.PrimaryQuestion && question.answer === undefined) {
        return false;
    }

    if(question.pickList !== undefined && question.pickList.length > 0){
        if(question.pickList.every(a=>a.answer === "false" || a.answer === undefined)){
            return false;
        }
        if(question.pickList.some(a=>a.followUpQuestion !== undefined && !isQuestionComplete(a.followUpQuestion))){
            return false;
        }
    }

    if (question.subQuestions !== undefined && !question.subQuestions.every(a => isQuestionComplete(a))) {
        return false;
    }

    if (question.followUpQuestion !== undefined && !isQuestionComplete(question.followUpQuestion)) {
        return false;
    }

    return true;
}
