import React from 'react';
import { connect } from "react-redux";
import { updateUseNicotineProductsAction } from '../../redux/DemographicInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        usesNicotineProducts: state.demographicInformation.useNicotineProducts
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateUsesNicotineProducts: (value: boolean | undefined) => dispatch(updateUseNicotineProductsAction(value))
    };
};

interface UsesNicotineProductComponentProps {
    usesNicotineProducts?: boolean;
    updateUsesNicotineProducts: (usesNicotineProducts: boolean) => void;
}

export class UsesNicotineProductComponent extends React.Component<UsesNicotineProductComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private setUsesNicotineProducts = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.props.updateUsesNicotineProducts(true);
        }
    }
    private setDoesntUseNicotineProducts = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.props.updateUsesNicotineProducts(false);
        }
    }
    render() {
        const baseButtonClassName = 'btn btn-outline-primary';
        const yesClassName = baseButtonClassName + (this.props.usesNicotineProducts ? ' active' : '');
        const noClassName = baseButtonClassName + (this.props.usesNicotineProducts === false ? ' active' : '');
        return (
            <>
                <Card title="Let's get to know you better!">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div>
                                    <label>Have you used nicotine in the past year?</label>
                                    <label>(Including any form of tobacco or substitutes such as patches or gum)</label>
                                </div>
                                <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label className={yesClassName}>
                                        <input type="radio" name="options" id="option1" autoComplete="off" onChange={this.setUsesNicotineProducts} checked={this.props.usesNicotineProducts === true} /> Yes
                                </label>
                                    <label className={noClassName}>
                                        <input type="radio" name="options" id="option2" autoComplete="off" onChange={this.setDoesntUseNicotineProducts} checked={this.props.usesNicotineProducts === false} /> No
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(UsesNicotineProductComponent));