import React from 'react';
import { connect } from "react-redux";
import { updateStreetAddressAction, updateCityAction, updateZipAction, updateStreetAddress2Action } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        streetAddress: state.personalInformation.streetAddress,
        streetAddress2: state.personalInformation.streetAddress2,
        city: state.personalInformation.city,
        state: state.demographicInformation.state,
        zip: state.personalInformation.zip
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateStreetAddress: (value: string | undefined) => dispatch(updateStreetAddressAction(value)),
        updateStreetAddress2: (value: string | undefined) => dispatch(updateStreetAddress2Action(value)),
        updateCity: (value: string | undefined) => dispatch(updateCityAction(value)),
        updateZip: (value: string | undefined) => dispatch(updateZipAction(value))
    };
};

interface UserIdentificationComponentProps {
    streetAddress?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    zip?: string;
    updateStreetAddress: (value: string) => void;
    updateStreetAddress2: (value: string) => void;
    updateCity: (value: string) => void;
    updateZip: (value: string) => void;
}

export class UserIdentificationComponent extends React.Component<UserIdentificationComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private updateStreetAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateStreetAddress(e.target.value);
    }
    private updateStreetAddress2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateStreetAddress2(e.target.value);
    }
    private updateCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateCity(e.target.value);
    }
    private updateZip = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateZip(e.target.value);
    }
    render() {
        return (
            <>
                <Card title="Where do you live?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="streetAddress1">Street Address</label>
                                <input type="text" autoComplete="address-line1" required className="form-control" id="streetAddress1" onChange={this.updateStreetAddress} value={this.props.streetAddress ? this.props.streetAddress : ""} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="streetAddress2">Apartment/Suite #</label>
                                <input type="text" autoComplete="address-line2" required className="form-control" id="streetAddress2" onChange={this.updateStreetAddress2} value={this.props.streetAddress2 ? this.props.streetAddress2 : ""} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input type="text" autoComplete="address-level2" required className="form-control" id="city" onChange={this.updateCity} value={this.props.city ? this.props.city : ""} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <input type="state" autoComplete="address-level1" disabled className="form-control" id="state" value={this.props.state ? this.props.state : ""} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label htmlFor="zip">Zip Code</label>
                                <input type="zip" autoComplete="postal-code" required className="form-control" id="zip" maxLength={5} onChange={this.updateZip} value={this.props.zip ? this.props.zip : ""} />
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(UserIdentificationComponent));