import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import DateSelector from '../../components/DateSelector/DateSelector';
import NicotineType from '../../models/NicotineType';

import {
    updateTobaccoUsesAction,
    updateTobaccoTypeAction,
    updateTobaccoDailyUseCountAction,
    updateTobaccoMonthlyUseCountAction,
    updateTobaccoLastUseDateAction
} from '../../redux/PersonalInformation/Actions';

const mapStateToProps = (state: AppState) => {
    return {
        tobaccoDemographic: state.demographicInformation.useNicotineProducts,
        usesTobacco: state.personalInformation.tobaccoUses,
        tobaccoType: state.personalInformation.tobaccoType,
        tobaccoDailyUseCount: state.personalInformation.tobaccoDailyUseCount,
        tobaccoMonthlyUseCount: state.personalInformation.tobaccoMonthlyUseCount,
        tobaccoLastUseDate: state.personalInformation.tobaccoLastUseDate,
        tobaccoLastUseDateValidationMessage: state.validation.personalInformation.fields.tobaccoLastUseDateValidation
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateTobaccoUses: (value: boolean) => dispatch(updateTobaccoUsesAction(value)),
        updateTobaccoType: (value: NicotineType | undefined) => dispatch(updateTobaccoTypeAction(value)),
        updateTobaccoDailyUseCount: (value: number) => dispatch(updateTobaccoDailyUseCountAction(value)),
        updateTobaccoMonthlyUseCount: (value: number) => dispatch(updateTobaccoMonthlyUseCountAction(value)),
        updateTobaccoLastUseDate: (value: Date) => dispatch(updateTobaccoLastUseDateAction(value))
    };
};

interface NicotineDetailsComponentProps {
    tobaccoDemographic?: boolean,
    usesTobacco?: boolean,
    tobaccoType?: NicotineType,
    tobaccoDailyUseCount?: number,
    tobaccoMonthlyUseCount?: number,
    tobaccoLastUseDate?: Date,
    tobaccoLastUseDateValidationMessage?: string,
    updateTobaccoUses: (value: boolean) => void,
    updateTobaccoType: (value: NicotineType | undefined) => void,
    updateTobaccoDailyUseCount: (value: number) => void,
    updateTobaccoMonthlyUseCount: (value: number) => void,
    updateTobaccoLastUseDate: (value: Date) => void
}

export class NicotineDetailsComponent extends React.Component<NicotineDetailsComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private setTobaccoUsesTrue = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.props.updateTobaccoUses(true);
        }
    }
    private setTobaccoUsesFalse = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            this.props.updateTobaccoUses(false);
        }
    }
    private updateTobaccoType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let stringVal = e.target.value as keyof typeof NicotineType;
        let val = stringVal && stringVal.length > 0 ? NicotineType[stringVal] : undefined;
        this.props.updateTobaccoType(val);
    }
    private updateFrequency = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.tobaccoType === NicotineType.Cigarettes || this.props.tobaccoType === NicotineType.Ecigarettes) {
            this.props.updateTobaccoDailyUseCount(parseInt(e.target.value));
            this.props.updateTobaccoMonthlyUseCount(0);
        } else if (this.props.tobaccoType === NicotineType.Pipe || this.props.tobaccoType === NicotineType.Cigar) {
            this.props.updateTobaccoDailyUseCount(0);
            this.props.updateTobaccoMonthlyUseCount(parseInt(e.target.value));
        }
    }
    private updateLastUse = (date: Date) => {
        this.props.updateTobaccoLastUseDate(date);
    }
    componentWillMount(){
        if(this.props.tobaccoDemographic){
            this.props.updateTobaccoUses(true);
        }
    }
    
    render() {
        const baseButtonClassName = 'btn btn-outline-primary';
        const trueClassName = baseButtonClassName + (this.props.usesTobacco === true ? ' active' : '');
        const falseClassName = baseButtonClassName + (this.props.usesTobacco === false ? ' active' : '');
        
        let frequencyType = ""
        if(this.props.tobaccoType === NicotineType.Cigarettes || this.props.tobaccoType === NicotineType.Ecigarettes){
            frequencyType = "Day"
        } else if (this.props.tobaccoType === NicotineType.Pipe || this.props.tobaccoType === NicotineType.Cigar){
            frequencyType = "Month"
        }

        
        let tobaccoTypeValue = this.props.tobaccoType === undefined ? "" : NicotineType[this.props.tobaccoType];

        return (
            <>
                <Card title="Do you use any nicotine products?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div>
                                    <label>Have you ever used any form of tobacco or nicotine-based products, or substitutes such as patches or gum?</label>
                                </div>
                                <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label className={trueClassName}>
                                        <input type="radio" name="options" id="option1" autoComplete="off" onChange={this.setTobaccoUsesTrue} checked={this.props.usesTobacco === true} /> Yes
                                    </label>
                                    <label className={falseClassName}>
                                        <input type="radio" name="options" id="option2" autoComplete="off" onChange={this.setTobaccoUsesFalse} checked={this.props.usesTobacco === false} /> No
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.usesTobacco &&
                        <>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Tobacco Type</label>
                                    <select className="form-control" onChange={this.updateTobaccoType} autoComplete="address-level1" value={tobaccoTypeValue}>
                                        <option value="">Choose...</option>
                                        <option value={NicotineType[NicotineType.Cigarettes]} key={NicotineType.Cigarettes}>Cigarettes</option>
                                        <option value={NicotineType[NicotineType.Cigar]} key={NicotineType.Cigar}>Cigar</option>
                                        <option value={NicotineType[NicotineType.Pipe]} key={NicotineType.Pipe}>Pipe</option>
                                        <option value={NicotineType[NicotineType.Smokeless]} key={NicotineType.Smokeless}>Smokeless</option>
                                        <option value={NicotineType[NicotineType.Ecigarettes]} key={NicotineType.Ecigarettes}>E-Cigarettes/Vapor Cigarettes</option>
                                        <option value={NicotineType[NicotineType.Nicotine_Patches_Gum_Other]} key={NicotineType.Nicotine_Patches_Gum_Other}>Nicotine Patches/Gum</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                    { frequencyType !== "" && this.props.usesTobacco &&
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Amount Per {frequencyType}</label>
                                        <input type="tel" maxLength={2} required className="form-control" onChange={this.updateFrequency} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {this.props.usesTobacco &&
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <DateSelector label={"Last Date of Use"} dateValidation={this.props.tobaccoLastUseDateValidationMessage} dateCallback={this.updateLastUse} defaultValue={this.props.tobaccoLastUseDate}/>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(NicotineDetailsComponent));