import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import Card from '../../components/Card/Card';
import { Question } from '../../models/Question';
import AppState from '../../models/State/AppState';
import CurrentPage from '../../models/CurrentPage';
import QuestionComponent from './Question';
import { loadQuestionsAction } from '../../redux/InterviewQuestions/Actions';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';
import PageTransition from '../../components/PageTransition';

const mapStateToProps = (state: AppState) => {
  let currentQuestion: Question | undefined = undefined;
  if (state.currentPage.currentPage === CurrentPage.InterviewQuestions && state.interviewQuestions.questions !== undefined && state.currentPage.currentQuestionIndex !== undefined) {
    currentQuestion = state.interviewQuestions.questions[state.currentPage.currentQuestionIndex];
  }
  return {
    currentQuestion: currentQuestion,
    questions: state.interviewQuestions.questions
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    loadQuestions: () => dispatch(loadQuestionsAction())
  }
};

interface QuestionsComponentProps {
  questions?: Question[];
  loadQuestions: () => void;
  currentQuestion?: Question;
}

export class QuestionsComponent extends React.Component<QuestionsComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.loadQuestions();
  }
  render() {
    if (this.props.questions === undefined || this.props.currentQuestion === undefined) {
      return null;
    }
    const currentQuestion = this.props.currentQuestion;
    return (
      <>
        {
          this.props.questions.map(a => {
            return <PageTransition key={a.id} in={a.id === currentQuestion.id} includeDisclosure={true}>
              <Card title={a.text}>
                <QuestionComponent question={a} />
              </Card>
              <NavigationButtons />
            </PageTransition>
          })
        }

      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(QuestionsComponent));