import AppState from "../../models/State/AppState";
import { ActionCreator, AnyAction } from "redux";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { getAvailableStates } from "../../accessors/QuoteAccessor";
import AvailableState from "../../models/AvailableState";

// Action Types
export const FETCH_AVAILABLESTATES = 'available_states/fetch';
export const RETRIEVED_AVAILABLESTATES = 'available_states/retrieved';

export type Types = typeof FETCH_AVAILABLESTATES |
                    typeof RETRIEVED_AVAILABLESTATES;

export interface FetchAvailableStatesPayload{
}

export interface RetrievedAvailableStatesPayload{
    availableStates: AvailableState[];
}

type TypePayload<T1 extends Types, T2> = {type: T1, payload: T2};

export const loadAvailableStates: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: ()=>AppState): Promise<any> => {
        const currState = getState();
        if(currState.availableStates.availableStates === undefined && !currState.availableStates.isLoadingAvailableStates){
            dispatch(fetchAvailableStatesAction());
            const states = await getAvailableStates();
            return dispatch(retrievedAvailableStatesAction(states));
        }
    };
}

export function fetchAvailableStatesAction(): TypePayload<typeof FETCH_AVAILABLESTATES, FetchAvailableStatesPayload> {
    return {type: FETCH_AVAILABLESTATES, payload: {}};
}

export function retrievedAvailableStatesAction(availableStates: AvailableState[]): TypePayload<typeof RETRIEVED_AVAILABLESTATES, RetrievedAvailableStatesPayload> {
    return {type: RETRIEVED_AVAILABLESTATES, payload: {availableStates: availableStates}};
}