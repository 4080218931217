import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import BirthLocationsState from "../../models/State/BirthLocationsState";

type Action = ReturnType<typeof Actions.fetchAvailableCountriesAction> |
              ReturnType<typeof Actions.retrievedAvailableCountriesAction>;

export function fetchAvailableCountriesReducer(state: BirthLocationsState, action: Action): BirthLocationsState {
    if (action.type !== Actions.FETCH_AVAILABLECOUNTRIES) {
        return state;
    }
    
    return { ...state, isLoadingAvailableCountries: true };
}

export function retrievedAvailableCountriesReducer(state: BirthLocationsState, action: Action): BirthLocationsState {
    if (action.type !== Actions.RETRIEVED_AVAILABLECOUNTRIES) {
        return state;
    }
    
    return { ...state, availableCountries: action.payload.availableCountries, isLoadingAvailableCountries: false };
}


export default createReducer<BirthLocationsState, Actions.Types, Action>({isLoadingAvailableCountries: false}, {
    [Actions.FETCH_AVAILABLECOUNTRIES]: fetchAvailableCountriesReducer,
    [Actions.RETRIEVED_AVAILABLECOUNTRIES]: retrievedAvailableCountriesReducer
});