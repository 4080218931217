import CurrentPage from "../CurrentPage";
import PageOrderData from "../PageOrderData";

export enum LastPageMovement{
    Forward,
    Backward
}

export default interface CurrentPageState {
    currentPage?: CurrentPage;
    previousPages?: CurrentPage[];
    pageOrder?: PageOrderData[];
    isLoadingPageOrderData: boolean;
    canGoBack:boolean;
    canContinue:boolean;
    progress?: number;
    displayStateIsUnavailable?: boolean;
    currentQuestionIndex?: number;
    lastPageMovement?:LastPageMovement;
}