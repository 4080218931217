import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from './App';
import { initializeApplicationInsights, initializeGoogleAnalytics } from './TrackingHelpers';

import './index.scss';
import { getBrandName } from './UriUtilities';
import { getSettings } from './Settings';

initializeApplicationInsights();
initializeGoogleAnalytics(store);

var brandName = getBrandName();
if (brandName !== undefined) {
  let settings = getSettings();
  const styleDataUrl: string = "/api/branding/styledata.css";
  var url = new URL(styleDataUrl, settings.apiHost);
  url.searchParams.append("brandName", brandName);
  url.searchParams.append("code", settings.apiKey);

  var styleSheetLink = document.createElement('link');
  styleSheetLink.id = "brandCss";
  styleSheetLink.rel = "stylesheet";
  styleSheetLink.type = "text/css";
  styleSheetLink.media = "all";
  styleSheetLink.href = url.href;
  document.head.appendChild(styleSheetLink);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



