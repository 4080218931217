import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import AvailableState from '../../models/AvailableState';
import AvailableCountry from '../../models/AvailableCountry';
import { updateBirthCountryAction, updateBirthStateAction } from '../../redux/PersonalInformation/Actions';
import { loadAvailableCountries } from '../../redux/BirthLocation/Actions';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    let matchingBirthCountry = state.birthLocation.availableCountries ? state.birthLocation.availableCountries.find(a => a.value === state.personalInformation.birthCountry) : undefined;
    return {
        availableCountries: state.birthLocation.availableCountries,
        availableStates: matchingBirthCountry ? matchingBirthCountry.states : [],
        selectedCountry: state.personalInformation.birthCountry,
        selectedState: state.personalInformation.birthState
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        loadAvailableCountries: () => dispatch(loadAvailableCountries()),
        updateBirthCountry: (country: string) => dispatch(updateBirthCountryAction(country)),
        updateBirthState: (state?: string) => dispatch(updateBirthStateAction(state))
    };
};

interface CitizenshipComponentProps {
    availableCountries?: AvailableCountry[];
    availableStates?: AvailableState[];
    selectedCountry?: string;
    selectedState?: string;
    loadAvailableCountries: () => void;
    updateBirthCountry: (country: string) => void;
    updateBirthState: (state?: string) => void;
}

export class BirthLocationComponent extends React.Component<CitizenshipComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.loadAvailableCountries();
    }
    private updateBirthCountry = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.updateBirthCountry(e.target.value);
    }
    private updateBirthState = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.updateBirthState(e.target.value);
    }
    componentDidUpdate() {
        if (this.props.selectedState !== undefined && this.props.selectedState.length > 0) {
            if (this.props.availableStates && this.props.availableStates.length > 0) {
                if (!this.props.availableStates.some(a => a.value === this.props.selectedState)) {
                    this.props.updateBirthState(undefined);
                }
            } else {
                this.props.updateBirthState(undefined);
            }
        }
    }
    render() {
        return (
            <>
                <Card title="Birth Location?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="usersFirstName">Where were you born?</label>
                                <select className="form-control" onChange={this.updateBirthCountry} autoComplete="country-name" value={this.props.selectedCountry}>
                                    <option value="">Choose...</option>
                                    {
                                        this.props.availableCountries && this.props.availableCountries.map(a => <option value={a.value} key={a.value}>{a.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                    {this.props.availableStates && this.props.availableStates.length > 0 &&
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <select className="form-control" onChange={this.updateBirthState} autoComplete="address-level1" value={this.props.selectedState}>
                                        <option value="">Choose...</option>
                                        {
                                            this.props.availableStates && this.props.availableStates.map(a => <option value={a.value} key={a.value}>{a.name}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(BirthLocationComponent));