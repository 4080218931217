import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import moment from 'moment';

import AppState from "../../models/State/AppState";
import PageOrderData from "../../models/PageOrderData";
import { getPageOrder } from "../../accessors/BrandingAccessor";
import Gender from "../../models/Gender";
import { getGender, getDateOfBirth, getUseNicotineProducts, getReferrer as getReferrerFromUri, getState as getStateFromUri, getEmail as getEmailFromUri, getPartnerSubdomain as getPartnerSubdomainFromUri } from "../../UriUtilities";
import DemographicInformationState from "../../models/State/DemographicInformationState";
import demographicInformationReducer from "../Validation/DemographicInformationReducer";
import CurrentPage from "../../models/CurrentPage";
import { loadAvailableStates } from "../AvailableStates/Actions";
import { updateGenderAction, updateUseNicotineProductsAction, updateStateAction, updateDateOfBirthAction, updateReferrerAction, updateEmailAction, updatePartnerSubdomainAction } from "../DemographicInformation/Actions";
import { saveQuestionAnswerAction } from "../InterviewQuestions/Actions";
import { getCurrentQuestion } from "../InterviewQuestions/Utilities";

export const NEXT = 'current_page/next';
export const PREVIOUS = 'current_page/previous';
export const DETAILS = 'current_page/details';
export const FULL_DETAILS = 'current_page/fulldetails';
export const FULL_TERMS = 'current_page/fullterms';
export const HIDE_STATEUNAVAILABLEMODAL = 'current_page/hide_stateunavailablemodal';
export const APPROVED = 'current_page/approved';
export const DECLINED = 'current_page/declined';
export const FETCH_PAGEORDER = 'current_page/fetch_pageorder';
export const RECEIVED_PAGEORDER = 'current_page/received_pageorder';
export const UPDATE_CURRENTQUESTION = 'current_page/update_currentquestion';

type Types = typeof NEXT |
    typeof PREVIOUS |
    typeof DETAILS |
    typeof FULL_DETAILS |
    typeof FULL_TERMS |
    typeof HIDE_STATEUNAVAILABLEMODAL |
    typeof APPROVED |
    typeof DECLINED |
    typeof FETCH_PAGEORDER |
    typeof RECEIVED_PAGEORDER |
    typeof UPDATE_CURRENTQUESTION;

export interface NextPagePayload {
}

export interface PreviousPagePayload {
}

export interface DetailsPagePayload {
}

export interface FullDetailsPagePayload {
}

export interface FullTermsPagePayload {
}

export interface HideStateUnavailablePayload {
}

export interface ApprovedPagePayload {
}

export interface DeclinedPagePayload {
}

export interface FetchPageOrderPayload {
}

export interface ReceivedPageOrderPayload {
    pageOrder?: PageOrderData[];
    dateOfBirth?: string;
    gender?: string;
    useTobacco?: string;
    state?: string;
}

export interface UpdateCurrentQuestionPayload {
    index: number;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export function moveNextPageAction(): TypePayload<typeof NEXT, NextPagePayload> {
    return { type: NEXT, payload: {} };
}

export function previousPageAction(): TypePayload<typeof PREVIOUS, PreviousPagePayload> {
    return { type: PREVIOUS, payload: {} };
}

export function detailsPageAction(): TypePayload<typeof DETAILS, DetailsPagePayload> {
    return { type: DETAILS, payload: {} };
}

export function fullDetailsPageAction(): TypePayload<typeof FULL_DETAILS, FullDetailsPagePayload> {
    return { type: FULL_DETAILS, payload: {} };
}

export function fullTermsPageAction(): TypePayload<typeof FULL_TERMS, FullTermsPagePayload> {
    return { type: FULL_TERMS, payload: {} };
}

export function approvedPageAction(): TypePayload<typeof APPROVED, ApprovedPagePayload> {
    return { type: APPROVED, payload: {} };
}

export function declinedPageAction(): TypePayload<typeof DECLINED, DeclinedPagePayload> {
    return { type: DECLINED, payload: {} };
}

export function hideStateUnavailableModalAction(): TypePayload<typeof HIDE_STATEUNAVAILABLEMODAL, HideStateUnavailablePayload> {
    return { type: HIDE_STATEUNAVAILABLEMODAL, payload: {} };
}

export function updateCurrentQuestionAction(index: number): TypePayload<typeof UPDATE_CURRENTQUESTION, UpdateCurrentQuestionPayload> {
    return { type: UPDATE_CURRENTQUESTION, payload: { index: index } };
}

export const loadPageOrderAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (dateOfBirth: string | undefined, gender: string | undefined, useTobacco: string | undefined, state: string | undefined) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        let currState = getState();
        if (currState.currentPage.pageOrder === undefined && !currState.benefitAmount.isLoadingAvailableAmounts) {
            dispatch(fetchPageOrderAction());

            let pageOrder = await getPageOrder();
            if (pageOrder !== undefined) {
                let dob = parseDateOfBirth(getDateOfBirth());
                let gender = parseGender(getGender());
                let useNicotineProducts = parseUseNicotine(getUseNicotineProducts());
                let state = getStateFromUri();
                let email = getEmailFromUri();
                let referrer = getReferrerFromUri();
                let partnerSubdomain = getPartnerSubdomainFromUri();
                if (state !== undefined && state.length > 0) {
                    await dispatch(loadAvailableStates());
                    state = state.toUpperCase();
                    currState = getState();
                }

                let demographicInformation: DemographicInformationState = 
                { 
                    ...currState.demographicInformation, 
                    dateOfBirth: dob, 
                    gender: gender, 
                    useNicotineProducts: useNicotineProducts, 
                    referrer: referrer, 
                    state: state, 
                    email: email,
                    partnerSubdomain: partnerSubdomain
                };

                let appStateClone = { ...currState, demographicInformation: demographicInformation };
                let validationData = demographicInformationReducer(appStateClone);
                if (validationData.fields.isGenderValid) {
                    await dispatch(updateGenderAction(demographicInformation.gender));
                    pageOrder = pageOrder.filter(a => a.page !== CurrentPage.Gender);
                }
                if (validationData.fields.isNicotineProductsValid) {
                    await dispatch(updateUseNicotineProductsAction(demographicInformation.useNicotineProducts));
                    pageOrder = pageOrder.filter(a => a.page !== CurrentPage.NicotineProducts);
                }
                if (validationData.fields.isStateValid === true && validationData.fields.isStateAvailable) {
                    await dispatch(updateStateAction(demographicInformation.state));
                    pageOrder = pageOrder.filter(a => a.page !== CurrentPage.State);
                }
                if (validationData.fields.dateOfBirthValidation !== undefined && validationData.fields.dateOfBirthValidation.length === 0) {
                    await dispatch(updateDateOfBirthAction(demographicInformation.dateOfBirth));
                    pageOrder = pageOrder.filter(a => a.page !== CurrentPage.DateOfBirth);
                }
                await dispatch(updateReferrerAction(referrer));
                await dispatch(updateEmailAction(email));
                await dispatch(updatePartnerSubdomainAction(partnerSubdomain));
            }

            return dispatch(receivedPageOrderAction(pageOrder));
        }
    };
}

export const nextPageAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        let currState = getState();
        dispatch(saveQuestionAnswerAction(getCurrentQuestion(currState)));
        dispatch(moveNextPageAction());
    };
}

function parseDateOfBirth(dateOfBirth?: string): Date | undefined {
    let dob: Date | undefined;
    if (dateOfBirth !== undefined && dateOfBirth.length > 0) {
        let dobMoment = moment(dateOfBirth);
        if (dobMoment.isValid()) {
            dob = dobMoment.toDate();
        }
    }
    return dob;
}

function parseGender(gender?: string): Gender | undefined {
    if (gender === undefined || gender.length === 0) {
        return undefined;
    }
    if (gender.toLowerCase() === "f" || gender.toLowerCase() === "female") {
        return Gender.Female;
    }
    if (gender.toLowerCase() === "m" || gender.toLowerCase() === "male") {
        return Gender.Male;
    }
    return undefined;
}

function parseUseNicotine(useNicotineProducts?: string): boolean | undefined {
    if (useNicotineProducts === undefined || useNicotineProducts.length === 0) {
        return undefined;
    }
    if (useNicotineProducts.toLowerCase() === "t" || useNicotineProducts.toLowerCase() === "true" || useNicotineProducts.toLowerCase() === "1") {
        return true;
    }
    if (useNicotineProducts.toLowerCase() === "f" || useNicotineProducts.toLowerCase() === "false" || useNicotineProducts.toLowerCase() === "0") {
        return false;
    }
    return undefined;
}

export function fetchPageOrderAction(): TypePayload<typeof FETCH_PAGEORDER, FetchPageOrderPayload> {
    return { type: FETCH_PAGEORDER, payload: {} };
}

export function receivedPageOrderAction(pageOrder?: PageOrderData[], dateOfBirth?: string, gender?: string, useTobacco?: string, state?: string): TypePayload<typeof RECEIVED_PAGEORDER, ReceivedPageOrderPayload> {
    return {
        type: RECEIVED_PAGEORDER,
        payload: {
            pageOrder: pageOrder,
            dateOfBirth: dateOfBirth,
            gender: gender,
            useTobacco: useTobacco,
            state: state
        }
    };
}