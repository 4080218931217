export const processRequest = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
    let response: Response | undefined;
    try {
        response = await fetch(input, init);
    } catch (e) {
        window.location.href = "/Error.html";
        return undefined as any;
    }
    if (response === undefined || response.status < 200 || response.status >= 300) {
        window.location.href = "/Error.html";
        return undefined as any;
    }
    return response;
}