import React from 'react';
import { connect } from "react-redux";
import { updateIsCitizenAction } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        isCitizen: state.personalInformation.isCitizen
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateIsCitizen: (value?: boolean) => dispatch(updateIsCitizenAction(value))
    };
};

interface CitizenshipComponentProps {
    isCitizen?: boolean;
    updateIsCitizen: (value?: boolean) => void;
}

export class CitizenshipComponent extends React.Component<CitizenshipComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private setIsCitizen = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateIsCitizen(true);
    }
    private setIsNotCitizen = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateIsCitizen(false);
    }
    render() {
        const baseButtonClassName = 'btn btn-outline-primary';
        const yesClassName = baseButtonClassName + (this.props.isCitizen ? ' active' : '');
        const noClassName = baseButtonClassName + (this.props.isCitizen === false ? ' active' : '');
        return (
            <>
                <Card title="Are you a US Citizen?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label className={yesClassName}>
                                        <input type="radio" name="options" id="option1" autoComplete="off" onChange={this.setIsCitizen} checked={this.props.isCitizen === true} /> Yes
                                </label>
                                    <label className={noClassName}>
                                        <input type="radio" name="options" id="option2" autoComplete="off" onChange={this.setIsNotCitizen} checked={this.props.isCitizen === false} /> No
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(CitizenshipComponent));