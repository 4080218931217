import { getSettings } from "../Settings";
import AvailableState from "../models/AvailableState";
import AvailableBenefitAmount from "../models/AvailableBenefitAmount";
import Gender from "../models/Gender";
import moment from 'moment';
import AvailableCountry from "../models/AvailableCountry";
import { QuestionOption, FollowUpQuestion, FollowUpQuestionOption, BaseQuestion, Question } from "../models/Question";
import { processRequest } from "./AccessorUtilities";

const availableStatesUrl: string = "/api/quote/availablestates.json";
const availableBirthCountriesUrl: string = "/api/quote/availablebirthcountries.json";
const availableBenefitAmountsUrl: string = "/api/quote/availablebenefitamounts.json";
const getQuestionsUrl: string = "/api/quote/getquestions.json";
const getSearchOptionsUrl: string = "/api/quote/getsearchoptions.json";
const answerQuestionUrl: string = "/api/quote/answerquestion.json";
const getFollowUpQuestionUrl: string = "/api/quote/getfollowupquestion.json";

export async function getAvailableStates(): Promise<AvailableState[]> {
    let settings = getSettings();
    let url = new URL(availableStatesUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);
    let fetchResult = await processRequest(url.href);
    return await fetchResult.json() as AvailableState[];
}

export async function getBirthCountries(): Promise<AvailableCountry[]> {
    let settings = getSettings();
    let url = new URL(availableBirthCountriesUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);
    let fetchResult = await processRequest(url.href);
    return await fetchResult.json() as AvailableCountry[];
}

export async function getAvailableBenefitAmounts(gender: Gender, dateOfBirth: Date, usesNicotineProducts: boolean, state: string, referrer: string, email: string | undefined, partnerSubdomain: string | undefined): Promise<AvailableBenefitAmount[]> {
    let settings = getSettings();
    let url = new URL(availableBenefitAmountsUrl, settings.apiHost);
    url.searchParams.append("gender", gender === Gender.Female ? "f" : "m");
    url.searchParams.append("dateOfBirth", moment(dateOfBirth).format('YYYY-MM-DD'));
    url.searchParams.append("nicotine", usesNicotineProducts ? "1" : "0");
    url.searchParams.append("state", state);
    url.searchParams.append("code", settings.apiKey);
    url.searchParams.append("referrer", referrer);
    if(email !== undefined) {
        url.searchParams.append("email", email);
    }    
    if(partnerSubdomain !== undefined) {
        url.searchParams.append("partner_subdomain", partnerSubdomain);
    }
    let fetchResult = await processRequest(url.href);
    return await fetchResult.json() as AvailableBenefitAmount[];
}

export async function getQuestions(caseId: string): Promise<BaseQuestion[]> {
    let settings = getSettings();
    let url = new URL(getQuestionsUrl, settings.apiHost);
    url.searchParams.append("case", caseId);
    url.searchParams.append("code", settings.apiKey);
    let fetchResult = await processRequest(url.href);
    let results = await fetchResult.json() as BaseQuestion[];
    results.forEach(a => a.hasSavedAnswer = false);
    return results;
}

export async function getSearchOptions(caseId: string, category: string, value: string): Promise<QuestionOption[]> {
    let settings = getSettings();
    let url = new URL(getSearchOptionsUrl, settings.apiHost);
    url.searchParams.append("case", caseId);
    url.searchParams.append("category", category);
    url.searchParams.append("value", value);
    url.searchParams.append("code", settings.apiKey);
    let fetchResult = await processRequest(url.href);
    return await fetchResult.json() as QuestionOption[];
}

export async function answerQuestion(caseId: string, question: BaseQuestion): Promise<void> {
    let settings = getSettings();
    let url = new URL(answerQuestionUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);
    let body = {
        case: caseId,
        question: buildQuestionAnswerRequest(question)
    };
    await processRequest(url.href, {
        method: 'POST',
        body: JSON.stringify(body)
    });
}

const buildQuestionAnswerRequest = (question: Question | undefined): any => {
    if (question === undefined) {
        return undefined;
    }
    let selectedValue = question.options.find(a => a.value === question.answer);
    let followUpId = selectedValue === undefined ? undefined : selectedValue.followUpId;
    return {
        id: question.id,
        answer: question.answer,
        followUpId: followUpId,
        subQuestions: question.subQuestions === undefined ? undefined : question.subQuestions.map(buildQuestionAnswerRequest),
        followUpQuestion: buildQuestionAnswerRequest(question.followUpQuestion),
        pickList: question.pickList === undefined ? undefined : question.pickList.map(a => ({
            id: a.id,
            answer: a.answer,
            followUpQuestion: buildQuestionAnswerRequest(a.followUpQuestion)
        }))
    }
}

export type QuestionAnswer = {
    questionId: string;
    answer: string | undefined;
};

export async function getFollowUpQuestions(caseId: string, baseQuestionId: string, followUpId?: string | null): Promise<FollowUpQuestion | undefined> {
    if (followUpId === undefined || followUpId === null || followUpId.length === 0) {
        return undefined;
    }
    let settings = getSettings();
    let url = new URL(getFollowUpQuestionUrl, settings.apiHost);
    url.searchParams.append("case", caseId);
    url.searchParams.append("followUp", followUpId);
    url.searchParams.append("code", settings.apiKey);
    let fetchResult = await processRequest(url.href);
    let serverResult = await fetchResult.text();
    if (serverResult === undefined || serverResult === null || serverResult.length === 0) {
        return undefined;
    }
    return fixFollowUpQuestionFromServer(JSON.parse(serverResult), baseQuestionId);
}

function fixFollowUpQuestionFromServer(result: FollowUpQuestionFromServer | undefined | null, baseQuestionId: string): FollowUpQuestion | undefined {
    if (result === null || result === undefined) {
        return undefined;
    }

    let followUpQuestion = result.followUpQuestion;
    let questionWithoutFollowUp: FollowUpQuestionWithoutSubFollowUpQuestion;

    ({ followUpQuestion, ...questionWithoutFollowUp } = { ...result });

    let returnValue = { ...questionWithoutFollowUp, baseQuestionId: baseQuestionId, followUpQuestion: fixFollowUpQuestionFromServer(followUpQuestion, baseQuestionId), options: result.options.map(a => fixFollowUpOptionsFromServer(a, baseQuestionId)) };
    return returnValue;
}

function fixFollowUpOptionsFromServer(result: FollowUpOptionFromServer, baseQuestionId: string): FollowUpQuestionOption {
    let followUpQuestion = result.followUpQuestion;
    let questionWithoutFollowUp: FollowUpOptionWithoutFollowUpQuestion;

    ({ followUpQuestion, ...questionWithoutFollowUp } = { ...result });

    let returnValue = { ...questionWithoutFollowUp, followUpQuestion: fixFollowUpQuestionFromServer(followUpQuestion, baseQuestionId) };
    return returnValue;
}

type FollowUpQuestionWithoutSubFollowUpQuestion = Omit<Omit<Omit<FollowUpQuestion, 'followUpQuestion'>, 'options'>, 'baseQuestionId'>;
type FollowUpOptionWithoutFollowUpQuestion = Omit<FollowUpQuestionOption, 'followUpQuestion'>;

interface FollowUpQuestionFromServer extends FollowUpQuestionWithoutSubFollowUpQuestion {
    followUpQuestion?: FollowUpQuestionFromServer | null;
    options: FollowUpOptionFromServer[]
}

interface FollowUpOptionFromServer extends FollowUpOptionWithoutFollowUpQuestion {
    followUpQuestion?: FollowUpQuestionFromServer | null;
}