import { getSettings } from "../Settings";
import { processRequest } from "./AccessorUtilities";

const sendNotAvailableInStateEmailUrl: string = "api/notification/sendnotavailableemail.json";
const sendApplicationConfirmationEmailUrl: string = "api/notification/sendapplicationconfirmationemail.json";
const sendApplicationUnderwritingEmailUrl: string = "api/notification/sendapplicationunderwritingemail.json";

export async function sendNotAvailableInStateEmail(applicantFirstName: string, applicantLastName: string, applicantEmail: string, state?: string) {
    let settings = getSettings();
    var url = new URL(sendNotAvailableInStateEmailUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);

    let body = {
        applicantName: `${applicantFirstName} ${applicantLastName}`,
        applicantEmail: applicantEmail,
        state: state
    };

    await processRequest(url.href, {
        method: 'POST',
        body: JSON.stringify(body)
    });
}

export async function sendApplicationConfirmationEmail(applicantFirstName: string, applicantLastName: string, applicantEmail: string, referrer: string, policyId: string) {
    let settings = getSettings();
    var url = new URL(sendApplicationConfirmationEmailUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);

    let body = {
        applicantName: `${applicantFirstName} ${applicantLastName}`,
        applicantEmail: applicantEmail,
        referrer: referrer,
        policyId: policyId
    };

    await processRequest(url.href, {
        method: 'POST',
        body: JSON.stringify(body)
    });
}

export async function sendApplicationUnderwritingEmail(applicantFirstName: string, applicantLastName: string, applicantEmail: string, referrer: string, policyId: string) {
    let settings = getSettings();
    var url = new URL(sendApplicationUnderwritingEmailUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);

    let body = {
        applicantName: `${applicantFirstName} ${applicantLastName}`,
        applicantEmail: applicantEmail,
        referrer: referrer,
        policyId: policyId
    };

    await processRequest(url.href, {
        method: 'POST',
        body: JSON.stringify(body)
    });
}