import React from 'react';
import { connect } from "react-redux";
import { Component } from 'react';

import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

class FullDetails extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <Card title="Assurity Critical Illness Product Highlights">
                    <div className="row">
                        <div className="embed-responsive embed-responsive-1by1 full-screen-object">
                            <object className="embed-responsive-item" data="/Assurity_Critical_Illness_Product_Highlights.pdf" type="application/pdf" title="">
                                <p>Your browser isn't supporting embedded pdf files. You can download the file <a href="/Assurity_Critical_Illness_Product_Highlights.pdf" download>here</a>.</p>
                            </object>
                        </div>
                    </div>
                </Card>
                <NavigationButtons/>
            </>
        );
    }
}

export default connect()(setupApplicationInsightsPageTracking(FullDetails));