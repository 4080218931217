import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import PaymentFields from './PaymentFields';
import Card from '../../components/Card/Card';
import { getSettings } from '../../Settings';

export class PaymentsComponent extends React.Component{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    var stripeApiKey = getSettings().stripeApiKey;
    return (
      <>
        <Card title="Payment Information">
          <StripeProvider apiKey={stripeApiKey}>
            <Elements>
              <PaymentFields />
            </Elements>
          </StripeProvider>
        </Card>
      </>
    );
  }
}

export default PaymentsComponent;
