import { withAITracking } from "@microsoft/applicationinsights-react-js";
import {initializeApplicationInsights as applicationInsightsInit} from '../TrackingHelpers';

export const setupApplicationInsightsPageTracking = <P>(reactComponent: React.ComponentType<P>): React.ComponentClass<P> | React.ComponentType<P> =>{
    let reactPlugin = applicationInsightsInit();
    if(reactPlugin===undefined){
        return reactComponent;
    }
    return withAITracking(reactPlugin, reactComponent);
}

export const formatNumber = (x: string | number) => {
    return x.toString().replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}