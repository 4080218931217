import React from 'react';
import { connect } from "react-redux";
import { updatePhoneNumberAction } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import Card from '../../components/Card/Card';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        phoneNumber: state.personalInformation.phoneNumber
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updatePhoneNumber: (value: string | undefined) => dispatch(updatePhoneNumberAction(value))
    };
};

interface UserIdentificationComponentProps {
    phoneNumber?: string;
    updatePhoneNumber: (value: string) => void;
}

export class UserIdentificationComponent extends React.Component<UserIdentificationComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private updatePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updatePhoneNumber(e.target.value);
    }
    render() {
        return (
            <>
                <Card title="What is your phone number?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="phoneNumber">Phone Number</label>
                                <input type="tel" autoComplete="tel" placeholder="(###) ###-####" maxLength={14} required className="form-control" id="phoneNumber" onChange={this.updatePhoneNumber} value={this.props.phoneNumber ? this.props.phoneNumber : ""} />
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(UserIdentificationComponent));