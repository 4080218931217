import React from 'react';
import { connect } from "react-redux";
import { updateIsPermanentResidentAction, updateGreenCardAction } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        isPermanentResident: state.personalInformation.isPermanentResident,
        greenCard: state.personalInformation.greenCard,
        isGreenCardRequired: state.validation.personalInformation.fields.isGreenCardRequired
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateIsPermanentResident: (value?: boolean) => dispatch(updateIsPermanentResidentAction(value)),
        updateGreenCard: (value?: string) => dispatch(updateGreenCardAction(value))
    };
};

interface PermanentResidentComponentProps {
    isPermanentResident?: boolean;
    greenCard?: string;
    isGreenCardRequired: boolean;
    updateIsPermanentResident: (value?: boolean) => void;
    updateGreenCard: (value?: string) => void;
}

export class PermanentResidentComponent extends React.Component<PermanentResidentComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private setIsPermanentResident = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateIsPermanentResident(true);
    }
    private setIsNotPermanentResident = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateIsPermanentResident(false);
    }
    private updateGreenCard = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateGreenCard(e.target.value);
    }
    render() {
        const baseButtonClassName = 'btn btn-outline-primary';
        const yesClassName = baseButtonClassName + (this.props.isPermanentResident ? ' active' : '');
        const noClassName = baseButtonClassName + (this.props.isPermanentResident === false ? ' active' : '');
        return (
            <>
                <Card title="Are you a permanent resident?">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label className={yesClassName}>
                                        <input type="radio" name="options" id="option1" autoComplete="off" onChange={this.setIsPermanentResident} checked={this.props.isPermanentResident === true} /> Yes
                                </label>
                                    <label className={noClassName}>
                                        <input type="radio" name="options" id="option2" autoComplete="off" onChange={this.setIsNotPermanentResident} checked={this.props.isPermanentResident === false} /> No
                                </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.isGreenCardRequired &&
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label htmlFor="greenCard">Green card</label>
                                    <input id="greenCard" type="text" onChange={this.updateGreenCard} value={this.props.greenCard ? this.props.greenCard : ""} />
                                </div>
                            </div>
                        </div>
                    }
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(PermanentResidentComponent));