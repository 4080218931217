import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Question } from '../../models/Question';
import { submitQuestionAnswerAction } from '../../redux/InterviewQuestions/Actions';

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    answerQuestion: (id: string, value: string) => dispatch(submitQuestionAnswerAction(id, value))
  };
};

interface SearchQuestionComponentProps {
  question: Question;
  answerQuestion: (id: string, value: string) => void;
}
class SearchQuestionComponent extends React.Component<SearchQuestionComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  private answerQuestion(answer: string) {
    this.props.answerQuestion(this.props.question.id, answer);
  }

  private answer = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.answerQuestion(e.target.value);
  }
  render() {
    let value = this.props.question.answer === undefined ? "" : this.props.question.answer;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <textarea className="form-control" onChange={this.answer} value={value} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(SearchQuestionComponent);