import CurrentPage from "../../models/CurrentPage";
import * as Actions from './Actions';
import * as PersonalInformationActions from '../PersonalInformation/Actions';
import * as DemographicInformationActions from '../DemographicInformation/Actions';
import CurrentPageState, { LastPageMovement } from "../../models/State/CurrentPageState";
import AppState from "../../models/State/AppState";
import { getNextQuestion, getPreviousQuestion, isCurrentQuestionComplete } from "../InterviewQuestions/Utilities";
import CaseResult from "../../models/CaseResult";
import { personalInformationPages } from "./Utilities";
import { recordEvent } from "../../TrackingHelpers";
import DemographicInformationState from "../../models/State/DemographicInformationState";

type Action = ReturnType<typeof Actions.fetchPageOrderAction> |
    ReturnType<typeof Actions.receivedPageOrderAction> |
    ReturnType<typeof Actions.detailsPageAction> |
    ReturnType<typeof Actions.fullDetailsPageAction> |
    ReturnType<typeof Actions.fullTermsPageAction> |
    ReturnType<typeof Actions.hideStateUnavailableModalAction> |
    ReturnType<typeof Actions.moveNextPageAction> |
    ReturnType<typeof Actions.previousPageAction> |
    ReturnType<typeof Actions.approvedPageAction> |
    ReturnType<typeof Actions.declinedPageAction> |
    ReturnType<typeof Actions.updateCurrentQuestionAction> |
    ReturnType<typeof PersonalInformationActions.updateIsCitizenAction> | 
    ReturnType<typeof DemographicInformationActions.updateStateAction>;

const nonWorkflowPages = [
    CurrentPage.SeeDetails,
    CurrentPage.FullTerms,
    CurrentPage.FullDetails
]

const nextPageReducer = (state: AppState): CurrentPageState => {
    if (state.currentPage.previousPages === undefined) {
        state.currentPage.previousPages = [];
    }
    if (state.currentPage.currentPage !== undefined) {
        state.currentPage.previousPages.push(state.currentPage.currentPage);
    }

    let nextPage = getNextPage(state);

    return { ...state.currentPage, ...nextPage, lastPageMovement: LastPageMovement.Forward };
};

const getNextPage = (state: AppState): Partial<CurrentPageState> => {
    if (state.currentPage.pageOrder === undefined || state.currentPage.pageOrder.length === 0) {
        return { ...setupPageProps(state, state.currentPage.currentPage) };
    }

    if (state.currentPage.currentPage === undefined) {
        return { currentPage: state.currentPage.pageOrder[0].page, ...setupPageProps(state, state.currentPage.pageOrder[0].page) };
    }

    if (!canContinue(state)) {
        return { ...setupPageProps(state, state.currentPage.currentPage) };
    }

    let nextPage: CurrentPage;
    let questionIndex: number | undefined = undefined;
    if (state.currentPage.currentPage === CurrentPage.State && !state.validation.demographicInformation.fields.isStateAvailable) {
        nextPage = CurrentPage.State
        recordEvent("Demographic Information", "Selected unavailable state", state.demographicInformation.state);
    } else if (state.currentPage.currentPage === CurrentPage.InterviewQuestions) {
        let nextQuestion = getNextQuestion(state);
        if (!nextQuestion.hasQuestionsLoaded) {
            nextPage = CurrentPage.InterviewQuestions;
        } else if (nextQuestion.movedPast) {
            nextPage = findNextPage(state);
        } else {
            nextPage = CurrentPage.InterviewQuestions;
            questionIndex = nextQuestion.questionIndex
        }
    } else if (state.currentPage.currentPage === CurrentPage.CaseDecisionDetermination && state.case.caseResult !== undefined && state.case.caseResult !== CaseResult.Processing) {
        if (state.case.caseResult === CaseResult.Declined) {
            nextPage = CurrentPage.Declined;
        } else if (state.case.caseResult === CaseResult.SentToUnderwriting) {
            nextPage = CurrentPage.TentativeSentToUnderwriting;
        } else {
            nextPage = CurrentPage.TentativeApproved;
        }
    } else if (state.currentPage.currentPage === CurrentPage.SubmittingApplication && state.policy !== undefined && state.policy.policyNumber !== undefined) {
        nextPage = CurrentPage.FinalApproved;
    }
    else if (state.currentPage.currentPage === CurrentPage.TentativeApproved || state.currentPage.currentPage === CurrentPage.TentativeSentToUnderwriting ) {
        nextPage = CurrentPage.Payment;
    } else {
        nextPage = findNextPage(state);
    }

    return { currentPage: nextPage, currentQuestionIndex: questionIndex, ...setupPageProps(state, nextPage) };
};

const findNextPage = (state: AppState): CurrentPage => {
    if (state.currentPage.currentPage === undefined || state.currentPage.pageOrder === undefined) {
        throw Error("Invalid page state");
    }

    let currentPageIndex = state.currentPage.pageOrder.findIndex(a => a.page === state.currentPage.currentPage);
    let nextPageInOrder = state.currentPage.pageOrder.find((a, i) => i > currentPageIndex && a.isRequired);
    if (nextPageInOrder === undefined) {
        return state.currentPage.currentPage;
    } else {
        return nextPageInOrder.page;
    }
}

const setupPageProps = (state: AppState, page?: CurrentPage): Partial<CurrentPageState> => {
    if (page === CurrentPage.State) {
        return {
            displayStateIsUnavailable: !state.validation.demographicInformation.fields.isStateAvailable
        }
    }
    return {};
}

const previousPageReducer = (state: AppState): CurrentPageState => {
    let nextPage = getPreviousPage(state);
    return { ...state.currentPage, ...(nextPage as any), lastPageMovement: LastPageMovement.Backward };
}

const getPreviousPage = (state: AppState): Partial<CurrentPageState> => {
    if (state.currentPage.pageOrder === undefined || state.currentPage.pageOrder.length === 0) {
        return { currentPage: state.currentPage.currentPage, ...setupPageProps(state, state.currentPage.currentPage) };
    }

    if (state.currentPage.currentPage === undefined) {
        return { currentPage: state.currentPage.pageOrder[0].page, ...setupPageProps(state, state.currentPage.pageOrder[0].page) };
    }

    if (!canGoBack(state)) {
        return { currentPage: state.currentPage.currentPage, ...setupPageProps(state, state.currentPage.currentPage) };
    }

    let previousPage: CurrentPage;
    let questionIndex: number | undefined = undefined;
    if (state.currentPage.currentPage === CurrentPage.InterviewQuestions) {
        let nextQuestion = getPreviousQuestion(state);
        if (!nextQuestion.hasQuestionsLoaded) {
            previousPage = CurrentPage.InterviewQuestions;
        } else if (nextQuestion.movedPast) {
            previousPage = findPreviousPage(state);
        } else {
            previousPage = CurrentPage.InterviewQuestions;
            questionIndex = nextQuestion.questionIndex
        }
    } else {
        previousPage = findPreviousPage(state);
    }

    if (state.currentPage.previousPages !== undefined && nonWorkflowPages.includes(state.currentPage.currentPage)) {
        let nextPage = state.currentPage.previousPages.pop();
        return { currentPage: nextPage, currentQuestionIndex: questionIndex, ...setupPageProps(state, previousPage) };
    } else if (state.currentPage.previousPages !== undefined) {
        state.currentPage.previousPages.pop();
    }

    return { currentPage: previousPage, currentQuestionIndex: questionIndex, ...setupPageProps(state, previousPage) };
}

const findPreviousPage = (state: AppState) => {
    if (state.currentPage.currentPage === undefined || state.currentPage.pageOrder === undefined) {
        throw Error("Invalid page state");
    }
    let currentPageIndex = state.currentPage.pageOrder.findIndex(a => a.page === state.currentPage.currentPage);
    let prevPageInOrder;
    for (let i = currentPageIndex - 1; i >= 0; i--) {
        if (state.currentPage.pageOrder[i].isRequired) {
            prevPageInOrder = state.currentPage.pageOrder[i];
            break;
        }
    }
    if (prevPageInOrder === undefined) {
        return state.currentPage.currentPage;
    } else {
        return prevPageInOrder.page;
    }
}

const getPotentialPreviousPage = (state: AppState): CurrentPage | undefined => {
    if (state.currentPage.pageOrder === undefined || state.currentPage.pageOrder.length === 0) {
        return state.currentPage.currentPage;
    }

    let previousPage: CurrentPage;

    let currentPageIndex = state.currentPage.pageOrder.findIndex(a => a.page === state.currentPage.currentPage);
    let prevPageInOrder;
    for (let i = currentPageIndex - 1; i >= 0; i--) {
        if (state.currentPage.pageOrder[i].isRequired) {
            prevPageInOrder = state.currentPage.pageOrder[i];
            break;
        }
    }
    if (prevPageInOrder === undefined) {
        return state.currentPage.currentPage;
    }
    previousPage = prevPageInOrder.page;

    return previousPage;
}

const detailsPageReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.DETAILS) {
        return state;
    }
    if (state.currentPage !== undefined && state.previousPages !== undefined) {
        state.previousPages.push(state.currentPage);
    }

    return { ...state, currentPage: CurrentPage.SeeDetails, lastPageMovement: LastPageMovement.Forward };
}


const fullDetailsPageReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.FULL_DETAILS) {
        return state;
    }
    if (state.currentPage !== undefined && state.previousPages !== undefined) {
        state.previousPages.push(state.currentPage);
    }

    return { ...state, currentPage: CurrentPage.FullDetails, lastPageMovement: LastPageMovement.Forward };
}


const fullTermsPageReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.FULL_TERMS) {
        return state;
    }
    if (state.currentPage !== undefined && state.previousPages !== undefined) {
        state.previousPages.push(state.currentPage);
    }

    return { ...state, currentPage: CurrentPage.FullTerms, lastPageMovement: LastPageMovement.Forward };
}

const approvedPageReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.APPROVED) {
        return state;
    }

    return { ...state, currentPage: CurrentPage.FinalApproved };
}

const declinedPageReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.DECLINED) {
        return state;
    }

    return { ...state, currentPage: CurrentPage.Declined };
}

const updateIsCitizenReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== PersonalInformationActions.UPDATE_ISCITIZEN || state.pageOrder === undefined) {
        return state;
    }
    let permanentStatusPageIndex = state.pageOrder.findIndex(a => a.page === CurrentPage.PermanentStatus);
    if (permanentStatusPageIndex === -1) {
        return state;
    }
    let items = [...state.pageOrder];
    let item = { ...state.pageOrder[permanentStatusPageIndex], isRequired: !action.payload.isCitizen };
    items[permanentStatusPageIndex] = item;

    return { ...state, pageOrder: items };
}

const updateStateReducer = (state: CurrentPageState, action: Action, demographicInformation: DemographicInformationState): CurrentPageState => {
    if (action.type !== DemographicInformationActions.UPDATE_STATE || state.pageOrder === undefined) {
        return state;
    }
    let comprehensiveHealthcarePageIndex = state.pageOrder.findIndex(a => a.page === CurrentPage.ComprehensiveHealthcare);
    if (comprehensiveHealthcarePageIndex === -1) {
        return state;
    }
    let items = [...state.pageOrder];
    let item = { ...state.pageOrder[comprehensiveHealthcarePageIndex], isRequired: demographicInformation.state === "CA" };
    items[comprehensiveHealthcarePageIndex] = item;

    return { ...state, pageOrder: items };
}


const fetchPageOrderReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.FETCH_PAGEORDER) {
        return state;
    }

    return { ...state, isLoadingPageOrderData: true };
}

const hideStateUnavailableModalReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.HIDE_STATEUNAVAILABLEMODAL) {
        return state;
    }

    if (state.currentPage !== CurrentPage.State) {
        return state;
    }
    return { ...state, displayStateIsUnavailable: false };
}

const receivedPageOrderReducer = (state: CurrentPageState, action: Action, demographicInformation: DemographicInformationState): CurrentPageState => {
    if (action.type !== Actions.RECEIVED_PAGEORDER) {
        return state;
    }

    let currentPage: CurrentPage | undefined;
    if (action.payload.pageOrder !== undefined && action.payload.pageOrder.length > 0) {
        currentPage = action.payload.pageOrder[0].page;
    }

    if(action.payload.pageOrder !== undefined) {
        let comprehensiveHealthcarePageIndex = action.payload.pageOrder.findIndex(a => a.page === CurrentPage.ComprehensiveHealthcare);
        if (comprehensiveHealthcarePageIndex > -1) {
            action.payload.pageOrder[comprehensiveHealthcarePageIndex].isRequired = (demographicInformation.state === "CA");
        }
    }

    return { ...state, currentPage: currentPage, pageOrder: action.payload.pageOrder, isLoadingPageOrderData: false, displayStateIsUnavailable: false };
}

const updateCurrentQuestionReducer = (state: CurrentPageState, action: Action): CurrentPageState => {
    if (action.type !== Actions.UPDATE_CURRENTQUESTION) {
        return state;
    }

    return { ...state, currentQuestionIndex: action.payload.index };
}

export default function setCurrentPageState(state: AppState, action?: Action): CurrentPageState {
    let appState = { ...state };
    if (appState.currentPage === undefined) {
        appState.currentPage = { isLoadingPageOrderData: false, canGoBack: false, canContinue: false };
    }

    if (appState.currentPage.currentPage === undefined || (action !== undefined && action.type === Actions.NEXT)) {
        appState.currentPage = nextPageReducer(appState);
    }
    if (action !== undefined && action.type === Actions.PREVIOUS) {
        appState.currentPage = previousPageReducer(appState);
    }
    if (action !== undefined && action.type === Actions.DETAILS) {
        appState.currentPage = detailsPageReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.FULL_DETAILS) {
        appState.currentPage = fullDetailsPageReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.FULL_TERMS) {
        appState.currentPage = fullTermsPageReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.APPROVED) {
        appState.currentPage = approvedPageReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.DECLINED) {
        appState.currentPage = declinedPageReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.FETCH_PAGEORDER) {
        appState.currentPage = fetchPageOrderReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.RECEIVED_PAGEORDER) {
        appState.currentPage = receivedPageOrderReducer(appState.currentPage, action, appState.demographicInformation);
    }
    if (action !== undefined && action.type === PersonalInformationActions.UPDATE_ISCITIZEN) {
        appState.currentPage = updateIsCitizenReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === DemographicInformationActions.UPDATE_STATE) {
        appState.currentPage = updateStateReducer(appState.currentPage, action, appState.demographicInformation);
    }
    if (action !== undefined && action.type === Actions.HIDE_STATEUNAVAILABLEMODAL) {
        appState.currentPage = hideStateUnavailableModalReducer(appState.currentPage, action);
    }
    if (action !== undefined && action.type === Actions.UPDATE_CURRENTQUESTION) {
        appState.currentPage = updateCurrentQuestionReducer(appState.currentPage, action);
    }

    return setCanNavigateState(appState);
}

const setCanNavigateState = (state: AppState): CurrentPageState => {
    return {
        ...state.currentPage,
        canGoBack: canGoBack(state),
        canContinue: canContinue(state),
        progress: getProgress(state)
    };
}

const getProgress = (state: AppState): number | undefined => {
    if (state.currentPage.pageOrder === undefined || state.currentPage.currentPage === undefined) {
        return undefined;
    }

    if (personalInformationPages.some(a => state.currentPage.currentPage === a)) {
        let totalCount = 0;
        let completedCount = 0;
        let currentPageFound = false;

        if (state.currentPage.pageOrder === undefined) {
            return undefined;
        }

        state.currentPage.pageOrder.forEach(a => {
            if (a.page === state.currentPage.currentPage) {
                currentPageFound = true;
            }
            if (a.isRequired && personalInformationPages.some(b => a.page === b)) {
                totalCount++;
                if (!currentPageFound) {
                    completedCount++;
                }
            }
        })

        return completedCount / totalCount * 50;
    } else if (state.currentPage.currentPage === CurrentPage.InterviewQuestions) {
        if (state.currentPage.currentQuestionIndex === undefined || state.interviewQuestions.questions === undefined) {
            return 50;
        }
        return (state.currentPage.currentQuestionIndex / state.interviewQuestions.questions.length * 50) + 50
    } else {
        return undefined;
    }


}

const canGoBack = (state: AppState): boolean => {
    if (state.currentPage.currentPage === CurrentPage.InterviewQuestions && state.currentPage.currentQuestionIndex !== undefined && state.currentPage.currentQuestionIndex > 0) {
        return true;
    }
    if (state.currentPage.currentPage === undefined || state.currentPage.pageOrder === undefined || state.currentPage.pageOrder.length === 0) {
        return false;
    }
    if (state.currentPage.pageOrder[0].page === state.currentPage.currentPage) {
        return false;
    }
    if (state.currentPage.currentPage === CurrentPage.BenefitSelection) {
        return false;
    }

    var previousPage = getPotentialPreviousPage(state);
    if (previousPage === CurrentPage.BenefitSelection) {
        return false;
    }
    return true;
}

const canContinue = (state: AppState): boolean => {
    if (state.currentPage.currentPage === undefined || state.currentPage.pageOrder === undefined || state.currentPage.pageOrder.length === 0) {
        return false;
    }
    if (state.currentPage.pageOrder[state.currentPage.pageOrder.length - 1].page === state.currentPage.currentPage) {
        return false;
    }
    switch (state.currentPage.currentPage) {
        case CurrentPage.DateOfBirth:
            return !state.validation.demographicInformation.fields.dateOfBirthValidation;
        case CurrentPage.Gender:
            return state.validation.demographicInformation.fields.isGenderValid;
        case CurrentPage.State:
            return state.validation.demographicInformation.fields.isStateValid;
        case CurrentPage.NicotineProducts:
            return state.validation.demographicInformation.fields.isNicotineProductsValid;
        case CurrentPage.BenefitSelection:
            return state.validation.selectedAmount.fields.isSelectedAmountValid;
        case CurrentPage.Terms:
            return state.validation.personalInformation.fields.userDidAcceptTerms;
        case CurrentPage.Birthstate:
            return state.validation.personalInformation.fields.isBirthLocationValid;
        case CurrentPage.Body:
            return state.validation.personalInformation.fields.isHeightFeetValid &&
                state.validation.personalInformation.fields.isHeightInchesValid &&
                state.validation.personalInformation.fields.isWeightValid;
        case CurrentPage.Citizenship:
            return state.validation.personalInformation.fields.isCitizenValid;
        case CurrentPage.ExistingCoverage:
            return state.validation.personalInformation.fields.isHasExistingCoverageValid;
        case CurrentPage.ComprehensiveHealthcare:
            return state.validation.personalInformation.fields.isHasComprehensiveHealthcareValid;
        case CurrentPage.PermanentStatus:
            return state.validation.personalInformation.fields.isPermanentResidentValid &&
                state.validation.personalInformation.fields.isGreenCardValid;
        case CurrentPage.Phone:
            return state.validation.personalInformation.fields.isPhoneNumberValid;
        case CurrentPage.Residence:
            return state.validation.personalInformation.fields.isStreetAddressValid &&
                state.validation.personalInformation.fields.isCityValid &&
                state.validation.personalInformation.fields.isZipValid;
        case CurrentPage.SocialSecurityNumber:
            return state.validation.personalInformation.fields.isSocialSecurityNumberValid;
        case CurrentPage.NicotineDetails:
            return state.validation.personalInformation.fields.isTobaccoUsesValid &&
                state.validation.personalInformation.fields.isTobaccoTypeValid &&
                state.validation.personalInformation.fields.isTobaccoUseCountValid &&
                !state.validation.personalInformation.fields.tobaccoLastUseDateValidation;
        case CurrentPage.UserIdentification:
            return state.validation.personalInformation.fields.isUsersFirstNameValid &&
                state.validation.personalInformation.fields.isUsersLastNameValid &&
                state.validation.personalInformation.fields.isEmailAddressValid;
        case CurrentPage.Payment:
            return state.validation.payment.fields.isPaymentInformationValid &&
                state.validation.payment.fields.isPaymentTokenValid;
        case CurrentPage.InterviewQuestions:
            return isCurrentQuestionComplete(state) === true;
        case CurrentPage.CaseDecisionDetermination:
            return state.case.caseResult !== undefined &&
                state.case.caseResult !== CaseResult.Processing;
        case CurrentPage.TentativeApproved:
            return true;
        case CurrentPage.TentativeSentToUnderwriting:
            return true;
        case CurrentPage.SignDocuments:
            return true;
        case CurrentPage.SubmittingApplication:
            var canContinue = state.policy.policyNumber !== undefined && state.case.applicationSubmitted === true && state.case.caseResult !== undefined;
            return canContinue;
    }
    return false;
}