import React from 'react';

import { connect } from "react-redux";
import { updateDateOfBirthAction } from '../../redux/DemographicInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import DateSelector from '../../components/DateSelector/DateSelector';

import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        dateOfBirth: state.demographicInformation.dateOfBirth,
        dateOfBirthValidation: state.validation.demographicInformation.fields.dateOfBirthValidation,
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateDateOfBirth: (value: Date | undefined) => dispatch(updateDateOfBirthAction(value))
    };
};

interface DateOfBirthComponentProps {
    dateOfBirth?: Date;
    dateOfBirthValidation: string;
    updateDateOfBirth: (dateOfBirth: Date | undefined) => void;
}


export class DateOfBirthComponent extends React.Component<DateOfBirthComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    private updateDateOfBirth = (date: Date) => {
        this.props.updateDateOfBirth(date);
    }

    render() {
        return (
            <>
                <Card title="Let's get to know you better!">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <DateSelector label={"When's your birthday? (MM/DD/YYYY)"} dateValidation={this.props.dateOfBirthValidation} dateCallback={this.updateDateOfBirth} defaultValue={this.props.dateOfBirth}/>
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(DateOfBirthComponent));