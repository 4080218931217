import React from 'react';
import { AnyAction } from 'redux';
import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';

import AppState from '../../models/State/AppState';

const mapStateToProps = (state: AppState) => {
    return {
        progress: state.currentPage.progress
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
    };
};


interface ProgressBarComponentProps {
    progress?: number;
}

export class ProgressBarComponent extends React.Component<ProgressBarComponentProps>{
    render() {
        return (
            this.props.progress !== undefined &&
            <div className="row justify-content-center mb-2">
                <div className="col-lg-7 col-12">
                    <div className="progress">
                        <div className="progress-bar bg-secondary" style={{ width: this.props.progress.toFixed(0) + "%" }} role="progressbar" aria-valuenow={this.props.progress} aria-valuemin={0} aria-valuemax={100}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressBarComponent);
