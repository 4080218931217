import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import PaymentState from "../../models/State/PaymentState";

type Action = ReturnType<typeof Actions.updateCardFieldAction> |
    ReturnType<typeof Actions.fetchTokenAction> |
    ReturnType<typeof Actions.retrievedTokenAction>;

export function updateCardFieldReducer(state: PaymentState, action: Action): PaymentState {
    if (action.type !== Actions.UPDATE_CARDFIELDS) {
        return state;
    }

    return { ...state, stripeElementData: action.payload.elementChangeResponse };
}

export function fetchAvailableStatesReducer(state: PaymentState, action: Action): PaymentState {
    if (action.type !== Actions.FETCH_TOKEN) {
        return state;
    }

    return { ...state, isRetrievingToken: true };
}

export function retrievedAvailableStatesReducer(state: PaymentState, action: Action): PaymentState {
    if (action.type !== Actions.RETRIEVED_TOKEN) {
        return state;
    }

    if (action.payload.tokenResponse.error) {
        return { ...state, token: undefined, paymentError: action.payload.tokenResponse.error.message, isRetrievingToken: false };
    }

    if (!action.payload.tokenResponse.token || !action.payload.tokenResponse.token.id || !action.payload.tokenResponse.token.card) {
        return { ...state, token: undefined, paymentError: 'Unable to process credit card', isRetrievingToken: false };
    }

    return { ...state, paymentError: undefined, token: action.payload.tokenResponse.token.id, cardType: action.payload.tokenResponse.token.card.brand, isRetrievingToken: false };
}

export default createReducer<PaymentState, Actions.Types, Action>({ isRetrievingToken: false }, {
    [Actions.FETCH_TOKEN]: fetchAvailableStatesReducer,
    [Actions.RETRIEVED_TOKEN]: retrievedAvailableStatesReducer,
    [Actions.UPDATE_CARDFIELDS]: updateCardFieldReducer
});