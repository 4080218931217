import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import AppState from "../../models/State/AppState";
import { sendApplicationConfirmationEmail, sendApplicationUnderwritingEmail } from "../../accessors/NotificationAccessor";

export const sendConfirmationEmail: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (isFinalApproved: boolean) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.personalInformation.usersFirstName !== undefined &&
            currState.personalInformation.usersLastName !== undefined &&
            currState.personalInformation.emailAddress !== undefined &&
            currState.policy.policyNumber !== undefined) {
            if (isFinalApproved) {
                sendApplicationConfirmationEmail(currState.personalInformation.usersFirstName,
                    currState.personalInformation.usersLastName,
                    currState.personalInformation.emailAddress,
                    currState.demographicInformation.referrer,
                    currState.policy.policyNumber.toString());
            } else {
                sendApplicationUnderwritingEmail(currState.personalInformation.usersFirstName,
                    currState.personalInformation.usersLastName,
                    currState.personalInformation.emailAddress,
                    currState.demographicInformation.referrer,
                    currState.policy.policyNumber.toString());                
            }
        }
    };
}