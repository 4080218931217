import React from 'react';
import { connect } from "react-redux";
import AppState from '../../models/State/AppState';

import { Dictionary } from '../../models/State/BrandState';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        canContinue: state.currentPage.canContinue,
        links: state.brand.links
    };
};

interface DeclinedComponentProps {
    canContinue: boolean;
    links?: Dictionary;
}

class Declined extends React.Component<DeclinedComponentProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private getHomepageAddress = (): string | undefined => {
        if (this.props.links !== undefined && this.props.links["homepage"] !== undefined) {
          return this.props.links["homepage"];
        }
    
        return undefined;
      }

    render() {
        return (
            <>
                <div className="p-3">
                    <div className="row mb-3">
                        <h1 className="status-header-text">We appreciate your time, but we are unable to offer you Critical Illness Insurance coverage at the moment.</h1>
                        <p>If you would like more information, please contact us at <a href="mailto:hello@prepareinsure.com">hello@prepareinsure.com</a></p>
                    </div>
                    <div className="row">
                        <div className="">
                            <a className="btn btn-primary btn-block" href={this.getHomepageAddress()}>Done</a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(setupApplicationInsightsPageTracking(Declined));