import NicotineType from "../../models/NicotineType";

// Action Types
export const UPDATE_USERDIDACCEPTTERMS = 'personal_information/update_userdidacceptterms';
export const UPDATE_USERSFIRSTNAME = 'personal_information/update_usersfirstname';
export const UPDATE_USERSLASTNAME = 'personal_information/update_userslastname';
export const UPDATE_EMAILADDRESS = 'personal_information/update_emailaddress';
export const UPDATE_DATEOFBIRTH = 'personal_information/update_dateofbirth';
export const UPDATE_STREETADDRESS = 'personal_information/update_streetaddress';
export const UPDATE_STREETADDRESS2 = 'personal_information/update_streetaddress2';
export const UPDATE_CITY = 'personal_information/update_city';
export const UPDATE_ZIP = 'personal_information/update_zip';
export const UPDATE_ISCITIZEN = 'personal_information/update_iscitizen';
export const UPDATE_HASEXISTINGCOVERAGE = 'personal_information/update_hasexistingcoverage';
export const UPDATE_HASCOMPREHENSIVEHEALTHCARE = 'personal_information/update_hascomprehensivehealthcare';
export const UPDATE_ISPERMANENTRESIDENT = 'personal_information/update_ispermanentresident';
export const UPDATE_GREENCARD = 'personal_information/update_greencard';
export const UPDATE_BIRTHCOUNTRY = 'personal_information/update_birthcountry';
export const UPDATE_BIRTHSTATE = 'personal_information/update_birthstate';
export const UPDATE_PHONENUMBER = 'personal_information/update_phonenumber';
export const UPDATE_SOCIALSECURITYNUMBER = 'personal_information/update_socialsecuritynumber';
export const UPDATE_TOBACCOUSES = 'personal_information/update_tobaccouses';
export const UPDATE_TOBACCOTYPE = 'personal_information/update_tobaccotype';
export const UPDATE_TOBACCODAILYUSECOUNT = 'personal_information/update_tobaccodailyusecount';
export const UPDATE_TOBACCOMONTHLYUSECOUNT = 'personal_information/update_tobaccomonthlyusecount';
export const UPDATE_TOBACCOLASTUSEDATE = 'personal_information/update_tobaccolastusedate';
export const UPDATE_HEIGHTFEET = 'personal_information/update_heightfeet';
export const UPDATE_HEIGHTINCHES = 'personal_information/update_heightinches';
export const UPDATE_WEIGHT = 'personal_information/update_weight';

export type Types = typeof UPDATE_USERDIDACCEPTTERMS |
    typeof UPDATE_USERSFIRSTNAME |
    typeof UPDATE_USERSLASTNAME |
    typeof UPDATE_EMAILADDRESS |
    typeof UPDATE_STREETADDRESS |
    typeof UPDATE_STREETADDRESS2 |
    typeof UPDATE_CITY |
    typeof UPDATE_ZIP |
    typeof UPDATE_ISCITIZEN |
    typeof UPDATE_HASEXISTINGCOVERAGE |
    typeof UPDATE_HASCOMPREHENSIVEHEALTHCARE |
    typeof UPDATE_ISPERMANENTRESIDENT |
    typeof UPDATE_GREENCARD |
    typeof UPDATE_BIRTHCOUNTRY |
    typeof UPDATE_BIRTHSTATE |
    typeof UPDATE_PHONENUMBER |
    typeof UPDATE_SOCIALSECURITYNUMBER |
    typeof UPDATE_TOBACCOUSES |
    typeof UPDATE_TOBACCOTYPE |
    typeof UPDATE_TOBACCODAILYUSECOUNT |
    typeof UPDATE_TOBACCOMONTHLYUSECOUNT |
    typeof UPDATE_TOBACCOLASTUSEDATE |
    typeof UPDATE_HEIGHTFEET |
    typeof UPDATE_HEIGHTINCHES |
    typeof UPDATE_WEIGHT;

export interface UpdateUserDidAcceptTermsPayload{
    userDidAcceptTerms?: boolean
}

export interface UpdateUsersFirstNamePayload {
    usersFirstName?: string;
}

export interface UpdateUsersLastNamePayload {
    usersLastName?: string;
}

export interface UpdateEmailAddressPayload {
    emailAddress?: string;
}

export interface UpdateStreetAddressPayload {
    streetAddress?: string;
}

export interface UpdateStreetAddress2Payload {
    streetAddress2?: string;
}

export interface UpdateCityPayload {
    city?: string;
}

export interface UpdateZipPayload {
    zip?: string;
}

export interface UpdateIsCitizenPayload {
    isCitizen?: boolean;
}

export interface UpdateHasExistingCoveragePayload {
    hasExistingCoverage?: boolean;
}

export interface UpdateHasComprehensiveHealthcarePayload {
    hasComprehensiveHealthcare?: boolean;
}

export interface UpdateIsPermanentResidentPayload {
    isPermanentResident?: boolean;
}

export interface UpdateGreenCardPayload {
    greenCard?: string;
}

export interface UpdateBirthCountryPayload {
    birthCountry?: string;
}

export interface UpdateBirthStatePayload {
    birthState?: string;
}

export interface UpdatePhoneNumberPayload {
    phoneNumber?: string;
}

export interface UpdateSocialSecurityNumberPayload {
    socialSecurityNumber?: string;
}

export interface UpdateTobaccoUses {
    tobaccoUses: boolean;
}

export interface UpdateTobaccoType {
    tobaccoType?: NicotineType;
}

export interface UpdateTobaccoDailyUseCount {
    tobaccoDailyUseCount: number;
}

export interface UpdateTobaccoMonthlyUseCount {
    tobaccoMonthlyUseCount: number;
}

export interface UpdateTobaccoLastUseDate {
    tobaccoLastUseDate: Date;
}

export interface UpdateHeightFeetPayload {
    feet?: number;
}

export interface UpdateHeightInchesPayload {
    inches?: number;
}

export interface UpdateWeightPayload {
    pounds?: number;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export function updateUsersDidAcceptTermsAction(userDidAcceptTerms?: boolean): TypePayload<typeof UPDATE_USERDIDACCEPTTERMS, UpdateUserDidAcceptTermsPayload> {
    return { type: UPDATE_USERDIDACCEPTTERMS, payload: { userDidAcceptTerms: userDidAcceptTerms } };
}

export function updateUsersFirstNameAction(usersFirstName?: string): TypePayload<typeof UPDATE_USERSFIRSTNAME, UpdateUsersFirstNamePayload> {
    return { type: UPDATE_USERSFIRSTNAME, payload: { usersFirstName: usersFirstName } };
}

export function updateUsersLastNameAction(usersLastName?: string): TypePayload<typeof UPDATE_USERSLASTNAME, UpdateUsersLastNamePayload> {
    return { type: UPDATE_USERSLASTNAME, payload: { usersLastName: usersLastName } };
}

export function updateEmailAddressAction(emailAddress?: string): TypePayload<typeof UPDATE_EMAILADDRESS, UpdateEmailAddressPayload> {
    return { type: UPDATE_EMAILADDRESS, payload: { emailAddress: emailAddress } };
}

export function updateStreetAddressAction(streetAddress?: string): TypePayload<typeof UPDATE_STREETADDRESS, UpdateStreetAddressPayload> {
    return { type: UPDATE_STREETADDRESS, payload: { streetAddress: streetAddress } };
}

export function updateStreetAddress2Action(streetAddress?: string): TypePayload<typeof UPDATE_STREETADDRESS2, UpdateStreetAddress2Payload> {
    return { type: UPDATE_STREETADDRESS2, payload: { streetAddress2: streetAddress } };
}

export function updateCityAction(city?: string): TypePayload<typeof UPDATE_CITY, UpdateCityPayload> {
    return { type: UPDATE_CITY, payload: { city: city } };
}

export function updateZipAction(zip?: string): TypePayload<typeof UPDATE_ZIP, UpdateZipPayload> {
    return { type: UPDATE_ZIP, payload: { zip: zip } };
}

export function updateIsCitizenAction(isCitizen?: boolean): TypePayload<typeof UPDATE_ISCITIZEN, UpdateIsCitizenPayload> {
    return { type: UPDATE_ISCITIZEN, payload: { isCitizen: isCitizen } };
}

export function updateHasExistingCoverageAction(hasExistingCoverage?: boolean): TypePayload<typeof UPDATE_HASEXISTINGCOVERAGE, UpdateHasExistingCoveragePayload> {
    return { type: UPDATE_HASEXISTINGCOVERAGE, payload: { hasExistingCoverage: hasExistingCoverage } };
}

export function updateHasComprehensiveHealthcareAction(hasComprehensiveHealthcare?: boolean): TypePayload<typeof UPDATE_HASCOMPREHENSIVEHEALTHCARE, UpdateHasComprehensiveHealthcarePayload> {
    return { type: UPDATE_HASCOMPREHENSIVEHEALTHCARE, payload: { hasComprehensiveHealthcare: hasComprehensiveHealthcare } };
}

export function updateIsPermanentResidentAction(isPermanentResident?: boolean): TypePayload<typeof UPDATE_ISPERMANENTRESIDENT, UpdateIsPermanentResidentPayload> {
    return { type: UPDATE_ISPERMANENTRESIDENT, payload: { isPermanentResident: isPermanentResident } };
}

export function updateGreenCardAction(greenCard?: string): TypePayload<typeof UPDATE_GREENCARD, UpdateGreenCardPayload> {
    return { type: UPDATE_GREENCARD, payload: { greenCard: greenCard } };
}

export function updateBirthCountryAction(birthCountry?: string): TypePayload<typeof UPDATE_BIRTHCOUNTRY, UpdateBirthCountryPayload> {
    return { type: UPDATE_BIRTHCOUNTRY, payload: { birthCountry: birthCountry } };
}

export function updateBirthStateAction(birthState?: string): TypePayload<typeof UPDATE_BIRTHSTATE, UpdateBirthStatePayload> {
    return { type: UPDATE_BIRTHSTATE, payload: { birthState: birthState } };
}

export function updatePhoneNumberAction(phoneNumber?: string): TypePayload<typeof UPDATE_PHONENUMBER, UpdatePhoneNumberPayload> {
    return { type: UPDATE_PHONENUMBER, payload: { phoneNumber: phoneNumber } };
}

export function updateSocialSecurityNumberAction(socialSecurityNumber?: string): TypePayload<typeof UPDATE_SOCIALSECURITYNUMBER, UpdateSocialSecurityNumberPayload> {
    return { type: UPDATE_SOCIALSECURITYNUMBER, payload: { socialSecurityNumber: socialSecurityNumber } };
}

export function updateTobaccoUsesAction(tobaccoUses: boolean): TypePayload<typeof UPDATE_TOBACCOUSES, UpdateTobaccoUses> {
    return { type: UPDATE_TOBACCOUSES, payload: { tobaccoUses: tobaccoUses } };
}

export function updateTobaccoTypeAction(tobaccoType: NicotineType | undefined): TypePayload<typeof UPDATE_TOBACCOTYPE, UpdateTobaccoType> {
    return { type: UPDATE_TOBACCOTYPE, payload: { tobaccoType: tobaccoType } };
}

export function updateTobaccoDailyUseCountAction(tobaccoDailyUseCount: number): TypePayload<typeof UPDATE_TOBACCODAILYUSECOUNT, UpdateTobaccoDailyUseCount> {
    return { type: UPDATE_TOBACCODAILYUSECOUNT, payload: { tobaccoDailyUseCount: tobaccoDailyUseCount } };
}

export function updateTobaccoMonthlyUseCountAction(tobaccoMonthlyUseCount: number): TypePayload<typeof UPDATE_TOBACCOMONTHLYUSECOUNT, UpdateTobaccoMonthlyUseCount> {
    return { type: UPDATE_TOBACCOMONTHLYUSECOUNT, payload: { tobaccoMonthlyUseCount: tobaccoMonthlyUseCount } };
}

export function updateTobaccoLastUseDateAction(tobaccoLastUseDate: Date): TypePayload<typeof UPDATE_TOBACCOLASTUSEDATE, UpdateTobaccoLastUseDate> {
    return { type: UPDATE_TOBACCOLASTUSEDATE, payload: { tobaccoLastUseDate: tobaccoLastUseDate } };
}

export function updateHeightFeetAction(feet?: number): TypePayload<typeof UPDATE_HEIGHTFEET, UpdateHeightFeetPayload> {
    return { type: UPDATE_HEIGHTFEET, payload: { feet: feet } };
}

export function updateHeightInchesAction(inches?: number): TypePayload<typeof UPDATE_HEIGHTINCHES, UpdateHeightInchesPayload> {
    return { type: UPDATE_HEIGHTINCHES, payload: { inches: inches } };
}

export function updateWeightAction(pounds?: number): TypePayload<typeof UPDATE_WEIGHT, UpdateWeightPayload> {
    return { type: UPDATE_WEIGHT, payload: { pounds: pounds } };
}