import React, { Component } from 'react';
import CardBody from './CardBody';

interface CardProps {
	title: string;
}

class Card extends Component<CardProps> {
	render() {
		return (
			<div className="card mb-4">
				<div className="card-header text-center">
					<h3>{this.props.title}</h3>
				</div>
				<CardBody>
					{this.props.children}
				</CardBody>
			</div>
		);
	}
}

export default Card;