import AppState from "../../models/State/AppState";
import { ActionCreator, AnyAction } from "redux";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { getBirthCountries } from "../../accessors/QuoteAccessor";
import AvailableCountry from "../../models/AvailableCountry";

// Action Types
export const FETCH_AVAILABLECOUNTRIES = 'birth_location/fetch';
export const RETRIEVED_AVAILABLECOUNTRIES = 'birth_location/retrieved_availablecountries';

export type Types = typeof FETCH_AVAILABLECOUNTRIES |
                    typeof RETRIEVED_AVAILABLECOUNTRIES;

export interface FetchAvailableCountriesPayload{
}

export interface RetrievedAvailableCountriesPayload{
    availableCountries: AvailableCountry[];
}

type TypePayload<T1 extends Types, T2> = {type: T1, payload: T2};

export const loadAvailableCountries: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: ()=>AppState): Promise<any> => {
        const currState = getState();
        if(currState.birthLocation.availableCountries === undefined && !currState.birthLocation.isLoadingAvailableCountries){
            dispatch(fetchAvailableCountriesAction());
            const countries = await getBirthCountries();
            return dispatch(retrievedAvailableCountriesAction(countries));
        }
    };
}

export function fetchAvailableCountriesAction(): TypePayload<typeof FETCH_AVAILABLECOUNTRIES, FetchAvailableCountriesPayload> {
    return {type: FETCH_AVAILABLECOUNTRIES, payload: {}};
}

export function retrievedAvailableCountriesAction(availableCountries: AvailableCountry[]): TypePayload<typeof RETRIEVED_AVAILABLECOUNTRIES, RetrievedAvailableCountriesPayload> {
    return {type: RETRIEVED_AVAILABLECOUNTRIES, payload: {availableCountries: availableCountries}};
}