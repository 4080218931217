import React from 'react';
import { Question, QuestionType, PickListOption } from '../../models/Question';
import BinaryQuestion from './BinaryQuestion';
import Search from './SearchQuestion';
import PrimaryQuestion from './PrimaryQuestion';
import OptionsQuestion from './OptionsQuestion';
import TextQuestion from './TextQuestion';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setPickListAnswerAction } from '../../redux/InterviewQuestions/Actions';
import { connect } from 'react-redux';
import NumericQuestion from './NumericQuestion';

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        setAnswer: (questionId: string, pickListId: string, answer: string) => dispatch(setPickListAnswerAction(questionId, pickListId, answer))
    }
};

interface QuestionComponentProps {
    question: Question;
    isSubQuestion: boolean;
    setAnswer: (questionId: string, pickListId: string, answer: string) => void;
}

export class QuestionComponent extends React.Component<QuestionComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    static defaultProps = { isSubQuestion: false };
    private getQuestionTypeComponent = (): JSX.Element => {
        switch (this.props.question.type) {
            case QuestionType.BinaryChoice:
                return <BinaryQuestion question={this.props.question} />;
            case QuestionType.Search:
                return <Search question={this.props.question} />;
            case QuestionType.PrimaryQuestion:
                return <PrimaryQuestion question={this.props.question} />;
            case QuestionType.Options:
                return <OptionsQuestion question={this.props.question} />;
            case QuestionType.Text:
                return <TextQuestion question={this.props.question} />;
            case QuestionType.Numeric:
                return <NumericQuestion question={this.props.question} />;
        }
        return <div>***Unknown Question Type {this.props.question.type} ***</div>;
    }
    render() {
        return (
            <>
                {this.props.question.subHeadingHtml !== undefined && this.props.question.subHeadingHtml.length > 0 &&
                    <div className="row">
                        <div className="col-12" dangerouslySetInnerHTML={{ __html: this.props.question.subHeadingHtml }}>
                        </div>
                    </div>
                }
                {this.getQuestionTypeComponent()}
                <PickListComponent question={this.props.question} setAnswer={this.props.setAnswer} />
                <FollowUpQuestionComponent question={this.props.question.followUpQuestion} setAnswer={this.props.setAnswer} />
            </>
        )
    }
}

interface FollowUpQuestionComponentProps {
    question?: Question;
    setAnswer: (questionId: string, pickListId: string, answer: string) => void;
}

export class FollowUpQuestionComponent extends React.Component<FollowUpQuestionComponentProps>{
    static defaultProps = { isSubQuestion: false };
    render() {
        if (this.props.question === undefined) {
            return null;
        }
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        {this.props.question.text}
                    </div>
                </div>
                <QuestionComponent question={this.props.question} setAnswer={this.props.setAnswer} />
            </>
        )
    }
}

export default connect(null, mapDispatchToProps)(QuestionComponent);

interface PickListComponentProps {
    question: Question;
    setAnswer: (questionId: string, pickListId: string, answer: string) => void;
}

class PickListComponent extends React.Component<PickListComponentProps>{
    render() {
        if (this.props.question.pickList === undefined || this.props.question.pickList.length === 0) {
            return null;
        }
        return (
            this.props.question.pickList.map(a => <PickListOptionComponent option={a} questionId={this.props.question.id} setAnswer={this.props.setAnswer} key={a.id} />)
        )
    }
}

interface PickListOptionProps {
    option: PickListOption;
    questionId: string;
    setAnswer: (questionId: string, pickListId: string, answer: string) => void;
}
class PickListOptionComponent extends React.Component<PickListOptionProps>{
    private setAnswer = (e: React.ChangeEvent<HTMLInputElement>) => {
        let answer = e.target.checked ? "true" : "false";
        this.props.setAnswer(this.props.questionId, this.props.option.id, answer);
    }
    render() {
        const inputId = `option_${this.props.option.id}`;
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id={inputId} checked={this.props.option.answer === "true"} onChange={this.setAnswer} />
                            <label className="form-check-label" htmlFor={inputId}>{this.props.option.text}</label>
                        </div>
                    </div>
                </div>
                <FollowUpQuestionComponent question={this.props.option.followUpQuestion} setAnswer={this.props.setAnswer} />
            </>
        )
    }
}