import React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import AppState from '../../models/State/AppState';
import { connect } from "react-redux";
import { fetchUrlAction } from '../../redux/Documents/Actions';
import { nextPageAction, declinedPageAction } from '../../redux/CurrentPage/Actions';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        signingUrl: state.document.signingUrl
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        continue: () => dispatch(nextPageAction()),
        declined: () => dispatch(declinedPageAction()),
        fetchUrlAction: () => dispatch(fetchUrlAction())
    };
};

interface SignDocumentsProps {
    signingUrl?: string;
    continue: () => void;
    declined: () => void;
    fetchUrlAction: () => void;
}

class SignDocuments extends React.Component<SignDocumentsProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.fetchUrlAction();
    }

    private loadFrame = (): void => {
        var iFrame = document.getElementById('docuSignFrame') as HTMLIFrameElement;
        
        if (iFrame.contentWindow !== null) {
            try {
                if (iFrame.contentWindow.location.href.startsWith(window.location.origin)) {
                    let url = new URL(iFrame.contentWindow.location.href);

                    if (url.searchParams.get("event") === 'signing_complete') {
                        this.props.continue();
                    } else {
                        this.props.declined();
                    }
                }
            } catch(e) {
            }
        }
    };

    render() {
        return (
            <div className="iframe-container">
                {this.props.signingUrl !== undefined &&
                    <iframe src={this.props.signingUrl} title="docuSignFrame" id="docuSignFrame" onLoad={this.loadFrame}></iframe>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(SignDocuments));