import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { nextPageAction, fullTermsPageAction } from '../../redux/CurrentPage/Actions';
import { updateUsersDidAcceptTermsAction } from '../../redux/PersonalInformation/Actions';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';

import './Terms'
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        didAcceptTerms: state.personalInformation.userDidAcceptTerms,
        canContinue: state.currentPage.canContinue
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        acceptTerms: (value?: boolean) => dispatch(updateUsersDidAcceptTermsAction(value)),
        continue: () => dispatch(nextPageAction()),
        seeFullTerms: () => dispatch(fullTermsPageAction())
    };
};

interface TermsComponentProps {
    didAcceptTerms?: boolean;
    canContinue: boolean;
    acceptTerms: (value?: boolean) => void;
    continue: () => void;
    seeFullTerms: () => void;
}

class Terms extends React.Component<TermsComponentProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private acceptTerms = () => {
        this.props.acceptTerms(!this.props.didAcceptTerms);
    }
    private continue = () => {
        this.props.continue();
    }
    private seeFullTerms = () => {
        this.props.seeFullTerms();
    }
    render() {
        return (
            <>
                <Card title="Great Job! Let's take the next step!">
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-3">
                                Policies are underwritten by Assurity Life Insurance Company, an "A-" (Excellent) rated company by A.M. Best.<sup>*</sup> Assurity's roots date back to 1890 and they are excellent at what they do!
                            </div>
                            <div className="mb-1">
                                Let's confirm your identity and eligibility for coverage!
                            </div>
                            <div className="mb-3">
                                <small className="text-muted"><sup>*</sup> A.M. Best ratings range from A++ (Superior) to F (in liquidation)</small>
                            </div>
                            <div className="font-weight-light mb-1">
                                In order to determine your eligibility for insurance, Assurity must collect certain information about you from third parties.  These third parties may include, but not be limited to, MIB Inc., LexisNexis Risk Solutions, pharmacies and pharmacy benefit managers and may include information such as your medical/prescription drug history/conditions (health information), driving records and credit information. By checking "I have read and agreed to the Authorization and Pre-Notice" checkbox, you hereby authorize such third parties to disclose information to Assurity Life Insurance Company as fully described in the Confidential Information Authorization ("Authorization").
                            </div>
                            <div className="font-weight-light mb-1">
                                By checking "I have read and agreed to the Authorization and Pre-Notice" checkbox, you further understand that you may inspect or copy the protected health information described by the Authorization at any time by providing written notice to Assurity. You understand you have the right to revoke your Authorization by writing to Assurity and that a revocation is not effective to the extent action has been taken in reliance on the Authorization.  You understand that this authorization is valid for a period of two years.  You understand that this information may be subject to redisclosure by Assurity and may no longer be protected by federal rules governing privacy of health information, and that this information may only be redisclosed in accordance with other applicable laws or regulations.  You further understand that if you refuse to sign the Authorization, Assurity may not be able to process your application for insurance.
                            </div>
                            <div className="mb-3">
                                <button onClick={this.seeFullTerms} className="btn btn-link font-weight-light p-0">Click Here to view the full text of the Confidential Information Authorization.</button>
                            </div>
                            <div className="custom-control custom-checkbox mb-3">
                                <input type="checkbox" className="custom-control-input" id="acceptTerms" onChange={this.acceptTerms} checked={this.props.didAcceptTerms === true}/>
                                <label className="custom-control-label" htmlFor="acceptTerms">I have read and agreed to the Authorization and Pre-Notice</label>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-primary btn-block" onClick={this.continue} disabled={!this.props.canContinue}>Continue</button>
                            </div>
                        </div>
                    </div>
                </Card>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(Terms));