import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Question } from '../../models/Question';
import { submitQuestionAnswerAction } from '../../redux/InterviewQuestions/Actions';


const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    answerQuestion: (id: string, value: string | undefined) => dispatch(submitQuestionAnswerAction(id, value))
  };
};

interface OptionsQuestionComponentProps {
  question: Question;
  answerQuestion: (id: string, value: string | undefined) => void;
}
class OptionsQuestionComponent extends React.Component<OptionsQuestionComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
   private answer = (e: React.ChangeEvent<HTMLSelectElement>): void => {
     let answer = e.target.value === "" ? undefined : e.target.value;
    this.props.answerQuestion(this.props.question.id, answer);
  }
  render() {
    let values = this.props.question.options.map(a => ({ value: a.value, label: a.text }));
    let answer = this.props.question.answer === undefined ? "" : this.props.question.answer;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <select className="form-control" onChange={(evt) => this.answer(evt)} autoComplete="address-level1" value={answer}>
                <option value="">Choose...</option>
                {
                  values.map(a => <option value={a.value} key={a.value}>{a.label}</option>)
                }
              </select>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(OptionsQuestionComponent);