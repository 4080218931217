import AppState from "../../models/State/AppState";
import { AnyAction, ActionCreator } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { getSigningUrl } from "../../accessors/FormsAccessor";

export const FETCH_URL = 'documents/fetch_url';
export const RETRIEVED_URL = 'documents/retrieved_url';

export type Types = typeof FETCH_URL |
    typeof RETRIEVED_URL;

export interface FetchUrlPayload {

}

export interface RetrievedUrlPayload {
    signingUrl: string;
    envelopeId: string;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export function loadingSigningUrlAction(): TypePayload<typeof FETCH_URL, FetchUrlPayload> {
    return { type: FETCH_URL, payload: {} };
}

export function retrievedSigningUrlAction(signingUrl: string, envelopeId: string): TypePayload<typeof RETRIEVED_URL, RetrievedUrlPayload> {
    return { type: RETRIEVED_URL, payload: { signingUrl: signingUrl, envelopeId: envelopeId } };
}

export const fetchUrlAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();

        if (currState.document.signingUrl === undefined &&
            !currState.document.isLoadingSigningUrl &&
            currState.case.case !== undefined &&
            currState.personalInformation.usersFirstName !== undefined &&
            currState.personalInformation.usersLastName !== undefined &&
            currState.personalInformation.emailAddress !== undefined &&
            currState.benefitAmount.selectedAmount !== undefined &&
            currState.payment.cardType !== undefined) {
            dispatch(loadingSigningUrlAction());
            let result = await getSigningUrl(
                currState.case.case.caseId,
                currState.personalInformation.usersFirstName,
                currState.personalInformation.usersLastName,
                currState.personalInformation.emailAddress,
                currState.benefitAmount.selectedAmount.benefitAmount,
                currState.payment.cardType,
                currState.demographicInformation.referrer);
            
            return dispatch(retrievedSigningUrlAction(result.url, result.envelopeId));
        }
    };
}
