import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import DemographicInformationState from "../../models/State/DemographicInformationState";

type Action = ReturnType<typeof Actions.updateDateOfBirthAction> |
    ReturnType<typeof Actions.updateGenderAction> |
    ReturnType<typeof Actions.updateUseNicotineProductsAction> |
    ReturnType<typeof Actions.updateStateAction> |
    ReturnType<typeof Actions.updateReferrerAction> | 
    ReturnType<typeof Actions.updateEmailAction> |
    ReturnType<typeof Actions.updatePartnerSubdomainAction>;

export function updateDateOfBirthReducer(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_DATEOFBIRTH) {
        return state;
    }
    return { ...state, dateOfBirth: action.payload.dateOfBirth };
}

export function updateGenderReducer(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_GENDER) {
        return state;
    }
    return { ...state, gender: action.payload.gender };
}

export function updateEmailReducer(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_EMAIL) {
        return state;
    }
    return { ...state, email: action.payload.email };
}

export function updateUseNicotineProductsReducer(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_USENICOTINEPRODUCTS) {
        return state;
    }
    return { ...state, useNicotineProducts: action.payload.useNicotineProducts };
}

export function updateStateReducer(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_STATE) {
        return state;
    }
    let value = action.payload.state ? action.payload.state.trim() : action.payload.state;
    return { ...state, state: value };
}

export function updateReferrerReducer(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_REFERRER) {
        return state;
    }
    return { ...state, referrer: action.payload.referrer };
}

export function updatePartnerSubdomain(state: DemographicInformationState, action: Action): DemographicInformationState {
    if (action.type !== Actions.UPDATE_PARTNERSUBDOMAIN) {
        return state;
    }
    return { ...state, partnerSubdomain: action.payload.partnerSubdomain };
}

export default createReducer<DemographicInformationState, Actions.Types, Action>({ referrer: '' }, {
    [Actions.UPDATE_DATEOFBIRTH]: updateDateOfBirthReducer,
    [Actions.UPDATE_GENDER]: updateGenderReducer,
    [Actions.UPDATE_USENICOTINEPRODUCTS]: updateUseNicotineProductsReducer,
    [Actions.UPDATE_STATE]: updateStateReducer,
    [Actions.UPDATE_REFERRER]: updateReferrerReducer,
    [Actions.UPDATE_EMAIL]: updateEmailReducer,
    [Actions.UPDATE_PARTNERSUBDOMAIN]: updatePartnerSubdomain,
});