import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import AppState from "../../models/State/AppState";
import { Question, QuestionOption, FollowUpQuestion, QuestionType, PickListOption, BaseQuestion, FollowUpQuestionOption } from "../../models/Question";
import { getQuestions, answerQuestion, getSearchOptions, getFollowUpQuestions } from "../../accessors/QuoteAccessor";
import { updateCurrentQuestionAction, declinedPageAction } from "../CurrentPage/Actions";
import { createCaseAction } from "../Case/Actions";
import { isQuestionComplete } from "./Utilities";

// Action Types
export const FETCH_QUESTIONS = 'interview_questions/fetch_questions';
export const RETRIEVED_QUESTIONS = 'interview_questions/retrieved_questions';
export const ANSWER_QUESTION = 'interview_questions/answer_question';
export const ANSWER_PICKLISTQUESTION = 'interview_questions/answer_picklistquestion';
export const UPDATE_QUESTIONOPTIONS = 'interview_questions/update_questionoptions';
export const SET_FOLLOWUPQUESTION = 'interview_questions/set_followupquestion';
export const SET_FOLLOWUPQUESTIONPICKLIST = 'interview_questions/set_followupquestionpicklist';
export const SET_HASSAVEDANSWER = 'interview_questions/set_hassavedanswer';
export const STORE_FOLLOWUPQUESTION = 'interview_questions/store_followupdata';

export type Types = typeof FETCH_QUESTIONS |
    typeof RETRIEVED_QUESTIONS |
    typeof ANSWER_QUESTION |
    typeof UPDATE_QUESTIONOPTIONS |
    typeof SET_FOLLOWUPQUESTION |
    typeof SET_HASSAVEDANSWER |
    typeof STORE_FOLLOWUPQUESTION |
    typeof SET_FOLLOWUPQUESTIONPICKLIST |
    typeof ANSWER_PICKLISTQUESTION;

export interface FetchQuestionsPayload {
}

export interface RetrievedQuestionsPayload {
    questions?: BaseQuestion[];
}

export interface AnswerQuestionPayload {
    id: string;
    answer?: string;
    pickList?: PickListOption[];
    baseQuestionId: string;
}

export interface AnswerPickListQuestionPayload {
    id: string;
    answer?: string;
    baseQuestionId: string;
}

export interface SetFollowUpQuestionPayload {
    id: string;
    followUpQuestion?: Question;
}

export interface UpdateQuestionOptionsPayload {
    id: string;
    options: QuestionOption[];
}

export interface FetchFollowUpQuestionsPayload {
    followUpQuestionId: string;
}

export interface RetrievedFollowUpQuestionsPayload {
    questions: Question[];
}

export interface SubmitFollowUpQuestionAnswerPayload {
    id: string;
    value: string;
}

export interface HasSavedAnswerPayload {
    id: string;
}

export interface StoreFollowUpQuestionPayload {
    followUpId: string,
    followUpQuestion?: FollowUpQuestion
}

export interface SetFollowUpQuestionPicklistPayload {
    questionId: string;
    pickListId: string;
    followUpQuestion?: Question;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export function fetchQuestionsAction(): TypePayload<typeof FETCH_QUESTIONS, FetchQuestionsPayload> {
    return { type: FETCH_QUESTIONS, payload: {} };
}

export function retrievedQuestionsAction(questions?: BaseQuestion[]): TypePayload<typeof RETRIEVED_QUESTIONS, RetrievedQuestionsPayload> {
    return { type: RETRIEVED_QUESTIONS, payload: { questions: questions } };
}

export function answerQuestionAction(id: string, baseQuestionId: string, answer?: string, pickList?: PickListOption[]): TypePayload<typeof ANSWER_QUESTION, AnswerQuestionPayload> {
    return { type: ANSWER_QUESTION, payload: { id: id, answer: answer, pickList: pickList, baseQuestionId: baseQuestionId } };
}

export function answerPickListQuestionAction(id: string, baseQuestionId: string, answer?: string): TypePayload<typeof ANSWER_PICKLISTQUESTION, AnswerPickListQuestionPayload> {
    return { type: ANSWER_PICKLISTQUESTION, payload: { id: id, answer: answer, baseQuestionId: baseQuestionId } };
}

export function setFollowUpQuestionAction(id: string, followUpQuestion?: Question): TypePayload<typeof SET_FOLLOWUPQUESTION, SetFollowUpQuestionPayload> {
    return { type: SET_FOLLOWUPQUESTION, payload: { id: id, followUpQuestion: followUpQuestion } };
}

export function storeFollowUpQuestionAction(followUpId: string, followUpQuestion?: FollowUpQuestion): TypePayload<typeof STORE_FOLLOWUPQUESTION, StoreFollowUpQuestionPayload> {
    return { type: STORE_FOLLOWUPQUESTION, payload: { followUpId: followUpId, followUpQuestion: followUpQuestion } };
}

export function setHasSavedAnswerAction(id: string): TypePayload<typeof SET_HASSAVEDANSWER, HasSavedAnswerPayload> {
    return { type: SET_HASSAVEDANSWER, payload: { id: id } };
}

export function updateQuestionOptionsAction(id: string, options: QuestionOption[]): TypePayload<typeof UPDATE_QUESTIONOPTIONS, UpdateQuestionOptionsPayload> {
    return { type: UPDATE_QUESTIONOPTIONS, payload: { id: id, options: options } };
}

export function setFollowUpQuestionPicklistAction(questionId: string, pickListId: string, followUpQuestion: Question | undefined): TypePayload<typeof SET_FOLLOWUPQUESTIONPICKLIST, SetFollowUpQuestionPicklistPayload> {
    return { type: SET_FOLLOWUPQUESTIONPICKLIST, payload: { questionId: questionId, pickListId: pickListId, followUpQuestion: followUpQuestion } };
}

export const loadQuestionsAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        dispatch(fetchQuestionsAction());
        await dispatch(createCaseAction());

        const updatedState = getState();
        if (updatedState.case.case === undefined) {
            dispatch(retrievedQuestionsAction());
            return;
        }

        if(updatedState.case.case.isEligible){
            const questions = await getQuestions(updatedState.case.case.caseId);
            dispatch(retrievedQuestionsAction(questions));
        } else {
            dispatch(retrievedQuestionsAction());
            dispatch(declinedPageAction());
        }
        
        return dispatch(updateCurrentQuestionAction(0));
    };
}

export const searchQuestionOptionsAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (id: string, category: string, value: string) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.case.case === undefined) {
            return;
        }
        if (value.length < 3) {
            return dispatch(updateQuestionOptionsAction(id, []));
        }
        const options = await getSearchOptions(currState.case.case.caseId, category, value); //todo: remove hard coded case id
        return dispatch(updateQuestionOptionsAction(id, options));
    };
}

export const submitQuestionAnswerAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (id: string, answer?: string) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.interviewQuestions.questions === undefined || currState.case.case === undefined) {
            return;
        }
        let currQuestion = findQuestion(id, currState.interviewQuestions.questions);
        if (currQuestion === undefined) {
            return;
        }
        if (currQuestion.question.answer !== answer) {
            if (currQuestion.isFollowUp) {
                await processFollowUpQuestionAnswer(currState, currQuestion, answer, id, dispatch);
            } else {
                await processBaseQuestionAnswer(currState, currQuestion, answer, id, currState.case.case.caseId, dispatch, getState);
            }
        }
    };
}

export const setPickListAnswerAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (questionId: string, pickListId: string, answer?: string) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.interviewQuestions.questions === undefined || currState.case.case === undefined) {
            return;
        }
        let currQuestion = findQuestion(questionId, currState.interviewQuestions.questions);
        if (currQuestion === undefined) {
            return;
        }

        dispatch(answerPickListQuestionAction(pickListId, questionId, answer));

        if (answer === "true") {
            dispatch(setFollowUpQuestionPicklistAction(questionId, pickListId, await processFollowUpQuestion(questionId, pickListId, currState, currState.case.case.caseId, dispatch, getState)));
        } else {
            dispatch(setFollowUpQuestionPicklistAction(questionId, pickListId, undefined));
        }
    };
}

export const saveQuestionAnswerAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (question: BaseQuestion) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.case.case === undefined) {
            return;
        }
        if (question !== undefined && isQuestionComplete(question) && !question.hasSavedAnswer) {
            await answerQuestion(currState.case.case.caseId, question);
            dispatch(setHasSavedAnswerAction(question.id));
        }

    };
}

type CurrentQuestion = {
    question: Question,
    isFollowUp: boolean,
    baseQuestionId: string
}

const processBaseQuestionAnswer = async (currState: AppState, currQuestion: CurrentQuestion, answer: string | undefined, id: string, caseId: string, dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<void> => {
    let selectedValue = currQuestion.question.options.find(a => a.value === answer);
    if (answer !== undefined && selectedValue === undefined) {
        return;
    }
    let pickList = selectedValue === undefined ? undefined : selectedValue.pickList;
    if (pickList !== undefined) {
        pickList.forEach(a => {
            if (a.answer === undefined) {
                a.answer = "false";
            }
            if (a.followUpQuestionRetrieved === undefined) {
                a.followUpQuestionRetrieved = false;
            }
        });
    }

    dispatch(answerQuestionAction(id, id, answer, pickList));

    let followUpQuestion: Question | undefined
    let followUpId = selectedValue === undefined ? undefined : selectedValue.followUpId;
    followUpQuestion = await processFollowUpQuestion(id, followUpId, currState, caseId, dispatch, getState);

    dispatch(setFollowUpQuestionAction(id, followUpQuestion));
}

const processFollowUpQuestionAnswer = async (currState: AppState, currQuestion: CurrentQuestion, answer: string | undefined, id: string, dispatch: ThunkDispatch<AppState, null, AnyAction>): Promise<void> => {
    let currFollowUpQuestion = currState.interviewQuestions.flatFollowUpQuestionCache[id];
    if (currFollowUpQuestion === undefined) {
        return;
    }
    let selectedValue = findMatchingSelectedValue(currFollowUpQuestion, answer);
    if (!selectedValue.canSetValue) {
        return;
    }

    let nextFollowUpQuestion = selectedValue.option === undefined ? undefined : selectedValue.option.followUpQuestion;

    dispatch(answerQuestionAction(id, currQuestion.baseQuestionId, answer));

    let followUpQuestion = convertFollowUpQuestion(nextFollowUpQuestion);
    if(currQuestion.question.followUpQuestion !== undefined && followUpQuestion !== undefined && currQuestion.question.followUpQuestion.id === followUpQuestion.id){
        dispatch(setFollowUpQuestionAction(id, currQuestion.question.followUpQuestion));
    } else {
        dispatch(setFollowUpQuestionAction(id, followUpQuestion));
    }
    
}

export const submitQuestionAnswersAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (answers: { id: string, answer?: string }[]) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.interviewQuestions.questions === undefined || currState.case.case === undefined) {
            return;
        }
        let allQuestions = currState.interviewQuestions.questions;
        let caseId = currState.case.case.caseId;
        let updates = new Array<{ questionId: string, answer: string | undefined, followUpPromise: Promise<Question | undefined> | undefined }>();
        answers.forEach(a => {
            let currQuestion = findQuestion(a.id, allQuestions);
            if (currQuestion === undefined) {
                return;
            }
            if (currQuestion.question.answer !== a.answer) {
                let selectedValue = currQuestion.question.options.find(b => b.value === a.answer);
                if (a.answer !== undefined && selectedValue === undefined) {
                    return;
                }
                dispatch(answerQuestionAction(a.id, currQuestion.baseQuestionId, a.answer));

                let followUpId = selectedValue === undefined ? undefined : selectedValue.followUpId;
                let followUpPromise = processFollowUpQuestion(a.id, followUpId, currState, caseId, dispatch, getState);

                updates.push({ questionId: a.id, answer: a.answer, followUpPromise: followUpPromise })
            }
        });
        updates.forEach(async a => {
            dispatch(setFollowUpQuestionAction(a.questionId, await a.followUpPromise))
        })
    };
}

const findQuestion = (id: string, allQuestions: Question[] | undefined): CurrentQuestion | undefined => {
    if (allQuestions === undefined) {
        return undefined;
    }
    return allQuestions.map(a => findSubQuestion(id, a, false, a.id)).find(a => a !== undefined);
}

const findSubQuestion = (id: string, question: Question | undefined, isFollowUp: boolean, baseQuestionId: string): CurrentQuestion | undefined => {
    if (question === undefined) {
        return undefined;
    }
    if (question.id === id) {
        return { question: question, isFollowUp: isFollowUp, baseQuestionId: baseQuestionId };
    }
    if (question.subQuestions !== undefined) {
        let match = question.subQuestions.map(a => findSubQuestion(id, a, isFollowUp, baseQuestionId)).find(a => a !== undefined);
        if (match !== undefined) {
            return match;
        }
    }
    if (question.followUpQuestion !== undefined) {
        let match = findSubQuestion(id, question.followUpQuestion, true, baseQuestionId);
        if (match !== undefined) {
            return match;
        }
    }
    if (question.pickList !== undefined) {
        let match = question.pickList.map(a => findSubQuestion(id, a.followUpQuestion, true, baseQuestionId)).find(a => a !== undefined);
        if (match !== undefined) {
            return match;
        }
    }
    return undefined;
}

const findMatchingSelectedValue = (currFollowUpQuestion: FollowUpQuestion, answer: string | undefined): { option: FollowUpQuestionOption | undefined, canSetValue: boolean } => {
    if (currFollowUpQuestion.type === QuestionType.Text || answer === undefined) {
        return { option: undefined, canSetValue: true };
    }
    if (currFollowUpQuestion.type === QuestionType.Numeric) {
        let intAnswer = parseInt(answer);
        for(let i=currFollowUpQuestion.options.length - 1; i>=0; i--){
            let currOption = currFollowUpQuestion.options[i];
            if(intAnswer >= parseInt(currOption.value)){
                return { option: currOption, canSetValue: true }
            }
        }
        return { option: undefined, canSetValue: false }
    }
    let foundOption = currFollowUpQuestion.options.find(a => a.value === answer);
    return { option: foundOption, canSetValue: foundOption !== undefined };
}

async function processFollowUpQuestion(baseQuestionId: string, followUpId: string | undefined, currState: AppState, caseId: string, dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<Question | undefined> {
    let followUpQuestion: Question | undefined;
    if (followUpId !== undefined) {
        let followUp: FollowUpQuestion | undefined;
        followUp = currState.interviewQuestions.followUpQuestionCache[followUpId];
        if (followUp === undefined) {
            let retrievedFollowUps = await getFollowUpQuestions(caseId, baseQuestionId, followUpId);
            await dispatch(storeFollowUpQuestionAction(followUpId, retrievedFollowUps));
        }
        currState = getState();
        followUp = currState.interviewQuestions.followUpQuestionCache[followUpId];
        followUpQuestion = convertFollowUpQuestion(followUp);
    }
    return followUpQuestion;
}

const convertFollowUpQuestion = (followUp: FollowUpQuestion | undefined): Question | undefined => {
    let followUpQuestion: Question | undefined;

    if (followUp !== undefined) {
        followUpQuestion = {
            id: followUp.id,
            type: followUp.type,
            options: followUp.options.map(a => {
                return {
                    value: a.value,
                    text: a.text,
                    followUpId: a.followUpQuestion === undefined ? undefined : a.followUpQuestion.id
                };
            }),
            text: followUp.text,
            followUpQuestionRetrieved: false
        };
    }

    return followUpQuestion;
}
