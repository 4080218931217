import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import PolicyState from "../../models/State/PolicyState";

type Action = ReturnType<typeof Actions.loadingSubmitApplicationAction> |
              ReturnType<typeof Actions.receivedApplicationAction>;

export function loadingSubmitApplicationReducer(state: PolicyState, action: Action): PolicyState {
    if (action.type !== Actions.SUBMIT_APPLICATION) {
        return state;
    }
    return { ...state, isLoadingApplication: true };
}

export function receivedApplicationReducer(state: PolicyState, action: Action): PolicyState {
    if (action.type !== Actions.RECEIVED_APPLICATION) {
        return state;
    }
    return { ...state, policyNumber: action.payload.policyNumber, isLoadingApplication: false };
}

export default createReducer<PolicyState, Actions.Types, Action>({ isLoadingApplication: false }, {
    [Actions.SUBMIT_APPLICATION]: loadingSubmitApplicationReducer,
    [Actions.RECEIVED_APPLICATION]: receivedApplicationReducer
});