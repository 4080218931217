import { Validation, DemographicInformationValidation } from "../../models/State/ValidationState";
import AppState from "../../models/State/AppState";
import moment from 'moment';

function isDateOfBirthValid(currState: AppState) {
    if (currState.demographicInformation.dateOfBirth !== undefined) {
        if (currState.demographicInformation.dateOfBirth > moment().toDate()) {
            return "Please enter a valid date of birth (Date of birth cannot be a future date)"
        }
        if (currState.demographicInformation.dateOfBirth > moment().subtract(18, 'years').toDate() ||
            currState.demographicInformation.dateOfBirth < moment().subtract(70, 'years').toDate()) {
            return "Please enter a valid date of birth (Policy is available from ages 18 through 70)";
        }
        if (!moment(currState.demographicInformation.dateOfBirth).isValid()) {
            return "Please enter a valid date of birth.";
        }
    } else {
        return "Please enter a valid date of birth (Date of birth cannot be undefined)";
    }

    return "";
}

function isGenderValid(currState: AppState) {
    return currState.demographicInformation.gender !== undefined;
}

function isNicotineProductsValid(currState: AppState) {
    return currState.demographicInformation.useNicotineProducts !== undefined;
}

function isStateValid(currState: AppState) {
    if (currState.availableStates.availableStates === undefined) {
        return false;
    }
    return currState.demographicInformation.state !== undefined &&
        currState.availableStates.availableStates.some(a => a.value === currState.demographicInformation.state);
}

function isStateAvailable(currState: AppState) {
    if (currState.availableStates.availableStates === undefined || currState.availableStates.availableStates === undefined || currState.demographicInformation.state === undefined) {
        return true;
    }
    return currState.availableStates.availableStates.some(a => a.value === currState.demographicInformation.state && a.isAvailable);
}

export default function demographicInformationReducer(currState: AppState): Validation<DemographicInformationValidation> {
    let fields: DemographicInformationValidation = {
        dateOfBirthValidation: isDateOfBirthValid(currState),
        isGenderValid: isGenderValid(currState),
        isNicotineProductsValid: isNicotineProductsValid(currState),
        isStateValid: isStateValid(currState),
        isStateAvailable: isStateAvailable(currState)
    }
    return {
        overall: !fields.dateOfBirthValidation &&
            fields.isGenderValid &&
            fields.isNicotineProductsValid &&
            fields.isStateValid,
        fields: fields
    }
}