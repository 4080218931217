import React from 'react';

import bars from './bars.svg';

interface LoadingDataProps{
  heading: string;
  subheading: string;
}

export class LoadingData extends React.Component<LoadingDataProps>{
  static defaultProps = { heading: "Loading", subheading: "just one moment"};
  render() {
    return (
          <div className="loading-data-overlay">
              <img src={bars} className="mb-2" alt="loading data" />
              <span className="loading-heading mb-1">{this.props.heading}</span>
              <span className="loading-subheading">{this.props.subheading}</span>
          </div>
    );
  }
}

export default LoadingData;
