import { Validation, SelectedAmountValidation } from "../../models/State/ValidationState";
import AppState from "../../models/State/AppState";


function isSelectedAmountValid(currState: AppState): boolean {
    if (currState.benefitAmount.availableAmounts === undefined) {
        return false;
    }
    if (currState.benefitAmount.selectedAmount === undefined) {
        return false;
    }
    var benefitAmount = currState.benefitAmount.selectedAmount.benefitAmount;
    return currState.benefitAmount.availableAmounts.some(a => a.benefitAmount === benefitAmount)
}

export default function selectedAmount(currState: AppState): Validation<SelectedAmountValidation> {
    let fields: SelectedAmountValidation = {
        isSelectedAmountValid: isSelectedAmountValid(currState)
    }
    return {
        overall: fields.isSelectedAmountValid,
        fields: fields
    }
}