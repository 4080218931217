import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { ThunkDispatch } from 'redux-thunk';
import { formatNumber } from '../PageUtilities';
import { updateCardFieldAction, submitCreditCardAction } from '../../redux/Payment/Actions';
import AppState from '../../models/State/AppState';

const mapStateToProps = (state: AppState) => {
  return {
    benefitAmount: (state.benefitAmount.selectedAmount && state.benefitAmount.selectedAmount.benefitAmount) || 0,
    cost: (state.benefitAmount.selectedAmount && state.benefitAmount.selectedAmount.cost) || 0,
    canSubmit: state.validation.payment.fields.isPaymentInformationValid
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    updateCardFields: (stripeProps: stripe.elements.ElementChangeResponse) => dispatch(updateCardFieldAction(stripeProps)),
    submitCreditCard: (stripeProps?: ReactStripeElements.StripeProps) => dispatch(submitCreditCardAction(stripeProps)),
  };
};

interface PaymentsComponentProps {
  stripe?: ReactStripeElements.StripeProps;
  benefitAmount: number;
  cost: number;
  canSubmit: boolean;
  updateCardFields: (stripeProps: stripe.elements.ElementChangeResponse) => void;
  submitCreditCard: (stripeProps?: ReactStripeElements.StripeProps) => void;
}

export class PaymentsComponent extends React.Component<PaymentsComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  private updateCardFields = (e: stripe.elements.ElementChangeResponse) => {
    this.props.updateCardFields(e)
  }
  private submitCreditCard = () => {
    this.props.submitCreditCard(this.props.stripe);
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-12 mb-2">
            You are purchasing a ${formatNumber(this.props.benefitAmount)} Critical Illness Insurance Policy for ${this.props.cost.toFixed(2)} per month.
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-2">
            <CardElement className="form-control" onChange={this.updateCardFields} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-2">
            <button className="btn btn-primary btn-block" onClick={this.submitCreditCard} disabled={!this.props.canSubmit}>Submit</button>
          </div>
        </div>
      </>
    );
  }
}

export default injectStripe(connect(mapStateToProps, mapDispatchToProps)(PaymentsComponent));
