import { getSettings } from "../Settings";
import Gender from "../models/Gender";
import Case from "../models/Case";
import CaseResult from "../models/CaseResult";
import { processRequest } from "./AccessorUtilities";

const createCaseUrl: string = "/api/quote/createcase.json";
const getcasedecisionUrl: string = "/api/quote/getcasedecision.json";

export async function createCase(caseRequest: CaseRequest) {

    let settings = getSettings();
    var url = new URL(createCaseUrl, settings.apiHost);
    url.searchParams.append("code", settings.apiKey);
    
    const response = await post(url.toString(), {
        benefitAmount: caseRequest.benefitAmount,
        streetAddress: caseRequest.streetAddress,
        city: caseRequest.city,
        state: caseRequest.state,
        zip: caseRequest.zip,
        firstName: caseRequest.firstName,
        lastName: caseRequest.lastName,
        gender: caseRequest.gender,
        dateOfBirth: caseRequest.dateOfBirth,
        heightFeet: caseRequest.heightFeet,
        heightInches: caseRequest.heightInches,
        weightPounds: caseRequest.weightPounds,
        birthState: caseRequest.birthState,
        birthCountry: caseRequest.birthCountry,
        phoneNumber: caseRequest.phoneNumber.replace(/\D/g, ''),
        socialSecurityNumber: caseRequest.SSN,
        isCitizen: caseRequest.isCitizen,
        tobaccoUses: caseRequest.tobaccoUses,
        tobaccoType: caseRequest.tobaccoType,
        tobaccoDailyUseCount: caseRequest.tobaccoDailyUseCount,
        tobaccoMonthlyUseCount: caseRequest.tobaccoMonthlyUseCount,
        tobaccoLastUseDate: caseRequest.tobaccoLastUseDate,
        quotedUsesTobacco: caseRequest.quotedUsesTobacco,
        isPermanentResident: !caseRequest.isCitizen,
        greenCard: caseRequest.greenCard,
        hasExistingCoverage: caseRequest.hasExistingCoverage,
        hasComprehensiveHealthcare: caseRequest.hasComprehensiveHealthcare,
        code: settings.apiKey,
        referrer: caseRequest.referrer
    }).catch(error => console.error(error));

    return response as Case;
}

export async function getCaseResult(caseId: string, applicationAlreadySubmitted: boolean): Promise<CaseResult> {
    let settings = getSettings();
    var url = new URL(getcasedecisionUrl, settings.apiHost);
    url.searchParams.append("case", caseId);
    url.searchParams.append("applicationAlreadySubmitted", String(applicationAlreadySubmitted));
    url.searchParams.append("code", settings.apiKey);
    
    let fetchResult = await processRequest(url.href);
    let result = await fetchResult.json() as CaseResultResponse;
    return result.caseResult;
}

type CaseResultResponse = {
    caseResult: CaseResult
}

async function post(url = '', data = {}) {
    return await processRequest(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(data),
    })
        .then(response => response.json());
}

export interface CaseRequest {
    quotedUsesTobacco: boolean;
    benefitAmount: number;
    streetAddress: string;
    streetAddress2?: string;
    city: string;
    state: string;
    zip: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    dateOfBirth: Date;
    heightFeet: number;
    heightInches: number;
    weightPounds: number;
    birthState?: string;
    birthCountry: string;
    phoneNumber: string;
    SSN: string;
    isCitizen: boolean;
    tobaccoUses: boolean;
    tobaccoType?: string;
    tobaccoDailyUseCount?: number;
    tobaccoMonthlyUseCount?: number;
    tobaccoLastUseDate?: Date;
    greenCard: string | undefined;
    hasExistingCoverage: boolean;
    hasComprehensiveHealthcare?: boolean;
    referrer: string;
}