import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';

import { sendConfirmationEmail } from '../../redux/Approved/Actions';
import { Dictionary } from '../../models/State/BrandState';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';
import CaseResult from '../../models/CaseResult';

const mapStateToProps = (state: AppState) => {
    return {
        isFinalApproved: state.case.caseResult === CaseResult.Approved,        
        emailAddress: state.personalInformation.emailAddress,
        policyNumber: state.policy.policyNumber,
        links: state.brand.links
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        sendConfirmationEmail: (isFinalApproved: boolean) => dispatch(sendConfirmationEmail(isFinalApproved))
    };
};

interface FinalApprovedComponentProps {
    isFinalApproved: boolean;
    emailAddress?: string;
    policyNumber?: number;
    sendConfirmationEmail: (isFinalApproved: boolean) => void;
    links?: Dictionary;
}

class FinalApproved extends React.Component<FinalApprovedComponentProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.sendConfirmationEmail(this.props.isFinalApproved);
    }

    private getHomepageAddress = (): string | undefined => {
        if (this.props.links !== undefined && this.props.links["homepage"] !== undefined) {
            return this.props.links["homepage"];
        }
        return undefined;
    }

    render() {
        return (
            <>
                <div className="p-3">
                    <div className="row mb-3">
                        {this.props.isFinalApproved ?
                            <>
                            <h1 className="status-header-text">Your application has been submitted to Assurity.</h1>
                                <p>Your policy number is {this.props.policyNumber}.</p>
                                <p>Your policy will be issued and mailed to you in the next 2-4 business days.</p>
                                <p>No coverage is effective until the first full premium is paid and payment is honored.</p>
                                <p>A confirmation email has been sent to <strong>{this.props.emailAddress}</strong>.</p>                                
                            </> : <>
                            <h1 className="status-header-text">Thank you for applying!</h1>
                                <p>Additional information is needed to make a decision on this application. Upon signature and submission, the application will be referred to Underwriting for review.</p>
                                <p>Your policy number is {this.props.policyNumber}. </p>
                                <p>We will reach out to you shortly with next steps.</p>
                                <p>A confirmation email has been sent to <strong>{this.props.emailAddress}</strong>.</p>
                            </>
                        }
                    </div>
                    <div className="row">
                        <div className="">
                            <a className="btn btn-primary btn-block" href={this.getHomepageAddress()}>Done</a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(FinalApproved));