export interface ISettings{
    apiHost: string;
    aiInstrumentationKey: string;
    gaKey: string;
    apiKey: string;
    stripeApiKey: string;
}

export function getSettings(): ISettings {
    return JSON.parse(
        (document.getElementById('appSettings') as HTMLScriptElement).text
    );
}