import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import AppState from "../../models/State/AppState";
import { submitApplication } from "../../accessors/ApplicationAccessor";

export const SUBMIT_APPLICATION = 'case/post_application';
export const RECEIVED_APPLICATION = 'case/received_application';
export const UPDATE_CASERESULT = 'case/update_caseresult';

export type Types = typeof SUBMIT_APPLICATION |
                    typeof RECEIVED_APPLICATION;

export interface SubmitApplicationPayload{
}

export interface ReceivedApplicationPayload{
    policyNumber: number;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export const submitApplicationAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: ()=>AppState): Promise<any> => {
        const currState = getState();
        if(currState.policy.policyNumber === undefined){
            if(currState.case.case === undefined ||
               currState.case.case.caseId === undefined ||
               currState.document.envelopeId === undefined ||
               currState.payment.token === undefined ||
               currState.benefitAmount.selectedAmount === undefined)
            {
                throw new Error("Not enough data to submit an application.");
            }
            dispatch(loadingSubmitApplicationAction());
            
            const applicationResult = await submitApplication({
                caseId: currState.case.case.caseId,
                envelopeId: currState.document.envelopeId, 
                paymentId: currState.payment.token,
                benefitAmount: currState.benefitAmount.selectedAmount.benefitAmount,
                referrer: currState.demographicInformation.referrer                
            });

            currState.case.applicationSubmitted = true;            
            currState.case.caseResult = undefined;

            return dispatch(receivedApplicationAction(applicationResult.policyNumber));
        }
    };
}

export function loadingSubmitApplicationAction(): TypePayload<typeof SUBMIT_APPLICATION, SubmitApplicationPayload> {
    return {type: SUBMIT_APPLICATION, payload: {}};
}

export function receivedApplicationAction( applicationResult: number ): TypePayload<typeof RECEIVED_APPLICATION, ReceivedApplicationPayload> {
    return {type: RECEIVED_APPLICATION, payload: { policyNumber: applicationResult }};
}