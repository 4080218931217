import React from 'react';
import { connect } from "react-redux";
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';

import './Terms'
import { setupApplicationInsightsPageTracking } from '../PageUtilities';
import AppState from '../../models/State/AppState';

const mapStateToProps = (state: AppState) => {
    return {
        state: state.demographicInformation.state
    };
};

interface FullTermsProps {
    state?: string;
}

class FullTerms extends React.Component<FullTermsProps> {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private getPdfName = ():string => {
        switch (this.props.state) {
            case "AZ":
                return "/AZ Prenotice.pdf";
            case "CA":
                return "/CA Prenotice.pdf";
            case "ME":
                return "/ME Prenotice.pdf";
            case "MN":
                return "/MN Prenotice.pdf";
            case "NC":
                return "/NC Prenotice.pdf";
            case "VA":
                return "/VA Prenotice.pdf";
            case "VT":
                return "/VT Prenotice.pdf";
        }
        return "/Generic Prenotice.pdf";
    }

    render() {
        return (
            <>
                <Card title="Confidential Information Authorization">
                    <div className="row">
                        <div className="embed-responsive embed-responsive-1by1 full-screen-object">
                            <object className="embed-responsive-item" data={this.getPdfName()} type="application/pdf" title="">
                                <p>Your browser isn't supporting embedded pdf files. You can download the file <a href={this.getPdfName()} download>here</a>.</p>
                            </object>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps)(setupApplicationInsightsPageTracking(FullTerms));