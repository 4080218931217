import { getSettings } from "../Settings";
import CurrentPage from "../models/CurrentPage";
import PageOrderData from "../models/PageOrderData";
import { getBrandName } from "../UriUtilities";
import { Dictionary } from "../models/State/BrandState";
import { processRequest } from "./AccessorUtilities";

const screenFlowUrl: string = "/api/branding/screenflow.json";

export async function getPageOrder(): Promise<PageOrderData[]> {
    let brandName = getBrandName();
    if (brandName !== undefined) {
        let settings = getSettings();        
        var url = new URL(screenFlowUrl, settings.apiHost);
        url.searchParams.append("brandName", brandName);
        url.searchParams.append("code", settings.apiKey);

        let fetchResult = await processRequest(url.href);

        let result = await fetchResult.json();

        if (result) {
            return result;
        }
    }

    return [
        { page: CurrentPage.State, isRequired: true },
        { page: CurrentPage.Gender, isRequired: true },
        { page: CurrentPage.NicotineProducts, isRequired: true },
        { page: CurrentPage.DateOfBirth, isRequired: true },
        { page: CurrentPage.BenefitSelection, isRequired: true },
        { page: CurrentPage.Terms, isRequired: true },
        { page: CurrentPage.UserIdentification, isRequired: true },
        { page: CurrentPage.Citizenship, isRequired: true },
        { page: CurrentPage.Birthstate, isRequired: true },
        { page: CurrentPage.PermanentStatus, isRequired: true },
        { page: CurrentPage.Residence, isRequired: true },
        { page: CurrentPage.Phone, isRequired: true },
        { page: CurrentPage.SocialSecurityNumber, isRequired: true },
        { page: CurrentPage.NicotineDetails, isRequired: true },
        { page: CurrentPage.Body, isRequired: true },
        { page: CurrentPage.ExistingCoverage, isRequired: true },
        { page: CurrentPage.ComprehensiveHealthcare, isRequired: true }, 
        { page: CurrentPage.InterviewQuestions, isRequired: true },
        { page: CurrentPage.CaseDecisionDetermination, isRequired: true },
        { page: CurrentPage.TentativeApproved, isRequired: true },
        { page: CurrentPage.TentativeSentToUnderwriting, isRequired: true },
        { page: CurrentPage.Payment, isRequired: true },
        { page: CurrentPage.SignDocuments, isRequired: true },
        { page: CurrentPage.SubmittingApplication, isRequired: true },
        { page: CurrentPage.FinalApproved, isRequired: true }
    ];
}

const linksUrl: string = "/api/branding/links.json";
const defaultLinks: Dictionary = {
    "headerImage": "/logo-prepare-insurance.png",
    "homepage": "https://prepareinsure.com"
};
export async function getLinks(): Promise<Dictionary> {
    let brandName = getBrandName();
    if (brandName !== undefined) {
        let settings = getSettings();
        var url = new URL(linksUrl, settings.apiHost);
        url.searchParams.append("brandName", brandName);
        url.searchParams.append("code", settings.apiKey);

        let fetchResult = await processRequest(url.href);

        let result = await fetchResult.json() as { key: string, value: string }[];
        let retrievedLinks: Dictionary = {};
        result.forEach(a => {
            retrievedLinks[a.key] = a.value
        })
        return Object.assign({}, defaultLinks, retrievedLinks);
    }

    return defaultLinks;
}