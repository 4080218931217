enum CurrentPage {
    Gender = 1,
    NicotineProducts = 2,
    DateOfBirth = 3,
    State = 4,
    BenefitSelection = 5,
    Terms = 6,
    FullTerms = 7,
    UserIdentification = 8,
    Citizenship = 9,
    Birthstate = 10,
    PermanentStatus = 11,
    Residence = 12,
    Phone = 13,
    SocialSecurityNumber = 14,
    Body = 15,
    SeeDetails = 16,
    FullDetails = 17,
    InterviewQuestions = 18,
    SignDocuments = 19,
    CaseDecisionDetermination = 20,
    TentativeApproved = 21, 
    SubmittingApplication = 22,
    NicotineDetails = 23,
    ExistingCoverage = 24,
    ComprehensiveHealthcare = 25,
    TentativeSentToUnderwriting = 26,    
    Payment = 500,
    Declined = 990,
    FinalApproved = 999
}
export default CurrentPage;