import { getSettings } from "../Settings";
import { processRequest } from "./AccessorUtilities";

const getSigningUrlUrl: string = "api/forms/signingurl.json";

export const getSigningUrl = async (caseId: string, applicantFirstName: string, applicantLastName: string, applicantEmail: string, benefitAmount: number, cardType: string, referrer: string): Promise<{ url: string, envelopeId: string }> => {
    let settings = getSettings();
    var url = new URL(getSigningUrlUrl, settings.apiHost);
    var brandUrl = new URL("/blank.html", window.location.origin);
    url.searchParams.append("caseId", caseId);
    url.searchParams.append("applicantName", `${applicantFirstName} ${applicantLastName}`);
    url.searchParams.append("applicantEmail", applicantEmail);
    url.searchParams.append("benefitAmount", benefitAmount.toString());
    url.searchParams.append("cardType", cardType);
    url.searchParams.append("brandUrl", brandUrl.href);
    url.searchParams.append("code", settings.apiKey);
    url.searchParams.append("referrer", referrer);

    let fetchResult = await processRequest(url.href);

    return await fetchResult.json();
}