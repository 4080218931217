import React from 'react';
import { connect } from "react-redux";
import { updateUsersFirstNameAction, updateUsersLastNameAction, updateEmailAddressAction } from '../../redux/PersonalInformation/Actions';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import AppState from '../../models/State/AppState';
import Card from '../../components/Card/Card';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import { setupApplicationInsightsPageTracking } from '../PageUtilities';

const mapStateToProps = (state: AppState) => {
    return {
        usersFirstName: state.personalInformation.usersFirstName,
        usersLastName: state.personalInformation.usersLastName,
        emailAddress: state.personalInformation.emailAddress
    };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
    return {
        updateUsersFirstName: (value: string | undefined) => dispatch(updateUsersFirstNameAction(value)),
        updateUsersLastName: (value: string | undefined) => dispatch(updateUsersLastNameAction(value)),
        updateEmailAddress: (value: string | undefined) => dispatch(updateEmailAddressAction(value))
    };
};

interface UserIdentificationComponentProps {
    usersFirstName?: string;
    usersLastName?: string;
    emailAddress?: string;
    updateUsersFirstName: (usersFirstName: string) => void;
    updateUsersLastName: (usersFirstName: string) => void;
    updateEmailAddress: (userName: string) => void;
}

export class UserIdentificationFieldsComponent extends React.Component<UserIdentificationComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private updateUsersFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateUsersFirstName(e.target.value);
    }
    private updateUsersLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateUsersLastName(e.target.value);
    }
    private updateEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateEmailAddress(e.target.value);
    }
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="usersFirstName">First Name</label>
                            <input type="text" autoComplete="given-name" required className="form-control" id="usersFirstName" onChange={this.updateUsersFirstName} value={this.props.usersFirstName ? this.props.usersFirstName : ""} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="usersLastName">Last Name</label>
                            <input type="text" autoComplete="family-name" required className="form-control" id="usersLastName" onChange={this.updateUsersLastName} value={this.props.usersLastName ? this.props.usersLastName : ""} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" autoComplete="email" required className="form-control" id="email" onChange={this.updateEmailAddress} value={this.props.emailAddress ? this.props.emailAddress : ""} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export class UserIdentificationComponent extends React.Component<UserIdentificationComponentProps>{
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    private updateUsersFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateUsersFirstName(e.target.value);
    }
    private updateUsersLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateUsersLastName(e.target.value);
    }
    private updateEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.updateEmailAddress(e.target.value);
    }
    render() {
        return (
            <>
                <Card title="Let's get to know you better!">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="usersFirstName">First Name</label>
                                <input type="text" autoComplete="given-name" required className="form-control" id="usersFirstName" onChange={this.updateUsersFirstName} value={this.props.usersFirstName ? this.props.usersFirstName : ""} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="usersLastName">Last Name</label>
                                <input type="text" autoComplete="family-name" required className="form-control" id="usersLastName" onChange={this.updateUsersLastName} value={this.props.usersLastName ? this.props.usersLastName : ""} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input type="email" autoComplete="email" required className="form-control" id="email" onChange={this.updateEmailAddress} value={this.props.emailAddress ? this.props.emailAddress : ""} />
                            </div>
                        </div>
                    </div>
                </Card>
                <NavigationButtons />
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(setupApplicationInsightsPageTracking(UserIdentificationComponent));