import * as Actions from './Actions';
import BrandState from "../../models/State/BrandState";
import { createReducer } from '../ReducersUtilities';

type Action = ReturnType<typeof Actions.fetchBrandLinksAction> |
    ReturnType<typeof Actions.receivedBrandLinksAction>;

const fetchBrandLinksReducer = (state: BrandState, action: Action): BrandState => {
    if (action.type !== Actions.FETCH_BRANDLINKS) {
        return state;
    }

    return { ...state, isLoadingLinks: true };
}

const receivedBrandLinksReducer = (state: BrandState, action: Action): BrandState => {
    if (action.type !== Actions.RECEIVED_BRANDLINKS) {
        return state;
    }

    return { ...state, links: action.payload.links, isLoadingLinks: false, };
}

export default createReducer<BrandState, Actions.Types, Action>({ isLoadingLinks: false }, {
    [Actions.FETCH_BRANDLINKS]: fetchBrandLinksReducer,
    [Actions.RECEIVED_BRANDLINKS]: receivedBrandLinksReducer
});