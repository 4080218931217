import React from 'react';
import { connect } from "react-redux";
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Question } from '../../models/Question';
import { searchQuestionOptionsAction, submitQuestionAnswerAction } from '../../redux/InterviewQuestions/Actions';
import Select from 'react-select';
import { ValueType, InputActionMeta } from 'react-select/src/types';


const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    answerQuestion: (id: string, value: string) => dispatch(submitQuestionAnswerAction(id, value)),
    searchQuestionAnswers: (id: string, category: string, value: string) => dispatch(searchQuestionOptionsAction(id, category, value))
  };
};

interface SearchQuestionComponentProps {
  question: Question;
  answerQuestion: (id: string, value: string) => void;
  searchQuestionAnswers: (id: string, category: string, value: string) => void;
}
class SearchQuestionComponent extends React.Component<SearchQuestionComponentProps>{
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  private search = (value: string, actionMeta: InputActionMeta): void =>{
    if(actionMeta.action === "input-change" && this.props.question.searchCategoryId !== undefined){
      this.props.searchQuestionAnswers(this.props.question.id, this.props.question.searchCategoryId,  value);
    }
  }
  private answer = (changeValue: ValueType<{value: string, label: string}>): void =>{
    this.props.answerQuestion(this.props.question.id, (changeValue as {value: string, label: string}).value );
  }
  render() {
    let values = this.props.question.options.map(a=>({value: a.value, label: a.text}));

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <Select placeholder="Start typing to search" value={values.find(a=>a.value === this.props.question.answer)} options={values} isMulti={false} onInputChange={this.search} onChange={this.answer} >
              </Select>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(SearchQuestionComponent);