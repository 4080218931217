import React from 'react';
import { AnyAction } from 'redux';
import { connect } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';

import CurrentPage from './models/CurrentPage';
import AppState from './models/State/AppState';
import Gender from './pages/DemographicInformation/Gender';
import UseNicotineProducts from './pages/DemographicInformation/UseNicotineProducts';
import DateOfBirth from './pages/DemographicInformation/DateOfBirth';
import State from './pages/DemographicInformation/State';
import BenefitSelection from './pages/BenefitAmount/BenefitSelection';
import Terms from './pages/Terms/Terms';
import FullTerms from './pages/Terms/FullTerms';
import FullDetails from './pages/BenefitAmount/FullDetails';
import UserIdentification from './pages/PersonalInformation/UserIdentification';
import Citizenship from './pages/PersonalInformation/Citizenship';
import { loadPageOrderAction } from './redux/CurrentPage/Actions';
import BirthLocation from './pages/PersonalInformation/BirthLocation';
import PermanentResident from './pages/PersonalInformation/PermanentResident';
import Declined from './pages/Status/Declined';
import FinalApproved from './pages/Status/FinalApproved';
import SignDocuments from './pages/Documents/SignDocuments';
import Modal from 'react-bootstrap/Modal';

import './App.scss';
import Residence from './pages/PersonalInformation/Residence';
import PhoneNumber from './pages/PersonalInformation/PhoneNumber';
import SocialSecurityNumber from './pages/PersonalInformation/SocialSecurityNumber';
import Body from './pages/PersonalInformation/Body';
import Payments from './pages/Payments/Payments';
import LoadingData from './components/Loading/LoadingData';
import ProgressBar from './components/Progress/ProgressBar';
import Questions from './pages/InterviewQuestions/Questions';
import TentativeApproved from './pages/Status/TentativeApproved';
import CaseDecisionDetermination from './pages/InterviewQuestions/CaseDecisionDetermination';
import { loadLinksAction } from './redux/Brand/Actions';

import { Dictionary } from './models/State/BrandState';
import SubmittingApplication from './pages/Status/SubmittingApplication';
import { CSSTransition } from 'react-transition-group';
import { LastPageMovement } from './models/State/CurrentPageState';
import PageTransition from './components/PageTransition';

import FAQLogo from './images/FAQ.png';
import NicotineDetails from './pages/PersonalInformation/NicotineDetails';
import ExistingCoverage from './pages/PersonalInformation/ExistingCoverage';
import TentativeSentToUnderwriting from './pages/Status/TentativeSentToUnderwriting';
import ComprehensiveHealthcare from './pages/PersonalInformation/ComprehensiveHealthcare';

const mapStateToProps = (state: AppState) => {
  let loading: LoadingInformation | undefined = undefined;
  if (state.currentPage.isLoadingPageOrderData) {
    loading = {
      heading: "Setting up Critical Illness application"
    }
  } else if (state.benefitAmount.isLoadingAvailableAmounts) {
    loading = {
      heading: "Preparing your personalized quote"
    }
  } else if (state.availableStates.isLoadingAvailableStates) {
    loading = {
      heading: "Accessing state data"
    }
  } else if (state.payment.isRetrievingToken) {
    loading = {
      heading: "Processing payment information"
    }
  } else if (state.interviewQuestions.isRetrievingQuestions || state.case.isLoadingCaseResponse) {
    loading = {
      heading: "Looking for additional questions"
    }
  } else if (state.document.isLoadingSigningUrl) {
    loading = {
      heading: "Preparing documents for your signature"
    }
  } else if (state.currentPage.currentPage === CurrentPage.CaseDecisionDetermination) {
    loading = {
      heading: "Determining eligibility"
    }
  }
  else if (state.currentPage.currentPage === CurrentPage.SubmittingApplication) {
    loading = {
      heading: "Submitting completed application"
    }
  }
  return {
    currentPage: state.currentPage.currentPage,
    loading: loading,
    links: state.brand.links,
    lastPageMovement: state.currentPage.lastPageMovement,
    hasProgressBar: state.currentPage.progress !== undefined
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, null, AnyAction>) => {
  return {
    loadPageOrderData: () => dispatch(loadPageOrderAction()),
    loadLinks: () => dispatch(loadLinksAction())
  };
};

interface LoadingInformation {
  heading?: string;
  subheading?: string;
}

interface AppProps {
  currentPage?: CurrentPage;
  loading?: LoadingInformation;
  loadPageOrderData: () => void;
  loadLinks: () => void;
  links?: Dictionary;
  lastPageMovement?: LastPageMovement;
  hasProgressBar: boolean;
}

interface AppComponentState {
  modalDisplayed: boolean;
}

export class AppInternal extends React.Component<AppProps, AppComponentState>{
  constructor(props: AppProps) {
    super(props);

    this.state = {
      modalDisplayed: false,
    }
  }

  private getBody = (page: CurrentPage): JSX.Element | null => {
    switch (page) {
      case CurrentPage.Gender:
        return <Gender />;
      case CurrentPage.NicotineProducts:
        return <UseNicotineProducts />;
      case CurrentPage.DateOfBirth:
        return <DateOfBirth />;
      case CurrentPage.State:
        return <State />;
      case CurrentPage.BenefitSelection:
        return <BenefitSelection />;
      case CurrentPage.UserIdentification:
        return <UserIdentification />;
      case CurrentPage.Citizenship:
        return <Citizenship />;
      case CurrentPage.Birthstate:
        return <BirthLocation />;
      case CurrentPage.Terms:
        return <Terms />;
      case CurrentPage.FullTerms:
        return <FullTerms />;
      case CurrentPage.PermanentStatus:
        return <PermanentResident />;
      case CurrentPage.Residence:
        return <Residence />;
      case CurrentPage.Phone:
        return <PhoneNumber />;
      case CurrentPage.SocialSecurityNumber:
        return <SocialSecurityNumber />;
      case CurrentPage.NicotineDetails:
        return <NicotineDetails />;
      case CurrentPage.ExistingCoverage:
        return <ExistingCoverage />;
      case CurrentPage.ComprehensiveHealthcare:
        return <ComprehensiveHealthcare />
      case CurrentPage.Body:
        return <Body />;      
      case CurrentPage.FullDetails:
        return <FullDetails />;
      case CurrentPage.InterviewQuestions:
        return <Questions />
      case CurrentPage.Payment:
        return <Payments />;
      case CurrentPage.FinalApproved:
        return <FinalApproved />;
      case CurrentPage.Declined:
        return <Declined />;
      case CurrentPage.SignDocuments:
        return <SignDocuments />;
      case CurrentPage.TentativeApproved:
        return <TentativeApproved />;
      case CurrentPage.CaseDecisionDetermination:
        return <CaseDecisionDetermination />;
      case CurrentPage.TentativeSentToUnderwriting:
          return <TentativeSentToUnderwriting />;
      case CurrentPage.SubmittingApplication:
        return <SubmittingApplication />;
    }
    return null;
  }

  private getBodyWidthClassName = (): string => {
    switch (this.props.currentPage) {
      case CurrentPage.SignDocuments:
      case CurrentPage.FullTerms:
      case CurrentPage.FullDetails:
        return "col-lg-12";
    }
    return "col-lg-7";
  }

  private getHeaderImageSource = (): string | undefined => {
    if (this.props.links !== undefined && this.props.links["headerImage"] !== undefined) {
      return this.props.links["headerImage"];
    }

    return undefined;
  }

  private getHomepageAddress = (): string | undefined => {
    if (this.props.links !== undefined && this.props.links["homepage"] !== undefined) {
      return this.props.links["homepage"];
    }

    return undefined;
  }

  private toggleModal = () => {
    this.setState((state, props) => ({
      modalDisplayed: !this.state.modalDisplayed
    }));
  }

  componentDidMount() {
    this.props.loadLinks();
    this.props.loadPageOrderData();
  }

  render() {
    let bodyContainerClass = `container pt-4 ${this.props.lastPageMovement === LastPageMovement.Backward ? "page-going-back" : ""}`;
    return (
      <>
        <nav className="navbar navbar-light">
          <div className="navbar-brand">
            <a href={this.getHomepageAddress()}>
              {this.getHeaderImageSource() !== undefined ?
                <img src={this.getHeaderImageSource()} alt="company logo" height="30" /> :
                <div style={{ height: '30px' }} ></div>
              }
            </a>
          </div>
          <ul className="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
            <li className="nav-item">
              <img src={FAQLogo} role="button" className="faq-button" alt="FAQ" height="30" onClick={this.toggleModal} />
            </li>
          </ul>
        </nav>
        <div className={bodyContainerClass}>
          <CSSTransition in={this.props.hasProgressBar} classNames="page-transition" timeout={300} mountOnEnter={true} unmountOnExit={true}>
            <ProgressBar />
          </CSSTransition>
          <div className="row justify-content-center">
            <div className={`${this.getBodyWidthClassName()} col-12`}>
              {
                Object.values(CurrentPage).map(a => {
                  let bodyCard = this.getBody(a);
                  return <PageTransition key={a} in={this.props.currentPage === a} includeDisclosure={a !== CurrentPage.InterviewQuestions}>
                    {bodyCard}
                  </PageTransition>
                })
              }

            </div>
          </div>

        </div>
        {this.props.loading !== undefined &&
          <LoadingData heading={this.props.loading.heading} subheading={this.props.loading.subheading} />
        }
        <Modal show={this.state.modalDisplayed} onHide={this.toggleModal} size="xl" className="modal-full">
          <Modal.Header closeButton><h2>FAQ</h2></Modal.Header>
          <Modal.Body className="p-0">
            <FaqIFrame />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export class FaqIFrame extends React.Component {
  render() {
    return (
      <iframe title="faq" style={{ height: "100%", width: "100%", position: "absolute", borderRadius: "0.3rem" }} className="" src="https://pbds.supportedbyparse.com/faqs/" frameBorder={0} />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppInternal);
