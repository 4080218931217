import * as Actions from './Actions';
import { DocumentState } from '../../models/State/DocumentState';
import { createReducer } from '../ReducersUtilities';

type Action = ReturnType<typeof Actions.loadingSigningUrlAction> |
    ReturnType<typeof Actions.retrievedSigningUrlAction>;

export function loadingSigningUrlReducer(state: DocumentState, action: Action): DocumentState {
    if (action.type !== Actions.FETCH_URL) {
        return state;
    }

    return { ...state, isLoadingSigningUrl: true };
}

export function retrievedSigningUrlReducer(state: DocumentState, action: Action): DocumentState {
    if (action.type !== Actions.RETRIEVED_URL) {
        return state;
    }

    return { ...state, signingUrl: action.payload.signingUrl, envelopeId: action.payload.envelopeId, isLoadingSigningUrl: false };
}

export default createReducer<DocumentState, Actions.Types, Action>({ isLoadingSigningUrl: false }, {
    [Actions.FETCH_URL]: loadingSigningUrlReducer,
    [Actions.RETRIEVED_URL]: retrievedSigningUrlReducer
});