import { ActionCreator, AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import AppState from "../../models/State/AppState";
import Case from "../../models/Case";
import { createCase, getCaseResult } from "../../accessors/CaseAccessor";
import { areQuestionAnswersSaving } from "../InterviewQuestions/Utilities";
import CaseResult from "../../models/CaseResult";
import NicotineType from "../../models/NicotineType";

export const CREATE_CASE = 'case/post_case';
export const RETRIEVED_CASE = 'case/retrieved_case';
export const UPDATE_CASERESULT = 'case/update_caseresult';

export type Types = typeof CREATE_CASE |
    typeof RETRIEVED_CASE |
    typeof UPDATE_CASERESULT;

export interface CreateCaseActionPayload {
}

export interface CreateCaseActionResponsePayload {
    case: Case;
}

export interface SetCaseResultPayload {
    result: CaseResult;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export const createCaseAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = () => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (currState.benefitAmount.selectedAmount === undefined
            || currState.personalInformation.streetAddress === undefined
            || currState.personalInformation.city === undefined
            || currState.demographicInformation.state === undefined
            || currState.personalInformation.zip === undefined
            || currState.personalInformation.usersFirstName === undefined
            || currState.personalInformation.usersLastName === undefined
            || currState.demographicInformation.gender === undefined
            || currState.demographicInformation.dateOfBirth === undefined
            || currState.personalInformation.heightFeet === undefined
            || currState.personalInformation.heightInches === undefined
            || currState.personalInformation.weightPounds === undefined
            || currState.personalInformation.birthCountry === undefined
            || currState.personalInformation.phoneNumber === undefined
            || currState.personalInformation.socialSecurityNumber === undefined
            || currState.personalInformation.tobaccoUses === undefined
            || currState.personalInformation.isCitizen === undefined
            || currState.demographicInformation.useNicotineProducts === undefined
            || currState.personalInformation.hasExistingCoverage === undefined
            || (currState.demographicInformation.state === "CA" && currState.personalInformation.hasComprehensiveHealthcare === undefined)
            || currState.demographicInformation.referrer === undefined
        ) {
            throw new Error("Not enough data to create a case.");
        }

        dispatch(loadingCreateCaseAction());

        const caseResult = await createCase({
            benefitAmount: currState.benefitAmount.selectedAmount.benefitAmount,
            streetAddress: currState.personalInformation.streetAddress,
            streetAddress2: currState.personalInformation.streetAddress2,
            city: currState.personalInformation.city,
            state: currState.demographicInformation.state,
            zip: currState.personalInformation.zip,
            firstName: currState.personalInformation.usersFirstName,
            lastName: currState.personalInformation.usersLastName,
            gender: currState.demographicInformation.gender,
            dateOfBirth: currState.demographicInformation.dateOfBirth,
            heightFeet: currState.personalInformation.heightFeet,
            heightInches: currState.personalInformation.heightInches,
            weightPounds: currState.personalInformation.weightPounds,
            birthState: currState.personalInformation.birthState,
            birthCountry: currState.personalInformation.birthCountry,
            phoneNumber: currState.personalInformation.phoneNumber,
            SSN: currState.personalInformation.socialSecurityNumber,
            isCitizen: currState.personalInformation.isCitizen,
            tobaccoUses: currState.personalInformation.tobaccoUses,
            tobaccoType: currState.personalInformation.tobaccoType === undefined ? "" : NicotineType[currState.personalInformation.tobaccoType],
            tobaccoDailyUseCount: currState.personalInformation.tobaccoDailyUseCount,
            tobaccoMonthlyUseCount: currState.personalInformation.tobaccoMonthlyUseCount,
            tobaccoLastUseDate: currState.personalInformation.tobaccoLastUseDate,
            greenCard: currState.personalInformation.greenCard,
            quotedUsesTobacco: currState.demographicInformation.useNicotineProducts,
            hasExistingCoverage: currState.personalInformation.hasExistingCoverage,
            hasComprehensiveHealthcare: currState.personalInformation.hasComprehensiveHealthcare,
            referrer: currState.demographicInformation.referrer
        });
        return dispatch(receivedCreateCaseAction(caseResult));
    };
}

export function loadingCreateCaseAction(): TypePayload<typeof CREATE_CASE, CreateCaseActionPayload> {
    return { type: CREATE_CASE, payload: {} };
}

export function receivedCreateCaseAction(caseResult: Case): TypePayload<typeof RETRIEVED_CASE, CreateCaseActionResponsePayload> {
    return { type: RETRIEVED_CASE, payload: { case: caseResult } };
}

export function setCaseResultAction(result: CaseResult): TypePayload<typeof UPDATE_CASERESULT, SetCaseResultPayload> {
    return { type: UPDATE_CASERESULT, payload: { result: result } };
}

export const validateCaseResultAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (id: string, answer?: string) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();

        if (currState.case.case === undefined || areQuestionAnswersSaving(currState.interviewQuestions)) {
            setTimeout(() => dispatch(validateCaseResultAction()), 5000);
            return;
        }

        let result = await getCaseResult(currState.case.case.caseId, currState.case.applicationSubmitted === true);
        await dispatch(setCaseResultAction(result));
        if (result === undefined || result === CaseResult.Processing) {
            setTimeout(() => dispatch(validateCaseResultAction()), 5000);
        }
    };
}