import AppState from "../../models/State/AppState";
import { ActionCreator, AnyAction } from "redux";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { ReactStripeElements } from "react-stripe-elements";
import { nextPageAction } from "../CurrentPage/Actions";

// Action Types
export const UPDATE_CARDFIELDS = 'payment_state/update_cardfields';
export const FETCH_TOKEN = 'payment_state/fetch_token';
export const RETRIEVED_TOKEN = 'payment_state/retrieved_token';

export type Types = typeof UPDATE_CARDFIELDS |
    typeof FETCH_TOKEN |
    typeof RETRIEVED_TOKEN;

export interface UpdateCardFieldsPayload {
    elementChangeResponse: stripe.elements.ElementChangeResponse;
}

export interface FetchTokenPayload {
}

export interface RetrievedTokenPayload {
    tokenResponse: ReactStripeElements.PatchedTokenResponse;
}

type TypePayload<T1 extends Types, T2> = { type: T1, payload: T2 };

export const submitCreditCardAction: ActionCreator<ThunkAction<Promise<any>, AppState, null, AnyAction>> = (stripeProps?: ReactStripeElements.StripeProps) => {
    return async (dispatch: ThunkDispatch<AppState, null, AnyAction>, getState: () => AppState): Promise<any> => {
        const currState = getState();
        if (stripeProps !== undefined) {
            dispatch(fetchTokenAction());
            const result = await stripeProps.createToken({
                name: `${currState.personalInformation.usersFirstName} ${currState.personalInformation.usersLastName}`
            });
            let value = dispatch(retrievedTokenAction(result));
            if (result.error) {
                return value;
            }
            return dispatch(nextPageAction());
        }
    };
}

export function fetchTokenAction(): TypePayload<typeof FETCH_TOKEN, FetchTokenPayload> {
    return { type: FETCH_TOKEN, payload: {} };
}

export function retrievedTokenAction(tokenResponse: ReactStripeElements.PatchedTokenResponse): TypePayload<typeof RETRIEVED_TOKEN, RetrievedTokenPayload> {
    return { type: RETRIEVED_TOKEN, payload: { tokenResponse: tokenResponse } };
}

export function updateCardFieldAction(elementChangeResponse: stripe.elements.ElementChangeResponse): TypePayload<typeof UPDATE_CARDFIELDS, UpdateCardFieldsPayload> {
    return { type: UPDATE_CARDFIELDS, payload: { elementChangeResponse: elementChangeResponse } };
}