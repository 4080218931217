import { CSSTransition } from "react-transition-group";
import React from "react";
import Disclosure from "./Disclosure";

interface PageTransitionComponentProps {
    in?: boolean;
    includeDisclosure: boolean;
}
export default class PageTransition extends React.Component<PageTransitionComponentProps>{
    render() {
        return <CSSTransition in={this.props.in} classNames="page-transition" timeout={300} mountOnEnter={true} unmountOnExit={true}>
            <div className="px-3" style={{ position: "absolute", top: "0", left: "0", right: "0" }}>
                {this.props.children}
                {this.props.includeDisclosure &&
                    <Disclosure />
                }
            </div>
        </CSSTransition>
    }
}