import { createReducer } from "../ReducersUtilities";
import * as Actions from './Actions';
import AvailableStatesState from "../../models/State/AvailableStatesState";

type Action = ReturnType<typeof Actions.fetchAvailableStatesAction> |
              ReturnType<typeof Actions.retrievedAvailableStatesAction>;

export function fetchAvailableStatesReducer(state: AvailableStatesState, action: Action): AvailableStatesState {
    if (action.type !== Actions.FETCH_AVAILABLESTATES) {
        return state;
    }
    
    return { ...state, isLoadingAvailableStates: true };
}

export function retrievedAvailableStatesReducer(state: AvailableStatesState, action: Action): AvailableStatesState {
    if (action.type !== Actions.RETRIEVED_AVAILABLESTATES) {
        return state;
    }
    
    return { ...state, availableStates: action.payload.availableStates, isLoadingAvailableStates: false };
}


export default createReducer<AvailableStatesState, Actions.Types, Action>({isLoadingAvailableStates: false}, {
    [Actions.FETCH_AVAILABLESTATES]: fetchAvailableStatesReducer,
    [Actions.RETRIEVED_AVAILABLESTATES]: retrievedAvailableStatesReducer
});