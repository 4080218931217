import React from "react";

type IntegerInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;
export default class IntegerInput extends React.Component<IntegerInputProps>{
    private handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>{
        if(isNaN(Number(e.key))){
            e.stopPropagation();
            e.preventDefault();
            return;
        }
        if(this.props.onKeyPress){
            this.props.onKeyPress(e);
        }
    }
    render(){
        return <input type="number" {...{min: 0, ...this.props, onKeyPress: this.handleKeyPress}} />
    }
}