import { Validation, PaymentValidation } from "../../models/State/ValidationState";
import AppState from "../../models/State/AppState";

const hasPaymentToken = (currState: AppState): boolean => {
    return !isStringEmpty(currState.payment.token) && isStringEmpty(currState.payment.paymentError);
}

const isPaymentInformationValid = (currState: AppState): boolean => {
    return currState.payment.stripeElementData !== undefined && currState.payment.stripeElementData.complete && !currState.payment.stripeElementData.empty && currState.payment.stripeElementData.error === undefined;
}

function isStringEmpty(value?: string): boolean {
    return value === undefined || value === null || value.trim().length === 0;
}

export default function personalInformationReducer(currState: AppState): Validation<PaymentValidation> {
    let fields: PaymentValidation =
    {
        isPaymentTokenValid: hasPaymentToken(currState),
        isPaymentInformationValid: isPaymentInformationValid(currState)
    };
    return {
        overall: fields.isPaymentTokenValid &&
                 fields.isPaymentInformationValid,
        fields: fields
    }
}